<template>
	<DefaultLayout>
		<SmartLoader :fetch-data="fetchData" :absolute-position="true">
			<b-form novalidate class="form-horizontal">
				<ofs-panel>
					<ContentHeader class="mb-3" :no-padding="true" :title="$t('Account Settings')" />
					<b-tabs vertical class="Settings">
						<b-tab :title="$t('Profile')">
							<h3>{{ $t('Profile') }}</h3>

							<of-form-input
								:readonly="!isAdmin"
								name="companyName"
								type="text"
								data-test-id="accountCompanyName"
								:label="$t('Company Name')"
								:placeholder="$t('Company Name')"
								required
							/>
							<of-form-input
								:readonly="!isAdmin"
								name="address1"
								type="text"
								data-test-id="accountAddressLine1"
								:label="$t('Address Line 1')"
								:placeholder="$t('Address Line 1')"
								required
							/>
							<of-form-input
								:readonly="!isAdmin"
								name="state"
								type="text"
								data-test-id="accountState"
								:label="$t('State')"
								:placeholder="$t('State')"
							/>
							<of-form-input
								:readonly="!isAdmin"
								name="town"
								type="text"
								data-test-id="accountTown"
								:label="$t('Town')"
								:placeholder="$t('Town')"
								required
							/>
							<of-form-select
								:disabled="!isAdmin"
								name="isoCountry"
								data-test-id="accountIsoCountry"
								:label="$t('ISO Country')"
								:options="ISOcountries"
								required
							/>
							<of-form-input
								:readonly="!isAdmin"
								name="postcode"
								type="text"
								data-test-id="accountPostCode"
								:label="$t('Post Code')"
								:placeholder="$t('Post Code')"
								required
							/>
							<of-form-input
								:readonly="!isAdmin"
								name="phoneNumber"
								type="text"
								data-test-id="accountPhoneNumber"
								:label="$t('Phone number')"
								:placeholder="$t('Phone number')"
								required
							/>
							<of-form-input
								:readonly="!isAdmin"
								name="faxNumber"
								type="text"
								data-test-id="accountFaxNumber"
								:label="$t('Fax number')"
								:placeholder="$t('Fax number')"
							/>
							<of-form-input
								:readonly="!isAdmin"
								name="emailAddress"
								type="email"
								data-test-id="accountEmailAddress"
								:label="$t('Email address')"
								:description="$t('We\'ll never share your email with anyone else')"
								:placeholder="$t('Enter email')"
								required
							/>
						</b-tab>

						<b-tab :title="$t('System')">
							<h3>{{ $t('System Preferences') }}</h3>

							<of-form-select
								:disabled="!isAdmin"
								name="timezoneGroup"
								data-test-id="accountTimezoneGroup"
								:label="$t('Timezone Group')"
								:options="timezoneGroups"
								required
								@input="onChangeTimezoneGroup"
							/>
							<of-form-select
								:disabled="!isAdmin"
								name="timezone"
								data-test-id="accountTimezone"
								:label="$t('Timezone')"
								:options="timeZones"
								required
							/>
							<of-form-select
								:disabled="!isAdmin"
								name="dateFormat"
								data-test-id="accountDateFormat"
								:label="$t('Local Date Format')"
								:options="dateFormats"
							/>
							<of-form-select
								:disabled="!isAdmin"
								name="weightUnit"
								data-test-id="accountWeightUnits"
								:label="$t('Weight Units')"
								:options="weightUnits"
							/>
							<of-form-select
								:disabled="!isAdmin"
								name="dimensionUnit"
								data-test-id="accountDimensionUnits"
								:label="$t('Dimension Units')"
								:options="dimensionUnits"
							/>
							<of-form-select
								:disabled="!isAdmin"
								name="currency"
								data-test-id="accountCurrency"
								:label="$t('Currency')"
								:options="currencies"
							/>
						</b-tab>

						<Feature name="siteflow-shipping-documentation">
							<b-tab :title="$t('Shipping documentation')">
								<h3>{{ $t('Shipping documentation') }}</h3>
								<p data-test-id="shippingDocInfo">
									{{
										$t(
											'If enabled, this generates a shipping document when a shipment is marked as shipped. This document is available in the Shipment Shipped postback and through the View File page, which can also be sent to the customer.'
										)
									}}
								</p>

								<b-form-checkbox
									v-model="shippingDocumentationEnabled"
									class="mb-2"
									data-test-id="enableShippingDocCheckbox"
								>
									{{ $t('Enable Shipping Documentation') }}
								</b-form-checkbox>

								<template v-if="shippingDocumentationEnabled">
									<of-multi-select
										name="shippingDoc.defaultTemplateId"
										data-test-id="accountShippingDocTemplate"
										track-by="_id"
										label-by="name"
										:allow-clear="false"
										:label="$t('Template')"
										:options="templates"
										:placeholder="$t('Select option')"
									/>

									<of-multi-select
										name="shippingDoc.defaultPdfOptions.format"
										data-test-id="accountShippingDocPdfFormat"
										:allow-clear="false"
										:label="$t('PDF format')"
										:options="pdfFormatOptions"
										:placeholder="$t('Select option')"
									/>

									<of-multi-select
										name="shippingDoc.defaultPdfOptions.orientation"
										data-test-id="accountShippingDocPdfOrientation"
										:allow-clear="false"
										:label="$t('PDF orientation')"
										:options="pdfOrientationOptions"
										:placeholder="$t('Select option')"
									/>

									<of-form-input
										type="number"
										name="shippingDoc.defaultPdfOptions.scale"
										data-test-id="accountShippingDocPdfScale"
										:label="$t('PDF scale')"
										min="0.1"
										step="0.05"
										max="2"
										:description="$t('Minimum accepted value is 0.1. Maximum accepted value is 2.')"
									/>
								</template>
							</b-tab>
						</Feature>
					</b-tabs>

					<template #actions>
						<b-button :disabled="!canSubmit" variant="primary" @click="onSave">
							{{ $t('Submit') }}
						</b-button>
					</template>
				</ofs-panel>
			</b-form>
		</SmartLoader>
	</DefaultLayout>
</template>

<script>
import Promise from 'bluebird';
import get from 'lodash/get';
import filter from 'lodash/filter';
import find from 'lodash/find';
import head from 'lodash/head';
import isEmpty from 'lodash/isEmpty';
import { mapGetters, mapActions } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import {
	ContentHeader,
	OfFormInput,
	OfFormSelect,
	OfsPanel,
	OfMultiSelect,
	withForm,
	Feature,
	validateWithMessage
} from '@workflow-solutions/ofs-vue-layout';
import { i18n } from 'src/vuex';
import DefaultLayout from '../../components/DefaultLayout';
import SmartLoader from '../../components/SmartLoader';

const pdfFormatOptions = [
	{ text: 'Letter', value: 'Letter' },
	{ text: 'Legal', value: 'Legal' },
	{ text: 'Tabloid', value: 'Tabloid' },
	{ text: 'Ledger', value: 'Ledger' },
	{ text: 'A3', value: 'A3' },
	{ text: 'A4', value: 'A4' },
	{ text: 'A5', value: 'A5' },
	{ text: 'A6', value: 'A6' }
];

const pdfOrientationOptions = [
	{ text: 'Landscape', value: 'landscape' },
	{ text: 'Portrait', value: 'portrait' }
];

export default {
	name: 'AccountSettings',
	components: {
		DefaultLayout,
		ContentHeader,
		OfFormInput,
		OfFormSelect,
		OfMultiSelect,
		OfsPanel,
		SmartLoader,
		Feature
	},
	mixins: [withForm('accountForm')],
	data() {
		const $t = str => i18n.t(str);
		return {
			timeZones: [],
			currencies: [],
			timezonesList: [],
			countries: [],
			dateFormats: [
				{ text: $t('YYYY-MM-DD'), value: 'yyyy-MM-dd' },
				{ text: $t('YYYY/MM/DD'), value: 'yyyy/MM/dd' },
				{ text: $t('DD-MM-YYYY'), value: 'dd-MM-yyyy' },
				{ text: $t('DD/MM/YYYY'), value: 'dd/MM/yyyy' },
				{ text: $t('MM-DD-YYYY'), value: 'MM-dd-yyyy' },
				{ text: $t('MM/DD/YYYY'), value: 'MM/dd/yyyy' }
			],
			weightUnits: [
				{ text: $t('Grams'), value: 'g' },
				{ text: $t('Ounces'), value: 'oz' }
			],
			dimensionUnits: [
				{ text: $t('Millimeters'), value: 'mm' },
				{ text: $t('Inches'), value: 'inch' }
			],
			pdfFormatOptions,
			pdfOrientationOptions
		};
	},
	computed: {
		...mapGetters('account', ['account', 'user', 'accountSettings']),
		...mapGetters({
			memberships: 'account/userMemberships'
		}),
		...mapGetters('auth', ['user']),
		...mapGetters('template', ['templates']),
		ISOcountries() {
			if (!this.countries) {
				return [];
			}

			return this.countries.map(item => ({ text: item.name, value: get(item, 'alpha-2') }));
		},
		canSubmit() {
			return this.formState.dirty && !this.formState.invalid && this.isAdmin;
		},
		validationRules() {
			let rules = {
				formData: {}
			};

			if (this.shippingDocumentationEnabled) {
				_.set(rules, 'formData.shippingDoc', {
					defaultTemplateId: {
						required
					},
					defaultPdfOptions: {
						scale: {
							required: validateWithMessage(this.$t('Please specify a PDF scale'), required)
						},
						orientation: {
							required: validateWithMessage(this.$t('PDF Orientation is required'), required)
						},
						format: {
							required: validateWithMessage(
								this.$t('You must specify a page format for the PDF'),
								required
							)
						}
					}
				});
			}

			return rules;
		},
		shippingDocumentationEnabled: {
			get() {
				return !!this.formData?.shippingDoc?.defaultTemplateId;
			},
			set(enabled) {
				let shippingDoc = this.formData?.shippingDoc || {};

				if (enabled) {
					// If enabled, set a defaultTemplateId
					shippingDoc.defaultTemplateId = this.templates?.[0]?._id;
				} else {
					// If disabled, unset the defaultTemplateId
					shippingDoc.defaultTemplateId = null;
				}

				this.updateFormData({ shippingDoc });
			}
		},
		timezoneGroups() {
			if (!this.timezonesList) {
				return [];
			}

			return this.timezonesList.map(item => ({ text: item.group, value: item.group }));
		},
		isAdmin() {
			if (this.memberships && this.user) {
				const userMemberships = this.memberships.filter(m => m.userId === this.user._id);
				if (userMemberships.length > 0 && userMemberships[0].role) {
					return userMemberships[0].role === 'admin';
				}
			}

			return false;
		}
	},
	watch: {
		accountSettings() {
			const isDataLoaded = !isEmpty(this.currencies) && !isEmpty(this.timezonesList) && !isEmpty(this.countries);
			if (this.accountSettings && isDataLoaded) {
				const { group, zones } = this.getTimeZoneGroupByZone(this.accountSettings.timezone);
				this.timeZones = zones;
				this.setFormData({ ...this.accountSettings, timezoneGroup: group });
			}
		}
	},
	beforeRouteLeave(to, from, next) {
		// Show a confirm dialog if the form is dirty
		if (this.canSubmit) {
			this.$bvModal
				.msgBoxConfirm(this.$t('You have unsaved changes, do you want to save them?'), {
					title: this.$t('You have unsaved changes'),
					okTitle: this.$t('Save'),
					cancelTitle: this.$t('Discard')
				})
				.then(async value => {
					if (value) await this.onSave();
					next();
				})
				.catch(err => {
					this.$toaster.error(this.$t('An error occurred saving your account settings'), { timeout: 3000 });
				});
		} else {
			next();
		}
	},
	methods: {
		...mapActions('account', ['getAccount', 'getVars', 'saveAccountSettings', 'getMemberships']),
		...mapActions('auth', ['getUser']),
		...mapActions('template', {
			findAllTemplates: 'findAll'
		}),
		async fetchData() {
			try {
				const [currencies, timezones, countries] = await Promise.all([
					import('../../data/currencies').then(res => res.currencies),
					import('../../data/timezones').then(res => res.timezones),
					import('../../data/ISOcountries').then(res => res.ISOcountries)
				]);
				this.currencies = currencies;
				this.timezonesList = timezones;
				this.countries = countries;
				await this.getVars();

				if (!this.user) {
					await this.getUser();
				}

				if (!this.memberships || !this.memberships.length) {
					await this.getMemberships({ query: { userId: this.user._id, $limit: 1000 } });
				}

				// Find active templates
				await this.findAllTemplates({
					query: {
						active: true,
						sort: 'name',
						direction: 1
					}
				});
			} catch (err) {
				this.$toaster.error(this.$t('An error occurred while fetching account settings'), { timeout: 3000 });
			}
		},
		async onSave() {
			try {
				await this.saveAccountSettings(this.formData);
				// eslint-disable-next-line no-underscore-dangle
				this._updateFormSummary({ formName: 'accountForm', summary: { dirty: false } });
				this.$toaster.success(this.$t('Account Settings saved successfully'), { timeout: 3000 });
			} catch (err) {
				this.$toaster.error(this.$t('An error occurred while updating account settings'), { timeout: 3000 });
			}
		},
		getTimeZoneGroupByZone(timezone) {
			const currentTimeZones = filter(this.timezonesList, item => find(item.zones, { value: timezone }));
			return head(currentTimeZones);
		},
		getTimeZoneGroupByGroup(group) {
			return find(this.timezonesList, { group });
		},
		onChangeTimezoneGroup(timezoneGroup) {
			if (this.accountSettings && timezoneGroup) {
				const { zones } = this.getTimeZoneGroupByGroup(timezoneGroup);
				this.timeZones = zones;
				this.updateFormData({ timezone: zones[0].value });
			}
		}
	}
};
</script>

<style lang="scss">
@import '~@workflow-solutions/ofs-vue-layout/dist/style/mixins';

.Settings {
	max-width: 800px;
	&-Separator {
		border-style: dashed;
	}
	.Form-Title {
		@include ofTextStyleFormLabels();
		padding-top: calc(0.375rem + 1px);
		padding-bottom: calc(0.375rem + 1px);
	}
	.Button-Container {
		width: 100%;
		label {
			width: 100%;
		}
	}
	.Input-Tooltip {
		position: relative;
		svg {
			color: $of-color-grey-2;
			position: absolute;
			top: 8px;
			right: 0px;
			z-index: 1;
		}
	}
}
</style>
