<template>
	<DefaultLayout>
		<ofs-panel>
			<ContentHeader :no-padding="true" :title="$t('Users')">
				<a
					v-if="isAdmin"
					v-t="'Invite User'"
					href="#"
					class="btn btn-primary btn-block"
					data-test-id="inviteUserBtn"
					@click.prevent="clickInviteMember"
				/>
				<TeamInviteModal
					v-if="isAdmin"
					:show-modal="isTeamInviteModalShown"
					:on-close="handleInviteModalClose"
					:on-complete="fetchData"
				/>
			</ContentHeader>
			<b-container class="p-0" fluid>
				<ListTable
					table-title=" "
					:items="memberships"
					:fields="fields"
					:total-items="membershipsCount"
					:per-page="perPage"
					:current-page="currentPage"
					:config="config"
					:selected="selected"
					:fetch-data="fetchData"
					:page-position-prefix="$t('Showing')"
					:page-position-join="$t('of')"
					hover
					@row-clicked="handleRowClick"
					@table-change="handleTableChange"
				>
					<template slot="prev-text" slot-scope="{}">
						{{ $t('Prev') }}
					</template>
					<template slot="next-text" slot-scope="{}">
						{{ $t('Next') }}
					</template>
					<template slot="empty">
						<span>
							<i>{{ $t('No Data') }}</i>
						</span>
					</template>
					<template #cell(email)="data">
						{{ _get(data, 'item.user.email') }}
					</template>
					<template #cell(role)="data">
						{{ getRoleName(_get(data, 'item.role')) }}
					</template>
					<template #cell(actions)="data">
						<TeamMemberDropdown
							v-if="isAdmin && !isCurrentUser(data.item)"
							:member="data.item"
							:refresh="fetchData"
						/>
					</template>
				</ListTable>
			</b-container>
		</ofs-panel>
	</DefaultLayout>
</template>

<script>
import _get from 'lodash/get';
import { mapActions, mapGetters } from 'vuex';
import { ListTable, ContentHeader, OfsPanel } from '@workflow-solutions/ofs-vue-layout';
import { i18n } from 'src/vuex';
import DefaultLayout from '../../components/DefaultLayout';
import TeamMemberDropdown from './TeamMemberDropdown';
import TeamInviteModal from './modals/TeamInviteModal';

const config = {
	breadcrumbs: { visible: false },
	filter: { visible: false },
	search: { visible: false },
	add: { visible: false },
	refresh: { visible: false },
	columns: { visible: false }
};
export default {
	name: 'TeamMemberList',
	components: {
		DefaultLayout,
		OfsPanel,
		ContentHeader,
		TeamMemberDropdown,
		TeamInviteModal,
		ListTable
	},
	data() {
		const $t = str => i18n.t(str);
		return {
			isTeamInviteModalShown: false,
			perPage: 10,
			currentPage: 1,
			isBusy: false,
			fields: [
				{ label: $t('Email'), key: 'email' },
				{ label: $t('Role'), key: 'role' },
				{ label: ' ', key: 'actions' }
			],
			config,
			selected: []
		};
	},
	computed: {
		...mapGetters({
			currentAccount: 'account/currentAccount',
			member: 'account/member',
			user: 'auth/user',
			memberships: 'account/memberships',
			membershipsCount: 'account/membershipsCount'
		}),
		isAdmin() {
			return this.member ? this.member.role === 'admin' : false;
		}
	},
	watch: {
		user() {
			const userId = _get(this.user, '_id');
			if (userId) return this.getMember({ userId });
			return null;
		}
	},
	created() {
		this.selected = [...this.fields];
		this.fetchData();
	},
	methods: {
		_get,
		...mapActions({
			getMemberships: 'account/getMemberships',
			getMember: 'account/getMember',
			getUser: 'auth/getUser'
		}),
		async fetchData() {
			if (!this.user) {
				await this.getUser();
			}

			await this.getMember({ userId: this.user._id });

			const query = {
				$limit: this.perPage,
				$skip: this.perPage * (this.currentPage - 1)
			};
			this.getMemberships({ query });
		},
		isCurrentUser(member) {
			return !!(member && member.user && member.user._id === this.user._id);
		},
		clickInviteMember() {
			this.isTeamInviteModalShown = true;
		},
		getRoleName(role) {
			switch (role) {
				case 'customer':
					return this.$t('Customer');
				case 'user':
					return this.$HP_MODE ? this.$t('Manager') : this.$t('User');
				case 'admin':
					return this.$t('Admin');
				default:
					return '-';
			}
		},
		handleInviteModalClose() {
			this.isTeamInviteModalShown = false;
		},
		handleTableChange({ currentPage, perPage, filter, selectedCols }) {
			this.currentPage = currentPage;
			this.perPage = perPage;
			this.selected = selectedCols;
			if (filter !== undefined) {
				this.filter = filter;
			}
		},
		handleRowClick(member) {
			this.$router.push({
				name: 'account-user',
				params: {
					id: member.user._id
				}
			});
		}
	}
};
</script>

<style lang="scss" scoped>
.TeamMemberList {
	display: flex;
	flex-direction: column;
}
</style>
