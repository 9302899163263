<template>
	<b-form class="DownsampleTool">
		<b-row>
			<b-col>
				<of-form-select
					name="resolution"
					:label="$t('Resolution')"
					:options="resolutionOptions"
					:label-cols="12"
					required
					class="mb-0"
					@input="handleChange"
				>
				</of-form-select>
			</b-col>
			<b-col>
				<of-form-select
					name="compressionQuality"
					:label="$t('Compression')"
					:label-cols="12"
					:options="compressionQualityOptions"
					required
					class="mb-0"
					@input="handleChange"
				>
				</of-form-select>
			</b-col>
			<b-col>
				<of-form-select
					name="downsampling"
					:label="$t('Downsampling')"
					:label-cols="12"
					:options="downsamplingOptions"
					required
					class="mb-0"
					@input="handleChange"
				>
				</of-form-select>
			</b-col>
		</b-row>
	</b-form>
</template>
<script>
import _ from 'lodash';
import { OfFormSelect, withForm } from '@workflow-solutions/ofs-vue-layout';

const formatOptions = prop =>
	function formatProp() {
		const options = _.get(this, `toolConfig.form.${prop}.options`, []);
		return options.map(({ label: text, value }) => ({ value, text }));
	};

export default {
	mixins: [withForm('runTool')],
	props: {
		toolConfig: Object,
		specification: Object,
		onChange: {
			type: Function,
			default: () => () => {}
		}
	},
	components: {
		OfFormSelect
	},
	computed: {
		resolutionOptions: formatOptions('resolution'),
		compressionQualityOptions: formatOptions('compressionQuality'),
		downsamplingOptions: formatOptions('downsampling')
	},
	created() {
		const defaultValues = ['resolution', 'compressionQuality', 'downsampling'].reduce((acc, field) => {
			const defaultValue = _.get(this, `toolConfig.inputs.${field}.defaultValue`);
			return {
				...acc,
				[field]: defaultValue
			};
		}, {});
		this.setFormData(defaultValues);
	},
	methods: {
		async handleChange() {
			await this.$nextTick();
			return this.onChange({ formData: this.formData, formState: this.formState });
		}
	}
};
</script>
