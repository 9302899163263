<template>
	<b-form @submit="handleFormSubmit">
		<b-row>
			<b-col>
				<of-toggle
					name="accountDefault"
					:label="$t('Account Default')"
					data-test-id="accountDefault"
					class="ClientForm_toggle"
				/>
				<of-toggle
					v-if="showActiveToggle"
					name="active"
					:label="$t('Active')"
					data-test-id="active"
					class="ClientForm_toggle"
				/>
			</b-col>
		</b-row>
		<b-row>
			<b-col>
				<of-form-input
					name="name"
					data-test-id="name"
					type="text"
					:label="$t('Name')"
					:placeholder="$t('Enter name')"
					required
				/>
				<of-form-input
					name="address1"
					data-test-id="address1"
					type="text"
					:label="$t('Address Line')"
					:placeholder="$t('Enter Address')"
					required
				/>
				<of-form-input name="address2" data-test-id="address2" type="text" :label="$t('Address Line 2')" />
				<of-form-input name="address3" data-test-id="address3" type="text" :label="$t('Address Line 3')" />
				<of-form-input
					name="town"
					data-test-id="town"
					type="text"
					:label="$t('Town')"
					:placeholder="$t('Town')"
					required
				/>
			</b-col>
			<b-col>
				<of-form-input
					name="state"
					data-test-id="state"
					type="text"
					:label="$t('State')"
					:placeholder="$t('State')"
					required
				/>
				<of-form-input
					name="postcode"
					data-test-id="postcode"
					type="text"
					:label="$t('Postcode/ZIP')"
					:placeholder="$t('Postcode')"
					required
				/>
				<of-multi-select
					name="isoCountry"
					data-test-id="isoCountry"
					:label="$t('Country')"
					:options="countries"
					track-by="alpha-2"
					label-by="name"
					:show-errors="true"
					required
					:placeholder="$t('Select option')"
				/>
				<of-form-input name="phone" data-test-id="phone" type="text" :label="$t('Phone')" />
				<of-form-input
					name="email"
					data-test-id="email"
					type="email"
					:label="$t('Email')"
					:placeholder="$t('email')"
					required
				/>
			</b-col>
		</b-row>
		<hr />
		<div slot="actions" class="text-right">
			<b-button v-t="'Cancel'" type="reset" variant="secondary" @click="$emit('on-cancel')" />
			<of-submit-button v-t="'Save'" class="ml-1" @click.prevent="handleFormSubmit" />
		</div>
	</b-form>
</template>

<script>
import { OfToggle, OfFormInput, OfSubmitButton, OfMultiSelect, withForm } from '@workflow-solutions/ofs-vue-layout';
import { mapActions } from 'vuex';
import { displayError } from '../../lib/helpers';
import { $t } from '../../vuex';

export default {
	name: 'ClientForm',
	components: {
		OfFormInput,
		OfMultiSelect,
		OfSubmitButton,
		OfToggle
	},
	mixins: [withForm('clientForm')],
	props: {
		id: {
			type: String,
			default: ''
		},
		client: {
			type: Object,
			default: () => ({})
		}
	},
	data() {
		return {
			countries: [],
			showActiveToggle: true
		};
	},
	watch: {
		client: {
			immediate: true,
			handler(newValue) {
				if (newValue && newValue._id) {
					this.setFormData(newValue);
				}
			}
		},
		'formData.accountDefault'(value) {
			// hide active toggle for default accounts
			this.showActiveToggle = !value;
			this.updateFormData({ active: true });
		}
	},
	async created() {
		await this.setFormData(this.id ? this.client : { active: true });
		this.countries = await import('../../../public/data/iso.countries.json').then(r => r.default);
		// hide active toggle for default accounts
		if (this.client && this.client.accountDefault) {
			this.showActiveToggle = false;
		}
	},
	methods: {
		...mapActions({
			createClient: 'client/create',
			updateClient: 'client/update'
		}),
		async handleFormSubmit() {
			const data = this.formData;
			const saveFn = !this.id ? () => this.createClient(data) : () => this.updateClient({ id: this.id, data });

			try {
				const client = await this.dispatchSubmit(saveFn());
				this.$notify({
					type: 'success',
					text: this.id ? $t('Client saved successfully') : $t('Client created successfully')
				});
				this.$emit('on-submit', client);
			} catch (err) {
				this.$notify({ type: 'error', text: $t(displayError(err)) });
			}
		}
	}
};
</script>

<style>
.ClientForm_toggle {
	width: auto;
	display: inline-block;
}
</style>
