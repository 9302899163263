<template>
	<DefaultLayout class="AnalyticsOrderPerformance" :class="componentClass">
		<ContentHeader :title="$t('Orders in the last week')" class="ContentHeader--border">
			<b-form-radio-group
				v-model="localFilters.aggregation"
				buttons
				button-variant="outline-primary"
				size="sm"
				:options="aggregationOptions"
				@change="handleFiltersChange($event, 'aggregation')"
			/>
		</ContentHeader>
		<SmartLoader class="AnalyticsOrderPerformance-Main" :is-loading="isDoingInitialFetch">
			<template #default="{ isLoading }">
				<section class="SidebarPanel" :class="{ 'is-loading': isLoading }">
					<Loader v-if="isLoading" />
					<template v-else>
						<div class="SidebarPanel-side">
							<Sidebar
								:psp-list="pspList"
								:selected-psp="selectedPsp"
								:on-psp-selection="handlePspSelection"
								:snapshot-items="snapshotItems"
							/>
						</div>
						<b-container fluid class="SidebarPanel-Content">
							<SmartLoader :is-loading="isFetchingInProgressProcedures">
								<!-- Daily Fulfilment -->
								<template #default="{ isLoading: isLoadingInProgress }">
									<div
										class="Panel AnalyticsOrderPerformance-progress"
										:class="{ 'is-loading': isLoadingInProgress }"
									>
										<Loader v-if="isLoadingInProgress" />
										<template v-else>
											<header class="Panel-Header">
												<h4 v-t="'Work In Progress'" class="Panel-Heading" />
											</header>
											<div class="Panel-Content">
												<Snapshot
													v-if="snapshotItems[selectedPspName]"
													:items="snapshotItems[selectedPspName]"
												/>
												<div v-else class="Panel-Content--noData">
													<span>
														{{ $t('No data') }}
													</span>
												</div>
											</div>
										</template>
									</div>
								</template>
							</SmartLoader>

							<SmartLoader :is-loading="isFetchingDateRangeProcedures">
								<!-- Daily Fulfilment -->
								<template #default="{ isLoading: isLoadingDateRange }">
									<div
										class="Panel AnalyticsOrderPerformance-daily"
										:class="{ 'is-loading': isLoadingDateRange }"
									>
										<Loader v-if="isLoadingDateRange" />
										<template v-else>
											<header class="Panel-Header">
												<h4 v-t="'Daily Fulfilment'" class="Panel-Heading" />

												<b-form-radio-group
													v-model="localFilters.dateRange"
													buttons
													button-variant="outline-primary"
													size="sm"
													:options="dateRangesOptions"
													@change="handleFiltersChange($event, 'dateRange', true)"
												/>
											</header>
											<div class="Panel-Content Panel-Content--scrollable">
												<Breakdown
													v-if="breakdownValues[selectedPspName]"
													:keys="breakdownKeys"
													:values="breakdownValues[selectedPspName]"
												/>
												<div v-else class="Panel-Content--noData">
													<span>
														{{ $t('No data') }}
													</span>
												</div>
											</div>
										</template>
									</div>
								</template>
							</SmartLoader>
						</b-container>
					</template>
				</section>
			</template>
		</SmartLoader>
	</DefaultLayout>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { ContentHeader } from '@workflow-solutions/ofs-vue-layout';
import _ from 'lodash';
import moment from 'moment';
import { i18n } from 'src/vuex';
import Loader from 'src/components/Loader';
import SmartLoader from 'src/components/SmartLoader';
import DefaultLayout from 'src/components/DefaultLayout';
import Sidebar from 'src/components/Sidebar';
import Snapshot from 'src/components/visualisations/Snapshot';
import Breakdown from 'src/components/visualisations/Breakdown';
import { featureFlagCheckMixin } from '../../../mixins/featureFlagCheck';

const groupBySwitch = {
	week: 'day',
	month: 'week',
	year: 'month'
};

export default {
	components: {
		DefaultLayout,
		ContentHeader,
		Sidebar,
		Snapshot,
		Breakdown,
		Loader,
		SmartLoader
	},
	mixins: [featureFlagCheckMixin('piazza-legacy', 'piazza')],
	data() {
		const $t = str => i18n.t(str);

		return {
			isDoingInitialFetch: true,
			selectedDate: 'week',
			dateRangesOptions: [
				{
					value: 'week',
					text: $t('Last Week')
				},
				{
					value: 'month',
					text: $t('Last Month')
				},
				{
					value: 'year',
					text: $t('Last Year')
				}
			],
			aggregationOptions: [
				{ value: 'count', text: $t('Shipments') },
				{ value: 'sum', text: $t('Items') }
			],
			breakdownKeys: [
				{
					key: 'sla',
					label: $t('SLA')
				},
				{
					key: 'shipmentsdue',
					label: $t('Shipments Due')
				},
				{
					key: 'shipped',
					label: $t('Shipped'),
					color: '#008EC7'
				},
				{
					key: 'unshipped',
					label: $t('Unshipped'),
					color: '#C70000'
				},
				{
					key: 'shippingchart',
					label: null,
					chart: {
						type: 'bar',
						keys: ['unshipped', 'shipped']
					}
				},
				{
					key: 'latesout',
					label: $t('Lates Out')
				},
				{
					key: 'latescarried',
					label: $t('Lates Carried'),
					color: '#008EC7'
				},
				{
					key: 'lateschart',
					label: null,
					chart: {
						type: 'line',
						keys: ['latescarried']
					}
				}
			]
		};
	},
	computed: {
		...mapGetters('analyticsOrders', [
			'filters',
			'dateRangeProcedures',
			'dateRangeProceduresWithSum',
			'dateRangeProceduresGrouped',
			'dateRangeProceduresGroupedWithSum',
			'inProgressProcedures',
			'inProgressProceduresWithSum',
			'selectedPspName',
			'todaySlas',
			'pspList',
			'isFetchingDateRangeProcedures',
			'isFetchingInProgressProcedures'
		]),

		pspPlainList() {
			return _.map(this.pspList, 'name');
		},
		selectedPsp() {
			if (this.selectedPspName) {
				return _.find(this.pspList, { name: this.selectedPspName });
			}

			return null;
		},
		localFilters: {
			// getter
			get() {
				return {
					...this.filters
				};
			}
		},
		currentDate() {
			const groupBy = groupBySwitch[this.filters.dateRange || 'week'];
			let currentDate = moment().startOf(groupBy);
			if (groupBy === 'week') currentDate = currentDate.isoWeekday(1);

			return currentDate.format('YYYY-MM-DD');
		},
		graphDateRanges() {
			const groupBy = groupBySwitch[this.filters.dateRange || 'week'];
			return _.range(-5, 5).map(dayDiff => {
				let date = moment()
					.add(dayDiff, `${groupBy}s`)
					.startOf(groupBy);

				if (groupBy === 'week') date = date.isoWeekday(1);
				return date.format('YYYY-MM-DD');
			});
		},
		next4graphDateRanges() {
			const groupBy = groupBySwitch[this.filters.dateRange || 'week'];
			return _.range(1, 4).map(dayDiff => {
				let date = moment()
					.add(dayDiff, `${groupBy}s`)
					.startOf(groupBy);

				if (groupBy === 'week') date = date.isoWeekday(1);
				return date.format('YYYY-MM-DD');
			});
		},
		snapshotItems() {
			return (this.pspPlainList || []).reduce((acc, pspName) => {
				const pspProcedures = this.inProgressProceduresWithSum[pspName];
				if (!pspProcedures) return acc;

				const totalInProgress = pspProcedures['shipmentsInProgress.total'];
				const overdue = pspProcedures['shipmentsOverdue.total'];
				const shipmentsDueFuture = pspProcedures['shipmentsDue.future'];
				const shipmentsDueToday = pspProcedures['shipmentsDue.today'];

				acc[pspName] = [
					{
						percentage: this.todaySlas[pspName],
						key: this.$t('SLA'),
						value: `${this.todaySlas[pspName]}%`
					},
					{
						key: this.$t('Due Today'),
						value: shipmentsDueToday || 0
					},
					{
						key: this.$t('Overdue'),
						value: overdue || 0
					},
					{
						key: this.$t('Due In Future'),
						value: shipmentsDueFuture || 0
					},
					{
						key: this.$t('Total In Progress'),
						value: totalInProgress || 0
					}
				];

				return acc;
			}, {});
		},
		breakdownValues() {
			const today = moment().startOf('day');
			return (this.pspPlainList || []).reduce((acc, pspName) => {
				const previousDaysData = [];

				acc[pspName] = this.graphDateRanges.map(day => {
					const momentDay = moment(day).startOf('day');
					const isToday = momentDay.isSame(today);
					const isFutureDate = momentDay.isAfter(today);

					const dayString = day.toString();
					const shipmentsDue = this.dateRangeProceduresGroupedWithSum[pspName].shipmentsDue || {};
					const totalShipmentsDue = shipmentsDue[dayString] || 0;
					const shipmentsOut = this.dateRangeProceduresGroupedWithSum[pspName].shipmentsOut || {};
					const totalShipmentsOut = shipmentsOut[dayString] || 0;
					const shipmentsLatesOut = this.dateRangeProceduresGroupedWithSum[pspName].shipmentsLatesOut || {};
					const totalShipmentsLatesOut = isFutureDate ? null : shipmentsLatesOut[dayString] || 0;
					const unshipped = isToday || isFutureDate ? null : totalShipmentsDue - totalShipmentsOut;
					const sla = _.round((totalShipmentsOut * 100) / totalShipmentsDue);
					const shipmentsLatesCarried = isFutureDate ? null : _.sumBy(previousDaysData, 'latesout');

					const dayData = {
						date: day,
						sla: `${_.isNaN(sla) ? 0 : sla}%`,
						shipmentsdue: totalShipmentsDue,
						shipped: totalShipmentsOut,
						unshipped,
						latesout: totalShipmentsLatesOut,
						latescarried: shipmentsLatesCarried
					};

					previousDaysData.push(dayData);
					return dayData;
				});

				return acc;
			}, {});
		},
		componentClass() {
			return { 'is-loading': this.isFetchingDateRangeProcedures };
		}
	},
	async created() {
		try {
			await Promise.all([this.fetchDateRangeProceduresData(), this.fetchInProgressProceduresData()]);
		} catch (err) {
			this.$notify({ type: 'error', title: this.$t('An error occurred while fetching the data.') });
		} finally {
			this.isDoingInitialFetch = false;
		}
	},
	methods: {
		...mapActions('analyticsOrders', [
			'fetchDateRangeProceduresData',
			'fetchInProgressProceduresData',
			'changeFilters'
		]),
		handlePspSelection(psp) {
			const selectedPspName = (psp && psp.name) || null;
			this.changeFilters({ selectedPspName });
		},
		handleFiltersChange(value, filterName, triggerFetch) {
			this.changeFilters({
				[filterName]: value
			});
			if (triggerFetch) this.fetchDateRangeProceduresData();
		}
	}
};
</script>
<style lang="scss">
.AnalyticsOrderPerformance {
	.ContentHeader-actions {
		border-top: 1px solid rgba(0, 0, 0, 0.05);
		text-align: left;
		width: 100%;
		display: flex;
		flex-direction: column;

		.btn-group-toggle {
			margin-top: 0.5rem;

			&:first-child {
				margin: 0;
			}

			label {
				margin: 0;
			}
		}
		.separator {
			display: none;
			margin: 0 10px;
			height: 28px;
			background: rgba(0, 0, 0, 0.05);
			width: 1px;
			vertical-align: middle;
		}

		@media all and (min-width: 600px) {
			flex-direction: row;
			width: auto;
			border: none;

			.separator {
				display: inline-block;
			}
		}
	}

	.ContentHeader--border {
		border-bottom: 1px solid rgba(0, 0, 0, 0.05);
	}

	&-Main {
		display: flex;
		height: 100%;
		justify-content: center;
	}

	.SidebarPanel {
		display: flex;
		flex-direction: column;
		flex: 1;
		overflow: hidden;

		&.is-loading {
			align-items: center;
			justify-content: center;
		}

		@media all and (min-width: 600px) {
			flex-direction: row;
		}

		&-Content {
			flex: 1;
			overflow: auto;
		}

		@media all and (min-width: 600px) {
			.SidebarPanel-side {
				min-height: 100%;
				min-width: 250px;
				display: flex;
				justify-content: center;
			}
		}
	}

	.Panel {
		&-Header {
			display: flex;
			flex-direction: row;
			align-items: center;
		}

		&-Heading {
			font-size: 1.2rem;
			line-height: 1rem;
			margin: 0;
			padding: 1.5rem 0;
			flex: 1;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		&-Help {
			width: 20px;
			height: 20px;
			border-radius: 50%;
			background: #cbd2e0;
			overflow: hidden;
			position: relative;

			&:before {
				content: '?';
				position: absolute;
				width: 100%;
				height: 100%;
				text-align: center;
				font-weight: bold;
				color: rgba(255, 255, 255, 0.8);
				font-size: 1.1rem;
				line-height: 20px;
			}
		}

		&-Content {
			background: #fff;
			border-radius: 0.2rem;
			box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
			border: 1px solid rgba(0, 0, 0, 0.05);

			&--padded {
				padding: 1rem;
			}

			&--scrollable {
				overflow: auto;
			}

			&--noData {
				display: flex;
				justify-content: center;
			}
		}
	}

	.AnalyticsOrderPerformance-progress {
		margin-bottom: 30px;
		&.is-loading {
			display: flex;
			justify-content: center;
			align-items: center;
			min-height: 200px;
		}
	}
	.AnalyticsOrderPerformance-daily {
		&.is-loading {
			display: flex;
			justify-content: center;
			align-items: center;
		}
		.btn-group-toggle {
			margin-top: 0.5rem;

			&:first-child {
				margin: 0;
			}

			label {
				margin: 0;
			}
		}
		.separator {
			display: none;
			margin: 0 10px;
			height: 28px;
			background: rgba(0, 0, 0, 0.05);
			width: 1px;
			vertical-align: middle;
		}

		@media all and (min-width: 600px) {
			flex-direction: row;
			width: auto;
			border: none;

			.separator {
				display: inline-block;
			}

			.btn-group-toggle {
				margin: 0;
			}
		}
	}
}
</style>
// overdue: lates carried today // DUE IN FUTURE: (sum all shipments due in the next 4 days) another query // TOTAL IN
PROGRESS: DUE TODAY + OVERDUE + DUE IN FUTURE
