<template>
	<li
		class="OrderShipment"
		:class="{ 'OrderShipment-errored': hasErroredItems }"
		:data-test-id="`orderShipment${index + 1}`"
	>
		<header class="OrderShipmentHeader">
			<span class="OrderShipmentHeader-inner">
				<font-awesome-icon
					v-if="hasErroredItems"
					icon="exclamation-triangle"
					class="OrderShipmentHeader-error"
					data-test-id="orderShipmentErrorIcon"
				/>
				<span
					class="OrderShipmentHeader-title"
					data-test-id="orderShipmentTitle"
					v-text="$t('Shipment') + ' #' + (index + 1)"
				/>
			</span>
			<b-dropdown
				v-if="canEditShipment || (canSplitShipment && canReprintShipment)"
				no-caret
				size="sm"
				data-test-id="orderShipmentActionDropdown"
			>
				<template #button-content>
					<font-awesome-icon icon="ellipsis-h" />
				</template>
				<b-dropdown-item v-if="canEditShipment" data-test-id="orderShipmentEditShipment" @click="editShipment">
					{{ $t('Edit shipment') }}
				</b-dropdown-item>
				<b-dropdown-item
					v-if="canReprintShipment"
					data-test-id="orderShipmentReprintShipment"
					@click="reprintShipment"
				>
					{{ $t('Reprint') }}
				</b-dropdown-item>
			</b-dropdown>
		</header>
		<section class="OrderShipment-inner">
			<section class="OrderShipment-items">
				<shipment-item
					v-for="(item, i) in shipment.items"
					:key="item._id"
					:shipment="shipment"
					:item="item"
					:expanded="index === 0 && i === 0"
					:errored-files="erroredFiles"
				/>
				<stock-item v-for="item in shipment.stockItems" :key="item._id" :item="item" />
			</section>
			<section class="OrderShipmentDetails">
				<header class="OrderShipmentDetails_header">
					<span class="OrderShipmentDetails_title" v-text="$t('Shipping Details')" />
					<ofs-badge
						data-test-id="shipment-status"
						:status="shipment.status"
						:text="getBadgeStatusText(shipment.status)"
					/>
				</header>
				<p class="OrderShipmentDetails_address">
					<span data-test-id="ship-to-name">{{ name }}</span>
					<span data-test-id="ship-to-address1">{{ address1 }}</span>
					<span data-test-id="ship-to-address2">{{ address2 }}</span>
					<span data-test-id="ship-to-town">{{ town }}</span>
					<span data-test-id="ship-to-postcode">{{ postcode }}</span>
					<span data-test-id="ship-to-country">{{ isoCountry }}</span>
				</p>

				<p class="OrderShipmentDetails_key" v-text="$t('Courier')" />
				<p
					v-if="shipment.carrier.alias"
					data-test-id="shipment-carrier"
					v-text="`${shipment.carrier.code} / ${shipment.carrier.service} (${shipment.carrier.alias})`"
				/>
				<p
					v-else
					data-test-id="shipment-carrier"
					v-text="`${shipment.carrier.code} / ${shipment.carrier.service}`"
				/>

				<p v-if="shipment.trackingNumber" class="OrderShipmentDetails_key" v-text="`Tracking Number`" />
				<p v-if="shipment.trackingUrl" data-test-id="shipment-tracking-url">
					<a :href="shipment.trackingUrl" v-text="shipment.trackingNumber" />
				</p>
				<p v-if="shipment.trackingNumber" data-test-id="shipment-tracking" v-text="shipment.trackingNumber" />

				<p v-if="shipment.slaDays" class="OrderShipmentDetails_key" data-test-id="shipment-date-sla">
					{{ $t('Ship By') }}
					({{ shipment.slaDays }} {{ $t('day SLA') }})
				</p>
				<p v-else v-t="'Ship By'" class="OrderShipmentDetails_key" />
				<p data-test-id="shipment-date" v-text="formatDate(shipment.shipByDate)" />

				<p v-t="'Can Ship Early'" class="OrderShipmentDetails_key" />
				<p
					:class="{ 'mb-0': !shipment.shippedDate && !shipment.returnAddress }"
					data-test-id="shipment-early"
					v-text="canShipEarly ? $t('Yes') : $t('No')"
				/>

				<template v-if="shipment.shippedDate">
					<p class="OrderShipmentDetails_key" v-text="$t('Shipped')" />
					<p
						:class="{ 'mb-0': !shipment.returnAddress }"
						data-test-id="shipped-date"
						v-text="formatDate(shipment.shippedDate)"
					/>
				</template>

				<accordion v-if="shipment.returnAddress" :title="$t('Return Address')" inline>
					<p class="mb-0" data-test-id="ship-return-address" v-text="returnTo" />
				</accordion>
			</section>
		</section>
		<ship-modal :shipment="shipment" :show="isShipModalVisible" :on-close="closeShipModal" />
		<reprint-shipment-modal
			:shipment="shipment"
			:show="isReprintShipmentModalVisible"
			:on-close="closeReprintShipmentModal"
		/>
		<edit-shipment-modal
			v-if="canEditShipment"
			:shipment="shipment"
			:show="isEditShipmentModalVisible"
			:on-close="closeEditShipmentModal"
			:order-status="orderStatus"
		/>
	</li>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import { Accordion, OfsBadge } from '@workflow-solutions/ofs-vue-layout';
import ShipmentItem from './Shipment/Item';
import StockItem from './Stock/Item';
import { getBadgeStatusText } from '../../../../lib/helpers';
import ShipModal from '../../../../components/siteflow/Modal/Ship';
import EditShipmentModal from '../../../../components/siteflow/Modal/EditShipment';
import ReprintShipmentModal from '../../../../components/siteflow/Modal/ReprintShipment';
import _get from 'lodash/get';

export default {
	components: {
		ShipmentItem,
		StockItem,
		OfsBadge,
		ShipModal,
		Accordion,
		EditShipmentModal,
		ReprintShipmentModal
	},
	props: {
		index: {
			type: Number,
			required: true
		},
		shipment: {
			type: Object,
			required: true
		},
		order: {
			type: Object,
			required: true
		},
		erroredFiles: {
			type: Array,
			default: () => []
		}
	},
	data() {
		return {
			isShipModalVisible: false,
			isEditShipmentModalVisible: false,
			isReprintShipmentModalVisible: false,
			isFileModalVisible: false,
			fields: [
				{
					key: 'title',
					text: 'Item'
				},
				{
					key: 'spec',
					text: 'Spec Code'
				},
				{
					key: 'quantity',
					text: 'Quantity'
				}
			]
		};
	},
	computed: {
		...mapGetters({
			accountSettings: 'account/accountSettings',
			pspSettings: 'account/pspAccountSettings'
		}),
		hasErroredItems() {
			return this.erroredFiles.length > 0;
		},
		name() {
			return _.get(this.shipment, 'shipTo.name');
		},
		address1() {
			return _.get(this.shipment, 'shipTo.address1');
		},
		address2() {
			return _.get(this.shipment, 'shipTo.address2');
		},
		town() {
			return _.get(this.shipment, 'shipTo.town');
		},
		postcode() {
			return _.get(this.shipment, 'shipTo.postcode');
		},
		isoCountry() {
			return _.get(this.shipment, 'shipTo.isoCountry');
		},
		canShipEarly() {
			return _.get(this.shipment, 'canShipEarly');
		},
		returnTo() {
			return this.generateShippingAddress(this.shipment.returnAddress);
		},
		orderStatus() {
			return _.get(this.order, 'order.orderData.status');
		},
		pspAccountId() {
			return _.get(this.order, 'order.destination.id');
		},
		totalItemQuantity() {
			return _.sumBy(this.shipment.items, 'quantity');
		},
		canSplitShipment() {
			return (
				_.includes(['shipped', 'printready', 'live', 'complete'], this.orderStatus) &&
				(this.totalItemQuantity > 1 || this.shipment.status === 'shipped')
			);
		},
		canEditShipment() {
			const status = _.get(this.shipment, 'status');
			return !['shipped', 'cancelled'].includes(status);
		},
		canReprintShipment() {
			return (
				_get(this.pspSettings, 'allowCustomerReprints', false) &&
				_.includes(['shipped', 'printready', 'live', 'complete'], this.orderStatus)
			);
		}
	},
	async created() {
		await this.getPspAccountSettings(this.pspAccountId);
	},
	methods: {
		...mapActions({
			setShipped: 'shipment/setShipped',
			getPspAccountSettings: 'account/getPspAccountSettings'
		}),
		getBadgeStatusText,
		formatDate(time) {
			if (!time) {
				return '-';
			}
			let date = time.split('T');
			if (Array.isArray(date)) {
				date = date[0];
			}
			return moment(date).accountFormat();
		},
		viewFile(tab) {
			this.fileModalTab = tab;
			this.isFileModalVisible = true;
		},
		closeFileModal() {
			this.isFileModalVisible = false;
		},
		generateShippingAddress(address) {
			if (!address) return '';
			return ['name', 'companyName', 'address1', 'address2', 'address3', 'postcode', 'town', 'isoCountry']
				.reduce((acc, key) => (address[key] ? [...acc, address[key]] : acc), [])
				.join(', ');
		},
		ship() {
			this.isShipModalVisible = true;
		},
		closeShipModal() {
			this.isShipModalVisible = false;
		},
		editShipment() {
			this.isEditShipmentModalVisible = true;
		},
		reprintShipment() {
			this.isReprintShipmentModalVisible = true;
		},
		closeEditShipmentModal() {
			this.isEditShipmentModalVisible = false;
		},
		closeReprintShipmentModal() {
			this.isReprintShipmentModalVisible = false;
		}
	}
};
</script>
