<template>
	<div class="Tags">
		<span class="Tags-label">{{ $t('Tags') }}</span>
		<div class="d-flex align-items-start">
			<of-multi-select
				class="flex-grow-1"
				data-test-id="addCustomTags"
				name="tags"
				no-label
				:placeholder="$t('Add custom tags')"
				:taggable="true"
			/>
			<loader v-if="tagsLoading" class="ml-3"></loader>
			<b-button
				v-else-if="tagsChanged"
				class="ml-2 flex-shrink-0"
				data-test-id="orderTagUpdateBtn"
				@click="updateTags"
				v-text="$t('Update')"
			/>
		</div>
	</div>
</template>
<script>
import { mapActions } from 'vuex';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';
import _sortBy from 'lodash/sortBy';
import { withForm, OfMultiSelect } from '@workflow-solutions/ofs-vue-layout';
import Loader from '../../components/Loader';

export default {
	components: {
		OfMultiSelect,
		Loader
	},
	mixins: [withForm('tagAddForm')],
	props: {
		path: {
			type: Object,
			default: () => {}
		},
		refresh: {
			type: Function,
			default: () => {}
		}
	},
	data() {
		return {
			originalTags: '',
			tagsLoading: false
		};
	},
	computed: {
		tags() {
			return _get(this.formData, 'tags', []);
		},
		tagsChanged() {
			return !_isEqual(_sortBy(this.originalTags), _sortBy(this.formData.tags));
		}
	},
	mounted() {
		this.updateForm();
	},
	methods: {
		...mapActions({
			updatePath: 'file/updatePath'
		}),
		updateForm() {
			if (this.path && this.path.tags) {
				// Keep record of original values
				this.originalTags = this.path.tags;
				this.setFormData({
					tags: this.path.tags
				});
			} else {
				this.setFormData({});
			}
		},
		async updateTags() {
			try {
				this.tagsLoading = true;
				const tags = this.formData.tags;
				await this.updatePath({ id: this.path._id, data: { tags } });
				this.$toaster.success(this.$t('Tags succesfully updated'), { timeout: 3000 });
				this.refresh();
			} catch (err) {
				this.$toaster.error(`${this.$t('Update failed')}: ${tag}`, { timeout: 3000 });
			} finally {
				this.tagsLoading = false;
			}
		}
	}
};
</script>
<style lang="scss">
@import '~@workflow-solutions/ofs-vue-layout/dist/style/mixins';
@import '~@workflow-solutions/ofs-vue-layout/dist/style/variables';
.Tags {
	&-label {
		@include ofTextStyleFormLabels();
	}
}
</style>
