<template>
	<b-modal
		id="add-folder-modal"
		v-model="show"
		:title="$root.$t('New folder')"
		:ok-disabled="okDisabled"
		:ok-title="$root.$t('Create')"
		:cancel-title="$root.$t('Cancel')"
		size="sm"
		@show="onModalShow"
		@ok="onSave"
	>
		<b-form class="p-1">
			<of-form-input
				ref="nameInput"
				without-label
				name="name"
				type="text"
				class="col-12"
				:class="{ 'border-danger': error }"
				required
				@input="onChangeInput"
			>
			</of-form-input>
			<small v-show="error" class="text-danger">
				{{ error }}
			</small>
		</b-form>
	</b-modal>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { OfFormInput, withForm } from '@workflow-solutions/ofs-vue-layout';

export default {
	components: {
		OfFormInput
	},
	mixins: [withForm('AddFolderModalForm')],
	props: {
		showModal: {
			type: Boolean,
			default: false
		},
		refresh: {
			type: Function,
			default: () => {}
		},
		onClose: {
			type: Function,
			default: () => {}
		}
	},
	data() {
		return {
			error: ''
		};
	},
	computed: {
		...mapGetters({
			tree: 'file/tree'
		}),
		okDisabled() {
			return !this.canSubmit || !!this.error;
		},
		show: {
			get() {
				return this.showModal;
			},
			set(value) {
				if (value === false) {
					this.onClose();
				}
			}
		}
	},
	methods: {
		...mapActions({
			createPath: 'file/createPath'
		}),
		onChangeInput(newName) {
			if (/[%#]/.test(newName)) {
				this.error = this.$root.$t('Name must not contain %# characters');
			} else {
				this.error = '';
			}
		},
		onModalShow() {
			this.setFormData({});
		},
		async onSave() {
			try {
				const { name } = await this.createPath({
					type: 'folder',
					name: this.formData.name,
					parentId: this.tree._id
				});

				if (this.formData.name !== name) {
					this.$toaster.warning(`${this.$root.$t('Folder name already exists. Created with name')} ${name}`, {
						timeout: 3000
					});
				} else {
					this.$toaster.success(
						`${this.$root.$t('Folder')} ${name} ${this.$root.$t('created successfully.')}`,
						{ timeout: 3000 }
					);
				}

				this.refresh('full');
				this.onClose();
			} catch (e) {
				this.$toaster.error(this.$root.$t('Error occurred during create folder'), { timeout: 3000 });
			}
		}
	}
};
</script>
