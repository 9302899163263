<template>
	<b-modal
		v-model="isVisible"
		:title="$t('New Specification')"
		class="SpecModal"
		:ok-title="$t('Save')"
		:ok-disabled="!isValidForm"
		:cancel-title="$t('Cancel')"
		@ok="onComplete"
	>
		<b-form v-if="specTemplates">
			<of-form-radio
				:horizontal="false"
				class="SpecModal-specRadio"
				data-test-id="specModalTypeRadio"
				buttons
				name="specTemplateId"
				:options="translatedSpecTemplates"
				track-by="_id"
				:label="$t('Type')"
				label-by="description"
				button-variant="secondary"
				required
				@input="updateDescription"
			/>
			<of-form-input
				:horizontal="false"
				name="sourceSpecId"
				type="text"
				data-test-id="specModalSpecCode"
				:placeholder="$t('Spec Code')"
				:label="$t('Spec Code')"
				required
				@input="updateDescription"
			/>
			<b-form-group class="SpecModal-sizes">
				<b-input-group>
					<b-col class="p-0">
						<of-form-input
							:horizontal="false"
							name="width"
							type="text"
							data-test-id="specModalWidth"
							:label="`${$t('Width')} (${units})`"
							:placeholder="$t('Width')"
							required
							@input="updateDescription"
						/>
					</b-col>
					<b-col class="p-0">
						<of-form-input
							:horizontal="false"
							name="height"
							type="text"
							data-test-id="specModalHeight"
							:label="`${$t('Height')} (${units})`"
							:placeholder="$t('Height')"
							class="ml-2"
							required
							@input="updateDescription"
						/>
					</b-col>
				</b-input-group>
			</b-form-group>
			<of-form-input
				:horizontal="false"
				name="description"
				type="text"
				data-test-id="specModalDescription"
				class="mb-0"
				:placeholder="$t('Description')"
				:label="$t('Description')"
				required
			/>
		</b-form>
	</b-modal>
</template>

<script>
import _ from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import { OfFormInput, OfFormRadio, withForm } from '@workflow-solutions/ofs-vue-layout';
import Promise from 'bluebird';
import { i18n } from 'src/vuex';

export default {
	components: {
		OfFormInput,
		OfFormRadio
	},
	mixins: [withForm('specificationAddForm')],
	props: {
		show: Boolean,
		onClose: {
			type: Function,
			default: () => {}
		}
	},
	computed: {
		...mapGetters({
			specTemplates: 'specTemplate/specTemplates',
			accountSettings: 'account/accountSettings'
		}),
		translatedSpecTemplates() {
			return this.specTemplates.map(template => ({
				...template,
				description: this.$t(template.description)
			}));
		},
		units() {
			return _.get(this.accountSettings, 'dimensionUnit', '');
		},
		isVisible: {
			get() {
				return this.show;
			},
			set(show) {
				if (!show) {
					this.onClose();
				}
			}
		},
		specTemplate() {
			return _.find(this.specTemplates, { _id: this.formData.specTemplateId });
		},
		isValidForm() {
			return !this.formSummary.invalid;
		}
	},
	mounted() {
		this.setFormData({ type: 'Book' });
		this.findSpecTemplates();
	},
	methods: {
		...mapActions({
			findSpecTemplates: 'specTemplate/find',
			createSpecification: 'specification/create',
			createComponent: 'component/create'
		}),
		...mapActions(['dispatchSubmit']),
		async onComplete($event) {
			$event.preventDefault();
			if (this.formState.invalid) {
				this.$toaster.error('Please fill out all fields in the form.', 3000);
				return;
			}
			if (this.specTemplate) {
				const requiredComponents = this.specTemplate.components.filter(c => c.required === true);

				this.formData.componentIds = await Promise.map(requiredComponents, async component => {
					if (this.formData.width && this.formData.height) {
						component.width = this.formData.width;
						component.height = this.formData.height;
					}
					const newComponent = await this.createComponent(component);
					return newComponent._id;
				});
			}

			const newSpec = await this.dispatchSubmit(this.createSpecification(this.formData));
			this.$router.push({ name: 'specifications.edit', params: { id: newSpec._id } });
		},
		async updateDescription() {
			await this.$nextTick();
			let title = '';

			if (this.formData.width && this.formData.height) {
				title += `${this.formData.width}x${this.formData.height} (${this.units})`;
			}

			if (this.specTemplate) {
				if (this.formData.width && this.formData.height) {
					title += ' - ';
				}

				title += `${this.translateString(this.specTemplate.description)} ${this.translateString(
					this.formData.type
				)}`;
			}

			this.updateFormData({ description: title });
		},
		translateString(str) {
			return i18n.t(str);
		}
	}
};
</script>
<style lang="scss">
@import '~@workflow-solutions/ofs-vue-layout/dist/style/variables';

.SpecModal {
	.b-form-group {
		input {
			width: 100%;
		}
	}
	&-specRadio {
		.btn-group {
			width: 100%;

			.btn {
				flex: 1;
				padding: 3.5rem 0.5rem 0.5rem;
				position: relative;
				background-repeat: no-repeat !important;
				background-size: 44% !important;
				background-position: 50% 0 !important;

				white-space: normal;
				line-height: 1.2;

				&:nth-child(1) {
					background-image: url('../../../public/img/specs/wirobound.png') !important;
				}

				&:nth-child(2) {
					background-image: url('../../../public/img/specs/casebound.png') !important;
				}

				&:nth-child(3) {
					background-image: url('../../../public/img/specs/softcase.png') !important;
				}

				&:nth-child(4) {
					background-image: url('../../../public/img/specs/saddle.png') !important;
				}

				&:not(:disabled).active,
				&:not(:disabled):active,
				&:not(:disabled):focus {
					background: $of-color-highlights;
					border-color: #d2ddf4;
					color: $of-color-dark;
				}
			}
		}
	}
	&-sizes {
		margin: 0;

		fieldset {
			flex: 1;
		}
		input,
		select {
			width: 100% !important;
		}
	}
}
</style>
