<template>
	<div id="app" v-if="ready" :data-version="version">
		<router-view />
		<Notification />
		<PreviewModal />
	</div>
</template>

<script type="text/javascript">
import { mapActions, mapGetters } from 'vuex';
import { Notification } from '@workflow-solutions/ofs-vue-layout';
import _get from 'lodash/get';
import PreviewModal from './components/modals/PreviewModal';

export default {
	name: 'BrandCentre',
	components: {
		Notification,
		PreviewModal
	},
	data() {
		return {
			ready: false,
			isConsentInitialized: false,
			version: process.env.VERSION
		};
	},
	computed: {
		...mapGetters('auth', ['isAuthenticated', 'user', 'availableAccounts']),
		...mapGetters('account', ['currentAccount', 'accountList']),
		...mapGetters('featureToggle', ['features']),
		...mapGetters('lang', ['lang'])
	},
	async beforeCreate() {
		this.tempToken = _get(this, '$router.history.pending.query.token');
	},
	async created() {
		let hasLoggedIn = false;
		if (!this.$store.state.auth.isAuthenticated && this.tempToken) {
			try {
				await this.$store.dispatch('auth/loginHpId', this.tempToken);
				hasLoggedIn = true;
			} catch (err) {
				this.$toaster.error(this.$t('An error occurred while checking the temporary token'), 3000);
			}
		}
		this.$router.afterEach(this.redirectIfNotAuth);
		try {
			await this.fetchBaseAccountData();
		} catch (err) {
			console.error(err);
		}
		this.redirectIfNoBrands();
		this.$router.afterEach(this.redirectIfNoBrands);

		await this.loadLanguage({ lang: this.lang });
		this.ready = true;

		this.switchAccount();
		this.$router.afterEach(this.switchAccount);

		if (this.$HP_MODE) { // eslint-disable-line
			this.updateMenu({
				path: '0.items.0.items',
				content: [
					{ title: this.$t('Title Reports'), name: 'analytics.title.reports' },
					{ title: this.$t('Title By Country'), name: 'analytics.title.by.country' }
				],
				updateMethod: 'concat'
			});
		}

		// Set User ID
		if (this.$ga && this.user) {
			this.$ga.set('userId', this.user._id);
		}

		this.checkCookieConsent();
		if (hasLoggedIn) {
			const { redirectTo = '/' } = this.$route.query || {};
			this.$router.push(redirectTo);
		}
	},
	watch: {
		isAuthenticated: {
			immediate: true,
			handler() {
				this.redirectIfNotAuth();
				if (this.isAuthenticated) {
					this.setupHourlyTokenRefresh();
				}
			}
		},
		features: {
			immediate: true,
			handler: 'checkFeatures'
		},
		$route: {
			immediate: true,
			handler: 'checkFeatures'
		}
	},
	methods: {
		...mapActions('account', ['getVars', 'changeAccount', 'fetchBaseAccountData']),
		...mapActions('user', ['getMemberships']),
		...mapActions('menu', ['updateMenu']),
		...mapActions('lang', ['loadLanguage']),
		...mapActions('auth', ['logout', 'setupHourlyTokenRefresh']),
		redirectIfNotAuth() {
			if (!this.isAuthenticated && this.$route.name !== 'login') this.$router.push('/login');
		},
		// Check to see if the route requires a feature flag
		// Redirect to the homepage if do not have permission
		// If HP Mode (Piazza) - Disable feature for now
		checkFeatures() {
			if (!this.features) {
				return;
			}

			if (this.$route.meta.feature) {
				if (this.features[this.$route.meta.feature] === false || this.$HP_MODE) {
					this.$router.replace('/');
				}
			}
		},
		async redirectIfNoBrands() {
			var accounts = (this.availableAccounts || []);
			const hasBrandAccess = !!(accounts.find(account => account.types.includes('brand') || account.type === 'brand'));

			if (!hasBrandAccess && this.$route.name !== 'login') {
				await this.logout();
				this.$router.push('/login');
			}
		},
		switchAccount() {
			if (!this.$route.query.$switchAccount) {
				return;
			}
			const account = this.accountList.find(acc => acc.name === this.$route.query.$switchAccount);
			if (account) {
				this.changeAccount(account);
			}
		},
		checkCookieConsent() {
			if (!this.isConsentInitialized) {
				const script = document.createElement('script');
				const dataDomainScript = window.$config.dataDomainScript;
				if (dataDomainScript) {
					script.dataset.domainScript = window.$config.dataDomainScript;
				}

				script.src = window.$config.cookieConsentUrl;
				script.type = 'text/javascript';
				script.charset = 'UTF-8';

				const scriptTags = document.getElementsByTagName('script');
				scriptTags[0].parentNode.insertBefore(script, scriptTags[0]);

				this.isConsentInitialized = true;
			}
		}
	}
};
</script>

<style lang="scss">
:focus {
	outline: none;
}

#onetrust-consent-sdk #onetrust-policy-title,
#onetrust-consent-sdk #onetrust-policy-text,
#onetrust-banner-sdk .ot-sdk-button,
#onetrust-banner-sdk button,
#onetrust-pc-sdk h2,
#onetrust-pc-sdk h3,
#onetrust-pc-sdk p,
#onetrust-pc-sdk button,
#onetrust-pc-sdk a,
#onetrust-pc-sdk div {
	font-family: Source Sans Pro, Helvetica Neue, Helvetica, Arial, sans-serif !important;
}

.modal.show {
	display: block;
}

.ContentHeader {
	z-index: 1;
}

.AccountSwitcher {
	display: flex;
	align-items: center;
}

.v-toaster {
	top: 80px;
}
</style>
