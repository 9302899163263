<template>
	<section class="ErrorList">
		<content-header class="mb-3" :title="$t('Error List')" no-padding />
		<b-table :items="items" :fields="fields">
			<template v-slot:cell(timestamp)="data">
				{{ formatDate(data.item.timestamp) }}
			</template>
			<template v-slot:cell(title)="data">
				<div class="d-flex flex-column">
					<strong v-text="data.item.title" />
					<!-- eslint-disable-next-line vue/no-v-html -->
					<span v-html="$options.filters.linkifyStr(data.item.description)" />
				</div>
			</template>
		</b-table>
	</section>
</template>

<script>
import moment from 'moment';
import { ContentHeader } from '@workflow-solutions/ofs-vue-layout';
import { linkifyStr } from '../../../../lib/filters';

export default {
	components: {
		ContentHeader
	},
	filters: {
		linkifyStr
	},
	props: {
		items: {
			type: Array,
			default: () => []
		}
	},
	data() {
		return {
			fields: [
				{
					key: 'title'
				},
				{
					key: 'timestamp'
				}
			]
		};
	},
	methods: {
		formatDate(time) {
			return moment(time).accountFormat(' LT');
		}
	}
};
</script>

<style lang="scss">
.ErrorList {
	padding: 20px 20px 20px 0;
	word-break: break-word;
}
</style>
