<template>
	<DefaultLayout class="FileGallery">
		<OfsPanel>
			<ContentHeader class="mb-3" :no-padding="true" :title="$t('Order files')" />
			<section
				v-infinite-scroll="loadMore"
				infinite-scroll-disabled="loading"
				infinite-scroll-distance="300"
				infinite-scroll-immediate-check="false"
				class="FileList"
			>
				<AsyncLoader :is-loading="loading">
					<b-row>
						<b-col v-for="file in files" :key="file._id" xl="2" lg="3" md="4" sm="6">
							<div class="ImageCard" @click="openFileModal(file)">
								<div class="ImageWrapper"><img v-img-error :src="file.thumbnailUrl" /></div>
								<div class="p-2 FileDetailsWrapper">
									<div class="d-flex justify-content-between align-content-center">
										<span class="FileDesc" data-test-id="fileType">{{ file.type }}</span>
										<ofs-badge
											v-if="file.status !== 'ready'"
											class="InlineBadge"
											:status="file.cleaned && file.cleaned.version ? $t('cleaned') : file.status"
											:text="mapBadgeText(file.status)"
										/>
									</div>
									<div class="FileDetails">
										<span data-test-id="fileCreated">{{ file.created | fromDate }}</span>
										<span data-test-id="filePages"> {{ file.pages }} <span v-t="'Pages'" /> </span>
									</div>
								</div>
							</div>
						</b-col>
					</b-row>
				</AsyncLoader>
			</section>
		</OfsPanel>

		<file-modal :show="isFileModalVisible" :file="file" :on-close="closeFileModal" />
	</DefaultLayout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { ContentHeader, OfsPanel, OfsBadge } from '@workflow-solutions/ofs-vue-layout';
import infiniteScroll from 'vue-infinite-scroll';
import DefaultLayout from '../../components/DefaultLayout';
import navigationMixin from '../../mixins/navigationMixin';
import AsyncLoader from '../../components/AsyncLoader';
import { dateFormatMixin } from '../../mixins/dateFormatMixin';
import _get from 'lodash/get';
import _throttle from 'lodash/throttle';
import FileModal from '../../components/siteflow/Modal/File';
import { featureFlagCheckMixin } from '../../mixins/featureFlagCheck';

export default {
	components: {
		DefaultLayout,
		ContentHeader,
		OfsPanel,
		OfsBadge,
		AsyncLoader,
		FileModal
	},
	directives: {
		infiniteScroll
	},
	mixins: [navigationMixin, dateFormatMixin(), featureFlagCheckMixin('piazza-brand-order-files-page'), featureFlagCheckMixin('piazza-legacy', 'piazza')],
	data() {
		return {
			pageSize: 60,
			page: 1,
			loading: false,
			isFileModalVisible: false,
			statusTextMap: {
				notreceived: this.$t('Not Received'),
				failed: this.$t('Failed')
			}
		};
	},
	computed: {
		...mapGetters({ files: 'order/files', fileCount: 'order/fileCount', file: 'order/file' })
	},
	watch: {
		'$route.params.orderId': function update() {
			this.refresh();
		}
	},
	async mounted() {
		await this.fetchFileGallery();
	},
	beforeDestroy() {
		this.resetFileState();
	},
	methods: {
		...mapActions({
			fetchFiles: 'order/fetchFiles',
			resetFileState: 'order/resetFileState',
			fetchFile: 'order/fetchFile'
		}),
		loadMore() {
			this.fetchFileGallery(true);
		},
		refresh() {
			this.page = 1;
			this.resetFileState();
			this.fetchFileGallery(null, true);
		},
		fetchFileGallery: _throttle(
			function(loadMore, reset) {
				this.fetchData(loadMore, reset);
			},
			500,
			{ trailing: false }
		),

		async fetchData(loadMore = false, reset) {
			// prevent infinite loop
			if (this.page * this.pageSize > this.fileCount) {
				return;
			}
			if (loadMore) {
				this.page++;
			} else {
				this.loading = true;
			}
			await this.fetchFiles({
				query: {
					sort: 'created',
					direction: -1,
					pagesize: this.pageSize,
					page: this.page,
					orderId: _get(this, '$route.params.orderId')
				},
				reset
			});
			if (!loadMore) {
				this.loading = false;
			}
		},
		async openFileModal(file) {
			await this.fetchFile({ id: file._id });
			this.isFileModalVisible = true;
		},
		closeFileModal() {
			this.isFileModalVisible = false;
		},
		mapBadgeText(status) {
			return _get(this.statusTextMap, status, status);
		}
	}
};
</script>

<style lang="scss">
@import '../../../node_modules/@workflow-solutions/ofs-vue-layout/dist/style/variables';

.FileGallery {
	.AsyncLoader {
		display: flex;
		flex: 1;
	}
	.ContentHeader {
		display: block;
		@media (min-width: 768px) {
			display: flex;
		}
		.ContentHeader-header {
			margin-bottom: 1rem;
			@media (min-width: 768px) {
				margin-bottom: 0;
			}
		}
	}
	.ContentHeader-actions {
		select {
			display: block;
			width: 100%;
			margin-bottom: 1rem;
			@media (min-width: 768px) {
				display: inline-block;
				min-width: 150px;
				width: auto;
				margin-right: 1rem;
				margin-bottom: 0;
				&:last-child {
					margin-right: 0;
				}
			}
		}
	}
	.FileList {
		.ImageCard {
			overflow: hidden;
			display: inline-block;
			width: 100%;
			height: 250px;
			background-color: $of-color-white;
			border: 1px solid $of-color-grey-3;
			border-radius: $of-border-radius;
			cursor: pointer;

			&:hover {
				border: 1px solid $of-color-aqua;
			}

			.ImageWrapper {
				display: flex;
				height: 170px;
				width: 100%;
				align-items: center;
				justify-content: center;
				background: $of-color-base;
				border-radius: $of-border-radius;
				overflow: hidden;

				img {
					max-width: 100%;
					height: 94%;
				}

				.broken-image {
					height: 90px;
				}
			}
		}

		.FileDetailsWrapper {
			height: 80px;
			flex-direction: column;
			display: flex;
			justify-content: center;

			.FileDetails {
				display: flex;
				justify-content: space-between;
				color: $of-color-grey-2;
				font-size: $of-font-size-x-small;
			}

			.FileDesc {
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}
		}
	}
}
</style>
