<template>
	<div>
		<div class="Preview--header">
			<h1 class="h6 Preview-title">{{ fileName || $t('Previewing file') }}</h1>
			<div class="Preview--controls">
				<section
					class="p-1 u-cursorPointer text-info"
					data-test-id="previewModalControlsZoomOut"
					:title="$t('Zoom Out')"
					@click.prevent="handleUserAction('zoomOut')"
				>
					<icon name="custom-search-minus" />
				</section>
				<section
					class="p-1 u-cursorPointer text-info"
					data-test-id="previewModalControlsZoomIn"
					:title="$t('Zoom In')"
					@click.prevent="handleUserAction('zoomIn')"
				>
					<icon name="custom-search-plus" />
				</section>
				<section
					class="p-1 u-cursorPointer text-info"
					data-test-id="previewModalControlsActualSize"
					:title="$t('Actual Size')"
					@click.prevent="handleUserAction('actualSize')"
				>
					<icon name="custom-actual-size" />
				</section>
				<section
					class="p-1 u-cursorPointer text-info"
					data-test-id="previewModalControlsFitToPage"
					:title="$t('Fit to page')"
					@click.prevent="handleUserAction('fitToPage')"
				>
					<icon name="custom-fit-to-page" />
				</section>
			</div>
			<div v-if="hasClose">
				<button
					type="button"
					data-test-id="previewModalCloseBtn"
					class="close"
					:aria-label="$t('Close')"
					@click.prevent="onClose"
				>
					<span aria-hidden="true">×</span>
				</button>
			</div>
		</div>
		<div class="Preview">
			<div class="Preview--sidebar">
				<div v-if="book && originalSpineIsValid" class="Preview-control">
					<h6 class="mb-1">{{ $t('Spine Size') }}</h6>
					<div
						v-for="(item, index) in spines"
						:key="`spines-${index}`"
						class="d-flex justify-content-between align-items-center mb-2"
					>
						<section class="d-flex align-items-center">
							<of-form-checkbox
								without-label
								class="Preview-spineCheckbox"
								:name="`spines.${index}.active`"
								:disabled="isPreviewMode"
								@input="onChangeSpineState"
							/>
							<div
								class="Preview-circle rounded-circle mx-2"
								:style="{ 'background-color': item.colour }"
							/>
							<span>{{ item.title }}</span>
						</section>
						<section
							:style="{ opacity: item.active ? 1 : 0.5 }"
							class="Preview-dimensions d-flex align-items-center ml-2"
						>
							<of-form-input
								without-label
								type="number"
								:name="`spines.${index}.valueInUnits`"
								class="Preview-spineInput h-100 border-0 rounded-0"
								data-test-id="spineAdjustSpineSizeInput"
								:disabled="isPreviewMode || !item.active || item.type === 'spine.original'"
								:required="item.type === 'spine.original'"
								@input="onChangeSpine($event, index)"
							/>
							<of-form-select
								without-label
								:options="units"
								:name="`spines.${index}.unit`"
								:disabled="!item.active || item.type === 'spine.original'"
								class="Preview-spineSelect h-100 border-0 rounded-0"
								required
							>
							</of-form-select>
						</section>
					</div>
					<of-form-select
						:options="adjustMethod"
						name="adjustMethod"
						class="Preview-adjustMethod w-100"
						:disabled="isPreviewMode"
						:label="$t('Method')"
					>
					</of-form-select>
					<b-row align-v="center" class="Preview-spineSubmit m-0">
						<b-col cols="7" class="p-0 text-left">
							<b-button
								data-test-id="spineAdjustToolResetBtn"
								variant="secondary"
								:class="{ 'Preview-notAllowed': isSavingNewVersion || isToolStarted }"
								:disabled="isSavingNewVersion || isToolStarted"
								@click="onResetSpineAdjust"
							>
								{{ $t('Reset') }}
							</b-button>
						</b-col>
						<b-col cols="5" class="p-0 text-right">
							<of-submit-button
								data-test-id="spineAdjustToolPreviewBtn"
								:style="{
									opacity:
										!(isPreviewMode || isSavingNewVersion || isToolStarted) && newSpine > 0
											? 1
											: 0.5
								}"
								:class="{
									'Preview-notAllowed':
										isPreviewMode || isSavingNewVersion || isToolStarted || newSpine <= 0
								}"
								@click="onClickSpinePreview"
							>
								{{ $t('Preview') }}
							</of-submit-button>
						</b-col>
					</b-row>
				</div>

				<div v-if="!book" class="Preview-control">
					<h6 class="mb-1">{{ $t('Separations') }}</h6>
					<div v-for="item in page.inks" :key="item.colorantName" class="d-flex align-items-center">
						<of-form-checkbox
							without-label
							class="Preview-spineCheckbox"
							:name="item.alias"
							:checked="existsInArray(item.colorantName, preferences.selectedInks)"
							@input="toggleInks(item.colorantName)"
						/>
						<div
							class="Preview-circle rounded-circle mx-2"
							:style="{ 'background-color': 'rgb(' + item.rgb.join(', ') + ')' }"
						/>
						<span>{{ item.colorantName }}</span>
					</div>
				</div>

				<div class="Preview-control">
					<h6 class="mb-1">{{ book && !isPreviewMode && newSpine ? $t('Page Sizes') : $t('Page Size') }}</h6>
					<b-row class="m-0">
						<b-col v-if="book && !isPreviewMode && newSpine && originalSpineIsValid" cols="2" class="p-0">
							{{ $t('Old') }}
						</b-col>
						<b-col cols="10" class="p-0" data-test-id="previewModalPageSize">
							{{ calculatedWidth | toFixed(3) }} x {{ calculatedHeight | toFixed(3) }}
							{{ preferences.unit }}
						</b-col>
					</b-row>
					<b-row v-if="book && !isPreviewMode && newSpine && originalSpineIsValid" class="m-0">
						<b-col cols="2" class="p-0">{{ $t('New') }}</b-col>
						<b-col cols="10" class="p-0">
							{{ (calculatedWidth + newCalculatedWidth) | toFixed(3) }} x
							{{ calculatedHeight | toFixed(3) }} {{ preferences.unit }}
						</b-col>
					</b-row>
				</div>

				<div class="Preview-control">
					<h6 class="mb-1">{{ $t('Boxes') }}</h6>
					<div v-for="item in showedBoxes" :key="item.type" class="d-flex align-items-center">
						<of-form-checkbox
							without-label
							class="Preview-spineCheckbox"
							:name="item.type"
							:checked="existsInArray(item.type, preferences.selectedBoxes)"
							@input="toggleBoxes(item.type)"
						/>
						<div class="Preview-circle rounded-circle mx-2" :style="{ 'background-color': item.colour }" />
						<span>{{ item.type }}</span>
					</div>
				</div>

				<div class="Preview-control">
					<h6 class="mb-1">{{ $t('Settings') }}</h6>
					<div class="d-flex align-items-center">
						<of-form-checkbox
							without-label
							class="Preview-spineCheckbox"
							name="showRulers"
							@input="onChangeRulers"
						/>
						<span class="ml-2">{{ $t('Show Rules') }}</span>
					</div>
					<div class="d-flex align-items-center">
						<of-form-checkbox
							without-label
							class="Preview-spineCheckbox"
							name="showOverview"
							@input="onChangeOverview"
						/>
						<span class="ml-2">{{ $t('Show Overview') }}</span>
					</div>
					<div class="d-flex align-items-center">
						<of-form-checkbox
							without-label
							class="Preview-spineCheckbox"
							name="showOverprint"
							@input="onChangeOverprint"
						/>
						<span class="ml-2">{{ $t('Show Overprint') }}</span>
					</div>
				</div>
			</div>
			<div class="Preview--main pl-3">
				<section
					v-if="isSavingNewVersion || isToolStarted"
					class="Preview--frame d-flex flex-column justify-content-center align-items-center"
				>
					<h5 class="text-muted">
						{{ isSavingNewVersion ? $t('Uploading new version') : $t('Loading new version') }}
					</h5>
					<Loader />
				</section>
				<iframe
					v-show="!(isSavingNewVersion || isToolStarted)"
					ref="iframe"
					class="Preview--frame"
					frameborder="0"
					layout="row"
					:src="frameUrl"
				/>
			</div>
		</div>
		<section v-if="book" class="Preview-footer d-flex justify-content-end align-items-center mt-3 pt-2">
			<b-button
				data-test-id="spineAdjustToolSaveBtn"
				variant="primary"
				:class="{ 'Preview-notAllowed': isSavingNewVersion || isToolStarted }"
				:disabled="isSavingNewVersion || isToolStarted"
				@click="onSaveSpineAdjust"
			>
				{{ $t('Save') }}
			</b-button>
		</section>
	</div>
</template>
<script type="text/javascript">
import { mapGetters, mapActions } from 'vuex';
import { OfFormInput, OfFormSelect, OfFormCheckbox, OfSubmitButton, withForm } from '@workflow-solutions/ofs-vue-layout';
import _ from 'lodash';
import { i18n } from 'src/vuex';
import { toFixed } from '../../../lib/filters';
import { unitToPT, ptToUnit } from '../../../lib/helpers';
import withSubscriptionMixin from '../../../mixins/withSubscriptionMixin';
import Loader from '../../../components/Loader';

const layersOptions = { color: '#fff', fillOpacity: 0.5, stroke: false };

export default {
	components: {
		OfFormInput,
		OfFormSelect,
		OfSubmitButton,
		OfFormCheckbox,
		Loader
	},
	filters: {
		toFixed
	},
	mixins: [withSubscriptionMixin, withForm('PreviewModalSpineForm')],
	props: {
		book: {
			type: Object,
			default: null
		},
		component: {
			type: Object,
			default: null
		},
		file: {
			type: Object,
			default: null
		},
		url: {
			type: String,
			default: null
		},
		revertBookComponentUncheckedState: {
			type: Function,
			default: null
		},
		toolName: {
			type: String,
			default: null
		},
		activePage: {
			type: Number,
			default: undefined
		},
		onClose: {
			type: Function,
			default: () => {}
		},
		hasClose: Boolean
	},
	data() {
		const $t = str => i18n.t(str);
		return {
			isSavingNewVersion: false,
			isPreviewMode: false,
			isToolStarted: false,
			newSpine: 0,
			adjustMethod: [
				{ text: $t('Scale Cover'), value: 'scaleCover' },
				{ text: $t('Resize Cover'), value: 'resizeCover' },
				{ text: $t('Scale Spine'), value: 'scaleSpine' },
				{ text: $t('Resize Spine'), value: 'resizeSpine' }
			],
			spines: [
				{
					colour: '#2196F3',
					active: true,
					weight: 1,
					key: 'oldSpineWidth',
					type: 'spine.original',
					title: $t('Original Spine'),
					value: null
				},
				{
					colour: '#F06292',
					active: false,
					weight: 1,
					dashArray: '6 6',
					type: 'spine.new',
					key: 'newSpineWidth',
					title: $t('New Spine'),
					value: null
				}
			],
			preferences: {
				selectedBoxes: [],
				selectedInks: [],
				showBoxes: true,
				showInks: true,
				showOverprint: true,
				showOverview: true,
				showRulers: true,
				unit: 'mm'
			},
			frameUrl: '',
			isReady: false,
			page: {}
		};
	},
	computed: {
		...mapGetters('tool', ['toolConfig', 'tool']),
		...mapGetters({
			accountSettings: 'account/accountSettings'
		}),
		tags() {
			return [
				`isbn:${_.get(this.book, 'properties.isbn')}`,
				`bookId:${_.get(this.book, '_id')}`,
				`componentId:${_.get(this.component, 'componentId')}`,
				`fileId:${_.get(this.file, '_id')}`
			];
		},
		requiredSpineBulk() {
			const requiredSpineBulk = _.get(this.book, 'properties.requiredSpineBulk', 0);
			return toFixed(requiredSpineBulk, 3);
		},
		showedBoxes() {
			return _.get(this.page, 'boxes', []).filter(box => !['CropBox', 'ArtBox'].includes(box.type));
		},
		units() {
			const units = _.get(this.accountSettings, 'dimensionUnit');

			if (units) {
				return [units];
			}

			return [];
		},
		originalSpine() {
			return _.get(this.book, 'properties.spineBulk', 0);
		},
		originalSpineIsValid() {
			const pageWidth = _.get(this.page, 'width', 0);
			return this.originalSpine > 0 && this.originalSpineInPT < pageWidth;
		},
		preferencesUnit() {
			return _.get(this.preferences, 'unit', 'mm');
		},
		originalSpineInPT() {
			return unitToPT({ value: this.originalSpine, unit: this.preferencesUnit });
		},
		newSpineInPT() {
			return unitToPT({ value: this.newSpine, unit: this.preferencesUnit });
		},
		fileName() {
			return _.get(this.file, 'name');
		},
		calculatedWidth() {
			const width = _.get(this.page, 'width', 0);
			return ptToUnit({ value: width, unit: this.preferencesUnit });
		},
		newCalculatedWidth() {
			return this.newSpine - this.originalSpine;
		},
		calculatedHeight() {
			const height = _.get(this.page, 'height', 0);
			return ptToUnit({ value: height, unit: this.preferencesUnit });
		}
	},
	watch: {
		tool() {
			if (this.tool && ['complete', 'error'].includes(this.tool.status)) {
				this.isToolStarted = false;
				this.unsubscribeAll();

				if (this.toolName === 'spineManipulator' && this.tool.status === 'complete') {
					this.frameUrl = this.buildUrl(this.tool.outputs.url);
					this.sendSpineInstructions();
				}
			}
		},
		activePage: {
			immediate: true,
			handler: 'sendActivePage'
		},
		isReady: {
			immediate: true,
			handler: 'sendActivePage'
		}
	},
	async mounted() {
		this.preferences.unit = _.get(this.accountSettings, 'dimensionUnit', 'mm');
		await this.fetchData();
		this.bindViewerMessages();
		return null;
	},
	beforeDestroy() {
		this.unbindViewerMessages();
	},
	methods: {
		...mapActions('tool', ['getToolConfig', 'clearStateAtPath', 'saveToolResults', 'clearStateAtPath']),
		...mapActions('tool', { dispatchStartTool: 'startTool', observeTool: 'observeOne' }),
		...mapActions('previewer', { closePreviewer: 'hide' }),
		...mapActions({
			updateFormField: 'form/updateFormField',
			fileDownloadUrl: 'file/fileDownloadUrl'
		}),
		buildUrl(url) {
			return `${window.$config.mediaBase}/#/frame/${encodeURIComponent(url)}`;
		},
		async getIframeUrl() {
			if (this.url) {
				return this.buildUrl(this.url);
			}

			const { url } = await this.fileDownloadUrl({ id: this.file._id });
			return this.buildUrl(url);
		},
		sendParentUrl() {
			this.$set(this.$refs.iframe, 'onload', () => {
				this.postMessageToViewer({
					command: 'setParentUrl',
					args: window.location.origin
				});
				this.postMessageToViewer({
					command: 'setPreferencesUnit',
					args: this.preferencesUnit
				});
			});
		},
		async fetchData() {
			this.frameUrl = await this.getIframeUrl();
			await this.sendParentUrl();
			await this.$nextTick();
			this.newSpine = _.toFinite(_.get(this.book, 'newSpine', this.requiredSpineBulk));
			this.spines = this.spines.map((s, index) => ({
				...s,
				valueInUnits: index === 0 ? this.originalSpine : this.newSpine,
				value: index === 0 ? this.originalSpineInPT : this.newSpineInPT,
				active: true,
				unit: this.preferencesUnit
			}));
			this.setFormData({ adjustMethod: this.adjustMethod[0].value, spines: this.spines });

			if (this.toolName) {
				await this.getToolConfig(this.toolName);
			}
		},
		postMessageToViewer(message) {
			if (this.$refs.iframe) {
				this.$refs.iframe.contentWindow.postMessage(message, `${window.$config.mediaBase}/#/frame`);
			}
		},
		bindViewerMessages() {
			window.addEventListener(
				'message',
				event => {
					this.incomingMessageHandler(event.data);
				},
				false
			);
		},
		unbindViewerMessages() {
			window.removeEventListener('message', event => {
				this.incomingMessageHandler(event.data);
			});
		},
		handleUserAction(command, args) {
			this.postMessageToViewer({ command, args });
		},
		incomingMessageHandler({ args, command }) {
			switch (command) {
				case 'pageChanged':
					this.page = args;
					break;
				case 'viewInfo':
					this.page = { ...this.page, ...args.data.page };
					if (this.book && this.originalSpineIsValid) {
						this.sendSpineInstructions();
					}

					if (this.book && !this.originalSpineIsValid) {
						const maxSpine = ptToUnit({ value: this.page.width, unit: this.preferencesUnit });
						const maxSpineString = `${toFixed(maxSpine)} ${this.preferencesUnit}`;
						this.$toaster.error(
							`${this.$t('Spine Bulk is invalid, it should be less')} ${maxSpineString}`,
							{ timeout: 3000 }
						);
					}
					break;
				case 'setPreferences':
					this.preferences = { selectedInks: [], ...args };
					this.$set(this.formData, 'showRulers', this.preferences.showRulers);
					this.$set(this.formData, 'showOverview', this.preferences.showOverview);
					this.$set(this.formData, 'showOverprint', this.preferences.showOverprint);
					this.preferences.selectedInks.forEach(ink => this.$set(this.formData, ink, true));
					this.preferences.selectedBoxes.forEach(box => this.$set(this.formData, box, true));
					break;
				case 'ready':
					this.isReady = true;
					break;
				default:
					break;
			}
		},
		toggleInks(item) {
			const list = _.get(this.preferences, 'selectedInks', []);
			const idx = list.indexOf(item);

			if (idx > -1) {
				list.splice(idx, 1);
			} else {
				list.push(item);
			}

			this.handleUserAction('setPreferences', {
				key: 'selectedInks',
				value: list
			});
		},
		toggleBoxes(item) {
			const list = _.get(this.preferences, 'selectedBoxes', []);
			const idx = list.indexOf(item);

			if (idx > -1) {
				list.splice(idx, 1);
			} else {
				list.push(item);
			}

			this.handleUserAction('setPreferences', {
				key: 'selectedBoxes',
				value: list
			});
		},
		existsInArray(item, list) {
			return (list || []).indexOf(item) > -1;
		},
		sendSpineInstructions() {
			this.handleUserAction('setSpines', { layersOptions, ...this.formData });
		},
		onClickSpinePreview() {
			if (this.isPreviewMode) {
				return;
			}

			if (!this.isToolStarted && this.newSpine > 0) {
				this.isPreviewMode = true;
				const spines = this.formData.spines.map(spine => ({ ...spine, active: false }));
				this.updateFormData({ spines });
				this.startTool();
			}
		},
		onChangeRulers(value) {
			this.$set(this.preferences, 'showRulers', value);
			this.handleUserAction('setPreferences', {
				key: 'showRulers',
				value
			});
		},
		onChangeOverview(value) {
			this.$set(this.preferences, 'showOverview', value);
			this.handleUserAction('setPreferences', {
				key: 'showOverview',
				value
			});
		},
		onChangeOverprint(value) {
			this.$set(this.preferences, 'showOverprint', value);
			this.handleUserAction('setPreferences', {
				key: 'showOverprint',
				value
			});
		},
		onChangeSpine(value, index) {
			this.newSpine = _.toFinite(value);
			this.updateFormField({
				formName: 'PreviewModalSpineForm',
				fieldPath: `spines[${index}].value`,
				value: unitToPT({ value: this.newSpine, unit: this.preferencesUnit })
			});
			this.spinesChangeHandler();
		},
		getToolInputValues() {
			const inputs = {};

			_.forIn(this.toolConfig.inputs, input => {
				const inputValue = _.get(this.formData, input.key);
				if (!_.isUndefined(inputValue)) {
					return Object.assign(inputs, { [input.key]: inputValue });
				}

				const spine = _.find(this.formData.spines, { key: input.key });

				if (!_.isUndefined(spine)) {
					return Object.assign(inputs, { [input.key]: `${spine.value}pt` });
				}

				return inputValue;
			});

			return inputs;
		},
		async startTool() {
			this.isToolStarted = true;
			const inputs = this.getToolInputValues();
			const tool = {
				tags: this.tags,
				tasks: [this.toolName],
				inputs,
				fileIds: [this.file._id]
			};
			const toolResult = await this.dispatchStartTool(tool);

			await this.addSubscription(this.observeTool({ id: toolResult._id }));
		},
		onResetSpineAdjust() {
			this.isPreviewMode = false;
			this.fetchData();
		},
		async onSaveSpineAdjust() {
			this.isSavingNewVersion = true;
			const { tool, file, toolConfig } = this;

			try {
				if (!_.isEmpty(tool)) {
					await this.saveToolResults({ tool: { ...tool, tags: this.tags }, file, toolConfig });
					this.clearStateAtPath({ path: ['tool', 'toolConfig'] });
					const newSpineWidth = parseFloat(_.get(tool, 'inputs.newSpineWidth'));
					if (!isNaN(newSpineWidth)) {
						this.updateFormField({
							formName: 'bookForm',
							fieldPath: 'properties.spineBulk',
							value: ptToUnit({ value: newSpineWidth, unit: this.preferencesUnit })
						});
					}

					if (this.revertBookComponentUncheckedState) {
						await this.revertBookComponentUncheckedState();
					}

					this.$toaster.success(this.$t('Spine successfully applied'), { timeout: 3000 });
				} else {
					this.$toaster.warning(this.$t('Spine was not changed'), { timeout: 3000 });
				}
			} catch (err) {
				this.$toaster.error(this.$t('Error during the Spine Adjust operation'), { timeout: 3000 });
			} finally {
				this.closePreviewer();
				this.isSavingNewVersion = false;
			}
		},
		onChangeSpineState() {
			this.spinesChangeHandler();
		},
		spinesChangeHandler: _.debounce(function spinesChangeHandler() {
			return this.sendSpineInstructions();
		}, 300),
		sendActivePage() {
			if (this.isReady && !isNaN(this.activePage)) {
				this.handleUserAction('changePage', this.activePage);
			}
		}
	}
};
</script>
<style lang="scss">
.Preview--header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding-bottom: 1rem;

	.Preview-title {
		margin: 0;
		font-weight: bold;
		display: none;

		@media (min-width: 660px) {
			display: block;
		}
	}

	.Preview--controls {
		display: flex;
		align-items: stretch;
		justify-content: flex-end;
		margin-left: -0.5rem;

		@media (min-width: 660px) {
			margin: 0;
		}

		a {
			padding: 0 0.5rem;
			line-height: 0;
			color: #333;
		}
	}
}

.Preview {
	display: flex;
	align-items: stretch;
	flex-direction: column-reverse;
	height: calc(100vh - 160px);
	font-size: 0.9em;

	ion-icon {
		font-size: 64px;
	}

	@media (min-width: 660px) {
		flex-direction: row;
	}

	&-spineCheckbox {
		width: 20px;
	}

	&-notAllowed {
		cursor: not-allowed;
	}

	&-spineInput,
	&-spineSelect {
		font-size: 0.9em;
		width: 60px;
	}

	&-spineSelect {
		background-color: #e1f5fe !important;
	}

	&-spineInput {
		background-color: #fff !important;
	}

	&-circle {
		width: 12px;
		height: 12px;
	}

	&-dimensions {
		border: 1px solid #bdbdbd;
		border-radius: 2px;
	}

	&-adjustMethod {
		select {
			border: 1px solid #bdbdbd;
			border-radius: 2px;
		}
	}

	&-spineSubmit {
		&Line {
			border-bottom: 1px solid rgba(0, 0, 0, 0.1);
		}
	}

	&-dialog {
		.modal-dialog {
			margin: 0 20px;

			@media (min-width: 576px) {
				margin: 0 50px;
			}
		}
	}

	.Preview--sidebar {
		overflow-y: auto;
		overflow-x: hidden;
		min-width: 210px;
		display: flex;

		@media (min-width: 660px) {
			flex-direction: column;
		}
	}

	&-control {
		margin: 1rem 1rem 0 0;

		h6 {
			font-weight: bold;
		}

		@media (min-width: 660px) {
			margin: 0 0 1rem;
		}
	}

	.Preview--main {
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		align-items: stretch;
	}

	.Preview--frame {
		flex-grow: 1;
	}
}
</style>
