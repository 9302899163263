<template>
	<div class="OrderPostbacks">
		<template v-if="displayedPostbacks.length">
			<b-table :fields="fields" :items="displayedPostbacks" small hover @row-clicked="expandPostback">
				<template v-slot:cell(event)="row">
					<span class="d-flex flex-column align-items-start">
						<p class="mb-0" v-text="row.item.event"></p>
						<small v-text="row.item.method" />
					</span>
				</template>
				<template v-slot:cell(status)="data">
					<ofs-badge :status="data.item.status" :text="getBadgeStatusText(data.item.status)" />
				</template>
				<template v-slot:cell(date)="data">
					{{ formatDate(data.item.timestamp) }}
				</template>
				<template slot="row-details" slot-scope="row">
					<b-table :fields="expandedFields" :items="[row.item]" stacked class="OrderPostbacks-details">
						<template v-slot:cell(payload)="{}">
							<b-button
								class="p-0"
								variant="link"
								@click="viewCode(row.item)"
								v-text="$t('View Payload')"
							/>
						</template>
						<template v-slot:cell(result)="data">
							{{ data.item.result || '-' }}
						</template>
					</b-table>
				</template>
			</b-table>
			<b-button
				v-if="postbackCount > defaultPostbacksCount"
				class="OrderPostbacks-button"
				@click="isShowingAll = !isShowingAll"
			>
				{{ isShowingAll ? $t('Show Less') : $t('Show All') }}
			</b-button>
		</template>
		<p v-else class="mb-0" v-text="$t('There are no postbacks for this order')" />
		<code-viewer
			:code="code"
			:title="$t('View Payload')"
			:content-type="contentType"
			:show="isCodeModalVisible"
			:on-close="closeJsonModal"
		/>
	</div>
</template>

<script>
import { OfsBadge } from '@workflow-solutions/ofs-vue-layout';
import { mapGetters, mapActions } from 'vuex';
import _ from 'lodash';
import moment from 'moment';
import { getBadgeStatusText } from '../lib/helpers';
import CodeViewer from './siteflow/Modal/CodeViewer';

export default {
	name: 'OrderPostbacks',
	components: {
		OfsBadge,
		CodeViewer
	},
	props: {
		order: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			isShowingAll: false,
			defaultPostbacksCount: 3,
			isCodeModalVisible: false,
			code: null,
			contentType: null,
			fields: [
				{
					key: 'event',
					label: this.$t('Event')
				},
				{
					key: 'date',
					label: this.$t('Date')
				},
				{
					key: 'status',
					label: this.$t('Status')
				}
			],
			expandedFields: [
				{
					key: 'payload',
					label: this.$t('Payload')
				},
				{
					key: 'result',
					label: this.$t('Result')
				},
				{
					key: 'code',
					label: this.$t('Code')
				}
			]
		};
	},
	computed: {
		...mapGetters({
			postbacks: 'order/postbacks'
		}),
		processedPostbacks() {
			const postbacks = [];
			_.forEach(this.postbacks, postback => {
				_.forEach(postback.results, (result, index) => {
					postbacks.push({
						_id: `${postback._id}${index}`,
						contentType: _.get(postback, 'additionalInfo.headers.Content-Type'),
						event: postback.additionalInfo.category,
						method: postback.type,
						timestamp: result.date,
						code: result.statusCode,
						status: result.statusCode < 300 ? 'success' : 'error',
						result: result.response,
						payload: postback.payload
					});
				});
			});

			return postbacks;
		},
		displayedPostbacks() {
			if (!this.isShowingAll) {
				return _.take(this.processedPostbacks, this.defaultPostbacksCount);
			}

			return this.processedPostbacks;
		},
		postbackCount() {
			return _.size(this.processedPostbacks);
		}
	},
	mounted() {
		this.getOrderPostbacks(this.order._id);
	},
	methods: {
		getBadgeStatusText,
		...mapActions({
			getOrderPostbacks: 'order/getOrderPostbacks'
		}),
		handleEventClick(id) {
			this.$set(this.rowDetails, id, !this.rowDetails[id]);
		},
		formatDate(time) {
			return moment(time).accountFormat(' LT');
		},
		expandPostback(row) {
			this.$set(row, '_showDetails', !row._showDetails);
		},
		viewCode(item) {
			this.contentType = item.contentType;
			this.code = JSON.stringify(item.payload, null, '\t');
			this.isCodeModalVisible = true;
		},
		closeJsonModal() {
			this.json = null;
			this.contentType = null;
			this.isCodeModalVisible = false;
		}
	}
};
</script>

<style lang="scss">
@import '~@workflow-solutions/ofs-vue-layout/dist/style/variables';

.OrderPostbacks {
	margin-top: 20px;
	display: flex;
	flex-direction: column;
	align-items: center;

	&-button {
		margin-top: 20px;
	}

	&-details {
		background: rgba($of-color-highlights, 0.3);
		word-break: break-word;
	}
}
</style>
