<template>
	<div>
		<span :class="{ CurrencyValue_voided: !billable}">{{ formattedValue }}</span>
		<span class="CurrencyVoided" v-if="!billable">{{ $t('VOIDED') }}</span>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { currency } from '../lib/filters';

export default {
	props: {
		value: {
			type: [String, Number]
		},
		language: {
			type: String
		},
		currency: {
			type: String,
			required: true
		},
		digits: {
			type: Number,
			default: 2
		},
		billable: {
			type: Boolean,
			default: true
		}
	},
	computed: {
		...mapGetters({ storeLanguage: 'lang/lang' }),
		formattedValue() {
			if (typeof this.value === 'undefined' || this.value === null) return '-';

			const language = this.language || this.storeLanguage;

			return currency(this.value, this.currency, language, {
				maximumFractionDigits: this.digits,
				minimumFractionDigits: this.digits
			});
		}
	}
};
</script>


<style lang="scss">
@import '~@workflow-solutions/ofs-vue-layout/dist/style/variables';

.CurrencyValue {
	&_voided {
		text-decoration: line-through;
	}
}

.CurrencyVoided {
	padding-left: 5px;
	color: $of-color-red;
	font-weight: bold;
}
</style>
