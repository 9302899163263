<template>
	<b-modal v-model="isVisible" hide-footer :title="$t('New Specification')" @shown="onModalShown">
		<b-breadcrumb class="ProductSelectorBreadcrumb">
			<b-breadcrumb-item
				v-for="(item, i) in selected"
				:key="i"
				:active="i === selected.length - 1"
				@click="selectCategory(i)"
			>
				{{ item.heading }}
			</b-breadcrumb-item>
		</b-breadcrumb>
		<div v-if="currentSelection" class="ProductSelector">
			<h1 class="ProductSelector__heading">{{ currentSelection.heading }}</h1>
			<h2 class="ProductSelector__description">{{ currentSelection.description }}</h2>
			<ul class="ProductSelectorList">
				<li
					v-for="(item, i) in currentSelection.items"
					:key="`${i}-${item.title}`"
					class="ProductSelectorList__item"
					@click="selectItem(item)"
				>
					<img :src="`./img/products/${item.icon}`" class="ProductSelectorList__icon" />
					{{ item.title }}
				</li>
			</ul>
			<div class="ProductSelector__testProductContainer">
				<div class="ProductSelector__testSeparator">{{ $t('or') }}</div>
				<div class="ProductSelector__testDescription">
					<a href="#" @click.prevent="selectItem(testProduct)">{{ $t('Create a Test Product') }}</a>
				</div>
			</div>
		</div>
	</b-modal>
</template>

<script>
export default {
	props: {
		show: {
			type: Boolean,
			required: true
		},
		onSelected: {
			type: Function,
			required: true
		},
		onClose: {
			type: Function,
			default: () => {}
		}
	},
	data() {
		return {
			isLoading: false,
			products: [
				{
					type: 'category',
					title: this.$t('Product Range'),
					heading: this.$t('Product Range'),
					description: this.$t('What range of products would you like to select from?'),
					items: [
						{
							type: 'category',
							title: this.$t('Publishing'),
							heading: this.$t('Product Type'),
							description: this.$t('What type of Book would you like to create?'),
							icon: 'book.svg',
							items: [
								{
									type: 'product',
									title: this.$t('Perfect Bound Trade/Edu'),
									icon: 'perfect-bound.svg',
									hpSpecTemplate: 'Perfect Bound books'
								},
								{
									type: 'product',
									title: this.$t('Perfect Bound Graphics'),
									icon: 'perfect-bound.svg',
									hpSpecTemplate: 'Perfect Bound Graphic books'
								}
							]
						},
						{
							type: 'category',
							title: this.$t('Photo'),
							heading: this.$t('Product Type'),
							description: this.$t('What type of Product would you like to create?'),
							icon: 'photo-book.svg',
							items: [
								{
									type: 'product',
									title: this.$t('Perfect Bound Book'),
									icon: 'perfect-bound.svg',
									hpSpecTemplate: 'Perfect bound Photo books'
								}
							]
						}
					]
				}
			],
			testProduct: {
				type: 'test-product',
				title: this.$t('Test Product'),
				hpSpecTemplate: 'Perfect Bound Test Books'
			},
			selected: []
		};
	},
	computed: {
		isVisible: {
			get() {
				return this.show;
			},
			set(show) {
				if (!show) {
					this.onClose();
				}
			}
		},
		currentSelection() {
			return this.selected[this.selected.length - 1];
		}
	},
	methods: {
		onModalShown() {
			// reset values
			this.selected = [...this.products];
		},
		selectItem(item) {
			const productTypes = ['product', 'test-product'];
			if (productTypes.includes(item.type)) {
				this.onSelected(item.hpSpecTemplate);
			} else {
				this.selected.push(item);
			}
		},
		selectCategory(index) {
			// When a breadcrumb is selected, this jumps the user to
			// the correct section in the products tree
			this.selected = [...this.selected.slice(0, index + 1)];
		}
	}
};
</script>

<style lang="scss">
@import '~@workflow-solutions/ofs-vue-layout/dist/style/variables';
@import '~@workflow-solutions/ofs-vue-layout/dist/style/mixins';

.ProductSelector {
	display: flex;
	align-items: center;
	flex-direction: column;
	padding: 20px 0;
	position: relative;

	&__heading {
		@include ofTextStyleSubheading();
	}

	&__description {
		@include ofTextStyleBody();
	}

	&__testProductContainer {
		text-align: center;
	}

	&__testSeparator {
		color: #8a9bb4;
		margin: 10px 0;
	}

	&__testDescription {
		@include ofTextStyleBody();
	}
}

.ProductSelectorBreadcrumb {
	margin: 0;
	padding: 0;
}

.ProductSelectorList {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	list-style: none;
	margin: 10px 0 0;
	padding: 0;

	&__item {
		margin: 5px;
		padding: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		text-align: center;
		width: 150px;
		height: 150px;
		border: 1px solid $of-color-grey-3;
		border-radius: $of-border-radius;
		cursor: pointer;

		&:hover {
			background: $of-color-highlights;
			border-color: $of-color-grey-2;
		}
	}

	&__icon {
		width: 50px;
		height: 50px;
		margin-bottom: 10px;
	}
}
</style>
