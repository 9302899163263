<template>
	<section>
		<ul class="Versions m-0 my-3 p-0 py-3 d-flex flex-column-reverse">
			<li v-for="(version, index) in item.file.versions" :key="`Version-${version.id}`" class="Versions-item">
				<b-row class="d-flex align-items-center">
					<section class="Versions-thumbnail d-flex align-items-center my-1 mx-2">
						<Thumbnails :item="item" :version="version" increase="true" preview="small" />
					</section>
					<section class="Versions-info">
						<b-row class="m-0 d-flex justify-content-between align-items-center">
							<div class="mr-3">
								<b-row class="Versions-itemFont Versions-version m-0 d-flex align-items-center">
									<ofs-badge
										v-if="isCurrentVersion(version.id)"
										status="dataready"
										:text="$t('Version') + ' ' + (index + 1)"
									/>
									<ofs-badge v-else :text="$t('Version') + ' ' + (index + 1)" />
								</b-row>
								<b-row class="m-0">
									<time
										v-b-tooltip.hover
										class="Versions-time Details-key"
										:datetime="version.createdAt"
										:title="version.createdAt | niceDate"
									>
										{{ version.createdAt | fromDate }}
									</time>
								</b-row>
							</div>
							<div
								v-if="!isLocked"
								class="Versions-itemFont d-flex text-uppercase justify-content-between px-0"
							>
								<b-btn
									v-if="!isCurrentVersion(version.id)"
									size="sm"
									class="mr-1"
									data-test-id="restore-version"
									:title="$t('Restore to this Version')"
									@click="onClickVersionRestore(version)"
								>
									<icon name="history" />
								</b-btn>
								<b-btn size="sm" :title="$t('Download')" @click="onClickVersionDownload(version.id)">
									<icon name="cloud-download-alt" />
								</b-btn>
							</div>
						</b-row>
					</section>
				</b-row>
			</li>
		</ul>
		<RestoreModal
			v-if="isRestoreModalVisible"
			:item="item"
			:version="restoreVersion"
			:show-modal="isRestoreModalVisible"
			:on-close="onClose"
			:on-complete="restoreFileVersion"
		/>
	</section>
</template>
<script>
import { mapActions } from 'vuex';
import { OfsBadge } from '@workflow-solutions/ofs-vue-layout';
import RestoreModal from './modals/RestoreModal';
import { plural } from '../../lib/filters';
import Thumbnails from './Thumbnails';
import { dateFormatMixin } from 'src/mixins/dateFormatMixin';

export default {
	name: 'FileDetailHistory',
	components: {
		RestoreModal,
		Thumbnails,
		OfsBadge
	},
	mixins: [dateFormatMixin()],
	filters: {
		plural
	},
	props: {
		item: {
			type: Object,
			required: true
		},
		onDownload: {
			type: Function,
			required: true
		},
		onComplete: {
			type: Function,
			required: true
		},
		isLocked: Boolean
	},
	data() {
		return {
			restoreVersion: {},
			isRestoreModalVisible: false
		};
	},
	methods: {
		...mapActions({
			restoreFile: 'file/restoreFile'
		}),
		async restoreFileVersion() {
			this.$set(this.restoreVersion, 'restoring', true);

			await this.restoreFile({ id: this.item.file._id, versionId: this.restoreVersion._id });

			this.onComplete();

			this.$set(this.restoreVersion, 'restoring', false);
			this.restoreVersion = null;
		},
		onClickVersionDownload(versionId) {
			this.onDownload(versionId);
		},
		onClickVersionRestore(version) {
			this.restoreVersion = version;
			this.isRestoreModalVisible = true;
		},
		onClose() {
			this.isRestoreModalVisible = false;
			this.restoreVersion = {};
		},
		isCurrentVersion(versionId) {
			return this.item.file.currentVersionId === versionId;
		}
	}
};
</script>
<style lang="scss">
.Versions {
	list-style: none;
	border-top: 1px solid rgba(0, 0, 0, 0.1);
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	overflow: auto;
	overflow-x: hidden;
	max-height: 40vh;

	&-item {
		padding: 10px 0;
		border-bottom: 1px solid #eeeeee;

		&:first-child {
			border: none;
		}
	}

	&-itemFont {
		font-size: 0.9em;
	}

	&-thumbnail {
		width: 35px;
		height: 35px;
	}

	&-info {
		width: calc(100% - 3rem - 35px);
	}

	&-time {
		cursor: pointer;
		margin-top: 5px;
	}
}
</style>
