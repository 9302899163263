<template>
	<DefaultLayout>
		<ofs-panel>
			<section v-if="!isLoaded" class="d-flex justify-content-center align-items-center w-100 h-100">
				<Loader />
			</section>
			<section v-else>
				<ContentHeader no-padding :title="item.file.name">
					<div class="d-flex">
						<b-dropdown v-if="!isLocked" class="mr-2" :text="$t('Actions')" data-test-id="actionDropdown">
							<input
								v-show="false"
								ref="fileUpload"
								type="file"
								accept="application/pdf"
								@change="onChangeUploadInput"
							/>
							<b-dropdown-item-button @click.prevent="$refs.fileUpload.click()">
								<div class="d-flex align-items-center">
									<icon class="mr-2" name="cloud-upload-alt" />
									<div v-t="'Upload New Version'" />
								</div>
							</b-dropdown-item-button>
							<b-dropdown-item-button @click.prevent="onClickDuplicateFile">
								<div class="d-flex align-items-center">
									<icon class="mr-2" name="clone" />
									<div v-t="'Duplicate'" />
								</div>
							</b-dropdown-item-button>
							<b-dropdown-item-button @click.prevent="onClickMoveFile">
								<div class="d-flex align-items-center">
									<icon class="mr-2" name="arrow-circle-right" />
									<div>{{ $t('Move') }}</div>
								</div>
							</b-dropdown-item-button>
							<b-dropdown-divider />
							<b-dropdown-item-button @click.prevent="onClickRemoveFile">
								<div class="d-flex align-items-center">
									<icon class="mr-2" name="trash-alt" />
									<div>{{ $t('Delete') }}</div>
								</div>
							</b-dropdown-item-button>
						</b-dropdown>

						<b-button
							v-if="!isNewVersionUploaded"
							v-t="'Download'"
							data-test-id="download"
							class="mr-2"
							variant="primary"
							@click="onClickDownloadFile"
						/>
					</div>
				</ContentHeader>
				<template>
					<b-row v-if="item.file" class="d-flex">
						<b-col cols="12" lg="8" xl="9">
							<b-row class="FileDetail-mainContent bg-white mr-1">
								<b-col
									cols="12"
									sm="3"
									class="py-3 my-2 d-flex justify-content-center align-items-center text-center"
								>
									<Thumbnails
										v-if="!isNewVersionUploaded"
										class="Thumbnails-Artwork h-100"
										:item="item"
										increase="true"
										preview="big"
									/>
									<Loader v-else />
								</b-col>
								<b-col cols="12" sm="9" class="d-flex flex-column justify-content-between py-3 my-2">
									<b-row class="Details-header w-100 border-bottom-3 m-0">
										<b-col>
											<h2 class="my-1">{{ $t('File Details') }}</h2>
										</b-col>
									</b-row>
									<b-row class="w-100 m-0 pt-3 mt-2">
										<b-col cols="6" sm="12" md="6" class="m-0 p-0 d-flex flex-column">
											<div class="Details-item">
												<span v-t="'Filename'" class="Details-key" />
												<span v-text="item.file.name" />
											</div>
											<div v-if="isPdf && metadata" class="Details-item">
												<span v-t="'Pages'" class="Details-key" />
												<span v-text="metadata.pageCount" />
											</div>
											<div v-if="isPdf && mediaDimensions" class="Details-item">
												<span v-t="'Media Size'" class="Details-key" />
												<span v-text="mediaDimensions" />
											</div>
											<div v-if="isPdf && trimDimensions" class="Details-item ">
												<span v-t="'Trim Size'" class="Details-key" />
												<span v-text="trimDimensions" />
											</div>
										</b-col>
										<b-col cols="6" sm="12" md="6" class="m-0 p-0 d-flex flex-column">
											<div v-if="isPdf && metadata" class="Details-item">
												<span v-t="'Status'" class="Details-key" />
												<span>
													<ofs-badge
														:status="getBadgeStatusVariant(item.file.status)"
														:text="getBadgeStatusText(item.file.status)"
														data-test-id="fileDetailsStatus"
													/>
												</span>
											</div>
											<div v-if="version" class="Details-item">
												<span v-t="'Version'" class="Details-key" />
												<span>
													<ofs-badge
														status="dataready"
														data-test-id="fileDetailsCurrentVersion"
														:text="$t('Version') + ' ' + version"
													/>
												</span>
											</div>
											<div class="Details-item">
												<span v-t="'File Size'" class="Details-key" />
												<span>{{ item.file.currentVersion.size | fileSize }}</span>
											</div>
											<div class="Details-item">
												<span v-t="'Last Modified'" class="Details-key" />
												<time :datetime="item.file.updatedAt">
													{{ item.file.updatedAt | fromDate }}
												</time>
											</div>
										</b-col>
									</b-row>
									<b-row class="w-100 m-0 mt-3">
										<b-col cols="12" class="m-0 p-0">
											<Tags v-if="!isLocked" :refresh="fetchData" :path="item" />
										</b-col>
									</b-row>
								</b-col>
							</b-row>

							<RemovePathsModal
								v-if="modals.isRemovePathsModalVisible"
								:show-modal="modals.isRemovePathsModalVisible"
								:refresh="afterMoveFile"
								:on-close="onModalClose"
								:paths="[item.file]"
							/>

							<DuplicatePathsModal
								v-if="modals.isDuplicatePathsModalVisible"
								:show-modal="modals.isDuplicatePathsModalVisible"
								:on-close="onModalClose"
								:path="item"
							/>

							<MovePathsModal
								v-if="modals.isMovePathsModalVisible"
								:show-modal="modals.isMovePathsModalVisible"
								:on-close="onModalClose"
								:refresh="afterMoveFile"
								:paths="[item]"
							/>
						</b-col>
						<b-col cols="12" lg="4" xl="3">
							<b-row class="FileDetail-mainContent FileDetail-aside py-2 px-2 px-xl-3 bg-white">
								<b-tabs class="py-3 w-100" lazy fill>
									<b-tab :title="$t('History')" class="pt-2">
										<h4 v-t="'Versions'" class="FileDetail-tabsHeaders text-uppercase m-0 mt-3" />
										<FileDetailHistory
											:item="item"
											:on-download="fetchDownloadUrl"
											:on-complete="refreshFile"
											:is-locked="isLocked"
										/>
										<h4
											v-t="'Activity'"
											class="FileDetail-tabsHeaders text-uppercase m-0 mb-3 pt-3"
										/>
										<PathActivity :path="item" />
									</b-tab>
									<b-tab :title="$t('Comments')">
										<FileDetailNotes class="p-3" :item="item" :show="true" />
									</b-tab>
								</b-tabs>
							</b-row>
						</b-col>
					</b-row>
				</template>
			</section>
		</ofs-panel>
	</DefaultLayout>
</template>

<script>
import Vue from 'vue';
import Promise from 'bluebird';
import { mapActions, mapGetters } from 'vuex';
import { ContentHeader, OfsPanel, OfsBadge } from '@workflow-solutions/ofs-vue-layout';
import get from 'lodash/get';
import delay from 'lodash/delay';
import first from 'lodash/first';
import mapValues from 'lodash/mapValues';
import findIndex from 'lodash/findIndex';
import uniqBy from 'lodash/uniqBy';
import map from 'lodash/map';
import { fileSize, shorten, replace } from '../../lib/filters';
import {
	pageDimensions,
	getRootPath,
	defaultBookComponentUnchecked,
	getBadgeStatusText,
	getBadgeStatusVariant
} from '../../lib/helpers';
import fileActionMessages from '../../lib/fileActionMessages';
import DefaultLayout from '../../components/DefaultLayout';
import PathActivity from '../../components/PathActivity';
import FileDetailNotes from './FileDetailNotes';
import FileDetailHistory from './FileDetailHistory';
import RemovePathsModal from './modals/RemovePathsModal';
import DuplicatePathsModal from './modals/DuplicatePathsModal';
import MovePathsModal from './modals/MovePathsModal';
import Tags from './Tags';
import Loader from '../../components/Loader';
import Thumbnails from './Thumbnails';
import analytics from '../../lib/analytics';
import { dateFormatMixin } from 'src/mixins/dateFormatMixin';

Promise.config({
	// Enable cancellation
	cancellation: true
});

const STATUSES = {
	'In Piazza': 'live',
	checked: 'ready',
	unchecked: 'error',
	warning: 'pending'
};

export default {
	name: 'FileDetail',
	components: {
		ContentHeader,
		DefaultLayout,
		Loader,
		FileDetailNotes,
		FileDetailHistory,
		RemovePathsModal,
		DuplicatePathsModal,
		MovePathsModal,
		Tags,
		Thumbnails,
		PathActivity,
		OfsPanel,
		OfsBadge
	},
	filters: {
		fileSize,
		shorten,
		replace
	},
	mixins: [dateFormatMixin()],
	data() {
		return {
			uploadNewVersionPromise: null,
			pollinStatuses: ['checking', 'unchecked'],
			isNewVersionUploaded: false,
			isLoaded: false,
			modals: {
				isRemovePathsModalVisible: false,
				isDuplicatePathsModalVisible: false,
				isMovePathsModalVisible: false
			},
			fileActions: {
				total: 0,
				skip: 0,
				limit: 20,
				data: []
			},
			refreshInterval: null,
			toggle: true
		};
	},
	computed: {
		...mapGetters({
			books: 'book/books',
			tree: 'file/tree',
			itemNotes: 'file/itemNotes'
		}),
		item() {
			return get(this.tree, 'data', {});
		},
		filePath() {
			return get(this.item, 'path', '');
		},
		isPdf() {
			return this.item.file.contentType === 'application/pdf';
		},
		metadata() {
			return get(this.item, 'file.currentVersion.metadata');
		},
		thumbnailPath() {
			return get(this.item, 'file.currentVersion.thumbnailPath');
		},
		thumbnailUrl() {
			return get(this.item, 'file.currentVersion.thumbnailUrl');
		},
		fileId() {
			return get(this.item, 'file._id');
		},
		mediaDimensions() {
			if (this.metadata) {
				return pageDimensions(this.metadata.pageConfigs[0]);
			}
			return null;
		},
		trimDimensions() {
			if (this.metadata) {
				return pageDimensions(this.metadata.pageConfigs[0], 'trim');
			}
			return null;
		},
		isLocked() {
			return this.isNewVersionUploaded || get(this.item, 'file.status') !== 'checked';
		},
		properties() {
			const properties = get(this.item, 'file.properties', {});
			return map(properties, (value, key) => ({ key, value }));
		},
		version() {
			const currentVersionId = get(this.item, 'file.currentVersion._id');
			const versions = get(this.item, 'file.versions', []);
			return versions.findIndex(version => version._id === currentVersionId) + 1;
		},
		getStatus() {
			const { status } = this.item.file || {};
			return STATUSES[status] ? STATUSES[status] : STATUSES.warning;
		}
	},
	async mounted() {
		await this.fetchData();
		this.fetchBooks();
	},
	beforeDestroy() {
		this.clearData();
	},
	beforeRouteLeave(to, from, next) {
		this.clearData();
		next();
	},
	methods: {
		...mapActions({
			findBooks: 'book/find',
			updateBook: 'book/update',
			getTree: 'file/getFoldersTreeByName',
			getFileUploadUrl: 'file/getUploadUrl',
			createFileVersion: 'file/createFileVersion',
			getFileActions: 'file/getFileActions',
			fileDownloadUrl: 'file/fileDownloadUrl'
		}),
		getBadgeStatusText,
		getBadgeStatusVariant,
		async fetchBooks() {
			const query = {
				query: {
					// Fetch books with current fileId
					'components.fileId': this.fileId,
					$populate: ['components.component'],
					$select: ['_id']
				}
			};

			await this.findBooks({ query });
		},
		clearData() {
			if (this.uploadNewVersionPromise) {
				this.uploadNewVersionPromise.cancel();
			}

			clearInterval(this.refreshInterval);
		},
		async fetchData() {
			try {
				this.isLoaded = false;
				const directoryName = get(this.$route, 'params.path');
				await this.getTree({ directoryName, limit: 1, skip: 0 });
				await this.fetchFileActions();
			} catch (err) {
				this.$toaster.error('No file found', { timeout: 2000 });
				this.$router.push({ name: this.$route.name });
			} finally {
				this.isLoaded = true;
			}
		},
		updateBooksComponents() {
			return Promise.map(this.books || [], async book => {
				await this.$nextTick();
				let isCoverComponent;
				const components = book.components.map(component => {
					if (component.fileId === this.fileId) {
						const componentCode = get(component, 'component.code');
						const componentCodeRegex = /cover/i;

						if (componentCodeRegex.test(componentCode)) {
							isCoverComponent = true;
						}

						return { ...component, ...defaultBookComponentUnchecked };
					}
					return component;
				});

				if (isCoverComponent) {
					return this.updateBook({
						id: book._id,
						data: { thumbnail: this.thumbnailUrl, thumbnailS3Path: this.thumbnailPath, components }
					});
				}

				return this.updateBook({ id: book._id, data: { components } });
			});
		},
		async getNewFileVersion() {
			if (this.uploadNewVersionPromise.isCancelled()) {
				return null;
			}

			await this.getTree({ directoryName: get(this.$route, 'params.path'), limit: 1, skip: 0 });
			const fileStatus = get(this.item, 'file.status');

			if (fileStatus === 'checking') {
				await new Promise(resolve => {
					delay(async () => {
						await this.getNewFileVersion();
						resolve(fileStatus);
					}, 2000);
				});
			}

			return fileStatus;
		},
		async fetchFileActions(skip = 0, limit = 10) {
			const fileActions = await this.getFileActions({ id: this.item.file._id, skip, limit });

			// Merge new results with current (pagination)
			const data = [...this.fileActions.data, ...fileActions.data];

			// Remove duplicates
			this.fileActions = { ...fileActions, data: uniqBy(data, '_id') };

			// Add in friendly messages
			this.fileActions.data = this.fileActions.data.map(action => {
				action.message = this.getFileActionMessage(action);
				return action;
			});
		},
		getFileActionMessage(action) {
			let versionNumber = null;

			if (action.additionalData && action.additionalData.versionId) {
				versionNumber = this.getVersionNumber(action.additionalData.versionId);
			}

			const message = fileActionMessages(action, versionNumber);
			return message;
		},
		getVersionNumber(versionId) {
			return findIndex(this.item.file.versions, { _id: versionId }) + 1;
		},
		refreshFile() {
			this.fetchData();
			this.refreshActions();
			this.refreshInterval = setInterval(() => {
				if (!this.pollinStatuses.includes(this.item.file.status)) {
					clearInterval(this.refreshInterval);
				} else {
					this.fetchData();
					this.refreshActions();
				}
			}, 2000);
		},
		onClickRemoveFile() {
			this.modals.isRemovePathsModalVisible = true;
		},
		onClickDuplicateFile() {
			this.modals.isDuplicatePathsModalVisible = true;
		},
		onClickMoveFile() {
			this.modals.isMovePathsModalVisible = true;
		},
		onModalClose() {
			this.modals = mapValues(this.modals, () => false);
		},
		onClickDownloadFile() {
			this.fetchDownloadUrl();
		},
		async onChangeUploadInput(event) {
			const files = Array.from(event.target.files);
			const file = first(files);
			this.uploadNewVersionPromise = this.handleUploadNewVersion(file);
		},
		handleUploadNewVersion(file) {
			return new Promise(async (resolve, reject) => {
				try {
					this.isNewVersionUploaded = true;
					const { url, s3Path } = await this.getFileUploadUrl(file.type);
					const data = {
						version: {
							live: true,
							s3Path,
							size: file.size,
							note: 'FILE_DETAILS_UPLOAD_NEW_VERSION'
						}
					};
					await this.uploadFile(url, file);
					const fileId = get(this.item, 'file._id');

					if (!this.uploadNewVersionPromise.isCancelled()) {
						await this.createFileVersion({ id: fileId, data });
					}

					if (!this.uploadNewVersionPromise.isCancelled()) {
						await this.getNewFileVersion();
						await this.updateBooksComponents();
						this.$toaster.success(`${this.$t('Uploaded')}: ${file.name}`, { timeout: 3000 });
						resolve();
					}
				} catch (err) {
					this.$toaster.error(`${this.$t('Upload failed')}: ${file.name}`, { timeout: 3000 });
					reject(err);
				} finally {
					this.isNewVersionUploaded = false;
				}
			});
		},
		uploadFile(url, file) {
			this.uploadRequest = Vue.http.put(url, file, { headers: { 'Content-Type': file.type } });
			analytics.trackEventNewVersionUpload();

			return this.uploadRequest;
		},
		async fetchDownloadUrl(versionId = null) {
			const { url } = await this.fileDownloadUrl({ id: this.item.file._id, versionId });
			window.location.href = url;
		},
		refreshActions() {
			this.fileActions = {
				total: 0,
				skip: 0,
				limit: 20,
				data: []
			};
			this.fetchFileActions();
		},
		afterMoveFile({ pathString } = {}) {
			const options = { name: 'files.browse' };
			const assetsPath = getRootPath(pathString);

			if (assetsPath) {
				options.query = { path: assetsPath };
			}

			this.$router.push(options);
		}
	}
};
</script>

<style lang="scss">
@import '~@workflow-solutions/ofs-vue-layout/dist/style/mixins';
@import '~@workflow-solutions/ofs-vue-layout/dist/style/variables';
.Details {
	&-key {
		@include ofTextStyleDescriptions();
	}

	&-newVersion {
		color: #bdbdbd;
		font-size: 1.1em;
		cursor: auto;
	}

	&-addNew {
		font-size: 0.6em;
	}

	&-plus {
		font-size: 0.8em;
	}

	&-addNew,
	&-plus {
		color: #757575;
		cursor: pointer;
	}

	&-header {
		background: $of-color-highlights;
	}

	&-item {
		display: flex;
		flex-direction: column;
		margin-top: 20px;

		&:first-of-type {
			margin-top: unset;
		}
	}

	&-headerItem {
		font-size: 1.2em;
	}

	&-filename {
		font-weight: bold;
		color: rgba(0, 0, 0, 0.85);
	}

	&-status {
		@media screen and (max-width: 575px) {
			margin-top: 1rem;
			span {
				text-align: left !important;
			}
		}
	}
}

.FileDetail {
	display: flex;
	flex-direction: column;

	@media screen and (min-width: 900px) {
		flex-direction: row;
	}

	&-aside {
		@media screen and (min-width: 992px) {
			min-height: 78vh;
		}
	}

	&-main {
		padding: 1rem;
		flex: 1;

		@media screen and (min-width: 900px) {
			overflow: auto;
		}
	}

	&-mainContent {
		word-break: break-word;
	}

	&-tabsHeaders {
		font-size: 0.9em;
		font-weight: bold;
	}
}

.Tools {
	font-size: 0.9em;
	cursor: pointer;

	&-empty {
		color: #bdbdbd;
	}

	&-icon {
		color: #bdbdbd;
		width: 35px;
		height: 35px;

		&NewVersion {
			display: flex;
			justify-content: center;
			align-items: center;

			svg {
				width: 54.3%;
				height: 54.3%;
			}
		}

		@media screen and (min-width: 1201px) and (max-width: 1492px) {
			width: 25px;
			height: 25px;
		}
	}

	&-item {
		@media screen and (min-width: 1201px) and (max-width: 1492px) {
			font-size: 0.8em;
		}
	}

	&-item:hover {
		.Tools-icon {
			color: inherit;
		}
	}
}
</style>
