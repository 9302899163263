<template>
	<div class="OrderComponent">
		<aside class="OrderComponent_artwork Artwork" data-test-id="itemThumbnail" @click="viewFile()">
			<thumbnail
				v-if="!file || !file.localFile"
				class="Artwork_image"
				:src="file ? file.thumbnailUrl : ''"
				data-test-id="itemFileThumbnail"
			/>
			<div v-else class="localfile" data-test-id="itemLocalThumbnail">
				<font-awesome-icon class="localfile_icon" icon="hdd" scale="2" />
				<span class="localfile_text" v-text="$t('Local file')"></span>
			</div>
		</aside>
		<div class="OrderComponent_inner">
			<header class="OrderComponent_header">
				<h3 class="OrderComponent_title" data-test-id="itemComponentCode" v-text="component.code" />
				<span
					v-if="preflightProfile"
					v-b-tooltip
					:title="preflightProfile"
					class="mr-1"
					data-test-id="itemPreflight"
					@click="viewFile('preflight')"
				>
					<ofs-badge
						class="OrderComponent_badge-clickable"
						data-test-id="itemPreflightBadge"
						:status="preflightStatus"
						:text="$t('Preflight')"
					/>
				</span>
				<span
					v-if="fileImposition"
					v-b-tooltip
					:title="fileImposition.name"
					class="mr-1"
					data-test-id="itemFileImposition"
				>
					<ofs-badge status="live" data-test-id="itemFileImpositionBadge" :text="$t('File Imposition')" />
				</span>
				<span
					v-if="batchImposition"
					v-b-tooltip
					:title="batchImposition.name"
					class="mr-1"
					data-test-id="itemBatchImposition"
				>
					<ofs-badge status="live" data-test-id="itemBatchImpositionBadge" :text="$t('Batch Imposition')" />
				</span>
				<span v-if="currentVersion.errorList && currentVersion.errorList.length" @click="viewFile('errors')">
					<ofs-badge
						class="OrderComponent_badge-clickable"
						status="error"
						data-test-id="itemBatchErrorBadge"
						:text="$t('Error')"
					/>
				</span>
			</header>
			<b-button
				v-if="showRefetchButton"
				:disabled="fileRefetchInProgress"
				size="sm"
				class="mt-2"
				data-test-id="itemFileRefetchButton"
				@click.stop="refetchFile"
			>
				<span v-if="showRefetchText" v-text="$t('Refetch')"></span>
				<span v-else v-text="$t('Refetch in progress...')"></span>
			</b-button>
			<div v-if="hasAttributes" class="OrderComponent_attributes">
				<h3 class="OrderComponent_subheading" v-text="$t('Attributes')" />
				<AttributeList :attributes="component.attributes" />
			</div>
			<file-modal :show="isFileModalVisible" :file="file" :tab="fileModalTab" :on-close="closeFileModal" />
		</div>
	</div>
</template>

<script>
import moment from 'moment';
import _ from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import { OfsBadge } from '@workflow-solutions/ofs-vue-layout';
import { withSubscriptionMixin } from '@workflow-solutions/ofs-vuex-crud';
import AttributeList from '../../../../../components/AttributeList';
import Thumbnail from '../../../../../components/siteflow/Thumbnail';
import FileModal from '../../../../../components/siteflow/Modal/File';
import { $t } from '../../../../../vuex';
import { displayError } from '../../../../../lib/helpers';

export default {
	components: {
		AttributeList,
		OfsBadge,
		Thumbnail,
		FileModal
	},
	mixins: [withSubscriptionMixin],
	props: {
		component: {
			type: Object,
			default: () => ({})
		},
		erroredFiles: {
			type: Array,
			default: () => []
		}
	},
	data() {
		return {
			fields: ['status', 'barcode', 'batch', 'created'],
			isFileModalVisible: false,
			fileModalTab: null,
			fileRefetchInProgress: false
		};
	},
	computed: {
		...mapGetters({
			orderConfigOptions: 'order/configOptions',
			order: 'order/order',
			preflightProfiles: 'order/preflightProfiles',
			fetchedFile: 'file/file'
		}),
		hasAttributes() {
			return _.size(_.get(this.component, 'attributes'));
		},
		file() {
			return _.find(this.order.files, { _id: this.component.fileId });
		},
		error() {
			return _.find(this.erroredFiles, { _id: this.component.fileId }) || this.preflightStatus === 'error';
		},
		preflightProfile() {
			if (!this.file || !this.file.preflight) {
				return null;
			}

			if (this.file.preflightProfile === 'custom') {
				const profile = _.find(this.preflightProfiles, {
					_id: this.file.preflightProfileId
				});

				if (profile) {
					return profile.name;
				}
			}

			return this.file.preflightProfile;
		},
		jobs() {
			return _.filter(this.order.jobs, {
				fileId: this.file._id
			});
		},
		currentVersion() {
			return _.find(this.file.versions, { _id: this.file.currentVersionId }) || {};
		},
		preflightStatus() {
			if (!this.file || !this.file.preflightReport) return;

			// somettimes null caused an error in StatusBadge component (null.toLowerCase())
			let preflightStatus = '';

			let preflightReport = this.file.preflightReport;
			if (this.file.currentRevision && this.file.versions && this.file.versions.length) {
				preflightReport = this.file.versions[this.file.currentRevision - 1].preflightReport;
			}

			preflightStatus = this.calculatePreflightStatus(preflightReport);

			return preflightStatus;
		},
		fileImposition() {
			return _.find(this.orderConfigOptions?.impositions, { _id: _.get(this.file, 'fileImpositionId') });
		},
		batchImposition() {
			return _.find(this.orderConfigOptions?.impositions, { _id: _.get(this.component, 'batchImpositionId') });
		},
		showRefetchButton() {
			return (
				(this.order.order.orderData.status !== 'cancelled' &&
					this.currentVersion.errorList &&
					this.currentVersion.errorList.length) ||
				this.preflightStatus === 'error'
			);
		},
		showRefetchText() {
			return !this.fileRefetchInProgress;
		}
	},
	watch: {
		fetchedFile: async function watchFileStaus(file) {
			const { status } = file;
			let preflightStatus = null;
			const currentId = file.currentVersionId;
			const currentVer = _.find(file.versions, { _id: currentId });
			if (currentVer) {
				preflightStatus = this.calculatePreflightStatus(currentVer.preflightReport);
			}

			if (
				(status && status !== 'notreceived' && this.fileRefetchInProgress) ||
				(this.fileRefetchInProgress && preflightStatus === 'error')
			) {
				await this.stopFilePolling();
				this.fileRefetchInProgress = false;

				const restOfFiles = _.reject(this.order.files, { _id: this.component.fileId }) || [];
				const updatedFiles = [...restOfFiles, this.fetchedFile];
				this.updateOrderFiles(updatedFiles);
			}
		}
	},
	async mounted() {
		if (!this.preflightProfiles || !this.preflightProfiles.length) {
			await this.getPreflightProfiles();
		}
	},
	beforeDestroy() {
		if (this.fileRefetchInProgress) this.stopFilePolling();
	},
	methods: {
		...mapActions({
			getPreflightProfiles: 'order/getPreflightProfiles',
			refetch: 'file/refetch',
			observeFile: 'file/observeOne',
			updateOrderFiles: 'order/updateOrderFiles'
		}),
		formatDate(time) {
			return moment(time).fromNow();
		},
		viewFile(tab) {
			this.fileModalTab = tab;
			this.isFileModalVisible = true;
		},
		closeFileModal() {
			this.isFileModalVisible = false;
		},
		async refetchFile() {
			this.fileRefetchInProgress = true;
			try {
				await this.refetch({ id: this.file._id });
				await this.startFilePolling();
				const msg = $t('It may take a few minutes to process the file');
				this.$notify({ type: 'success', text: msg, title: $t('File being refetched') });
			} catch (err) {
				const message = displayError(err);
				this.$notify({ type: 'error ', text: message });
			}
		},
		async startFilePolling() {
			await this.unsubscribeAll();
			return this.addSubscription(this.observeFile({ id: this.file._id, options: { interval: 5000 } }));
		},
		async stopFilePolling() {
			return this.unsubscribeAll();
		},
		calculatePreflightStatus(preflightReport) {
			let preflightStatus = '';

			if (preflightReport && preflightReport.length) {
				for (const fixup of preflightReport) {
					const severity = fixup.severity.toLowerCase();
					if (severity === 'error') {
						preflightStatus = severity;
					} else if (severity === 'warning' && preflightStatus !== 'error') {
						preflightStatus = severity;
					} else if (severity === 'success' && !_.includes(['error', 'warning'], preflightStatus)) {
						preflightStatus = severity;
					}
				}
			}

			return preflightStatus;
		}
	}
};
</script>

<style lang="scss">
@import '~@workflow-solutions/ofs-vue-layout/dist/style/variables';
@import '~@workflow-solutions/ofs-vue-layout/dist/style/mixins';

.OrderComponent {
	margin: 0 10px;
	padding: 10px 0;
	border-top: 1px solid $of-color-highlights;
	display: flex;
	flex-direction: column;

	@media all and (min-width: 500px) {
		flex-direction: row;
	}

	&-borderless {
		border: none;
	}

	&_inner {
		flex: 1;
	}

	&_artwork {
		width: 80px;
		height: 80px;
		margin-bottom: 10px;

		@media all and (min-width: 500px) {
			margin-bottom: 0;
			margin-right: 20px;
		}
	}

	&_header {
		display: flex;
		align-items: center;
	}

	&_title {
		@include ofTextStyleHeading();
		margin: 0 10px 0 0;
	}

	&_subheading {
		@include ofTextStyleDescriptions();
		margin: 10px 0 5px;
	}

	&_badge {
		color: $of-color-white;
		font-size: 10px;
		line-height: 10px;
		font-weight: bold;
		display: inline-flex;
		background: $of-color-aqua;
		padding: 2px 5px;
		border-radius: $of-border-radius;
		margin: 2px;
		user-select: none;

		&-error {
			background: $of-color-red;
		}

		&-clickable {
			cursor: pointer;
		}
	}

	&_fileEditButton {
		position: absolute;
		cursor: pointer;
		top: 0;
		left: 60px;
		transform: translate(30%, -30%);
		width: 22px;
		height: 22px;
		align-items: center;
		justify-content: center;
		z-index: 2;
		display: none;
		background: $of-color-aqua;
		border-radius: 50%;

		&:hover {
			background: darken($of-color-aqua, 5%);
		}

		@media all and (min-width: 500px) {
			left: auto;
			right: 20px;
		}

		svg {
			width: 10px;
			height: 10px;
			color: #fff;
		}
	}

	&_artworkWrapper {
		position: relative;

		&:hover > .OrderComponent_fileEditButton {
			display: flex;
		}
	}
}

.localfile {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: desaturate(darken($of-color-highlights, 20), 60);

	&_icon {
		margin-bottom: 5px;
		min-width: 20px;
		min-height: 20px;
	}

	&_text {
		text-transform: uppercase;
		font-size: 10px;
		font-weight: 600;
	}
}

.uploadFile {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: desaturate(darken($of-color-highlights, 20), 60);

	&_icon {
		min-width: 20px;
		min-height: 20px;
	}

	&_text {
		font-size: 12px;
		color: $of-color-aqua;
	}
}

.thumbnailError {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: desaturate(darken($of-color-highlights, 20), 60);

	&_icon {
		min-width: 20px;
		min-height: 20px;
		margin-bottom: 2px;
	}

	&_text {
		font-size: 12px;
		color: $of-color-red;
		line-height: 1.2;
		text-align: center;
	}
}

.Artwork {
	background: $of-color-highlights;
	border-radius: $of-border-radius;
	padding: 5px;
	cursor: pointer;
	overflow: hidden;
	border: 1px solid $of-color-grey-3;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	&_image {
		width: 100%;
		height: 100%;
	}
	&--dashed {
		border-style: dashed;
	}
}

.OrderComponentReprint {
	background: $of-color-white;
	border-radius: $of-border-radius;
	overflow: hidden;
	padding: 1px 3px;
	border: 1px solid $of-color-grey-3;
	font-weight: bold;
	font-size: 10px;
	line-height: 10px;
	margin-left: 5px;
}
</style>
