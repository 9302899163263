<template>
	<DefaultLayout>
		<ofs-panel class="Catalogue" :is-padded="false">
			<content-header :title="$t('Edit Catalogue')" />
			<Loader v-if="isLoading" class="w-100 h-100" />
			<b-tabs v-else class="Catalogue-tabs" no-fade>
				<b-tab :title="$t('Details')" data-test-id="detailsTab">
					<b-form novalidate class="Catalogue-form" @submit.prevent="onSave">
						<b-container fluid class="Catalogue-details">
							<b-row>
								<b-col>
									<of-form-input
										:horizontal="false"
										name="sourceCatalogueId"
										type="text"
										data-test-id="catalogueViewCatalogueName"
										:placeholder="$t('Catalogue Name')"
										:label="$t('Catalogue Name')"
										required
									/>
								</b-col>
								<b-col>
									<of-form-input
										:horizontal="false"
										name="description"
										type="text"
										data-test-id="catalogueViewDescription"
										:label="$t('Description')"
										:placeholder="$t('Description')"
										required
									/>
								</b-col>
							</b-row>
							<b-row>
								<b-col>
									<of-multi-select
										:horizontal="false"
										name="tags"
										data-test-id="catalogueViewTags"
										:label="`${$t('Tags')}:`"
										label-by=""
										:options="[]"
										:searchable="false"
										:multiple="true"
										:taggable="true"
										:tag-placeholder="$t('Add this as new tag')"
										:placeholder="$t('Add a tag')"
										required
									/>
								</b-col>
							</b-row>
							<b-row>
								<b-col>
									<of-multi-select
										name="userIds"
										:label="`${$t('Users')}:`"
										:options="userOptions"
										:multiple="true"
										label-by="text"
										track-by="value"
										required
										:placeholder="$t('Select option')"
									/>
								</b-col>
							</b-row>
						</b-container>
						<nav class="Catalogue-actions">
							<b-button variant="danger" @click="onClickDelete">{{ $t('Delete') }}</b-button>
							<b-button type="submit" variant="primary">{{ $t('Save') }}</b-button>
						</nav>
					</b-form>
				</b-tab>
				<b-tab :title="$t('Titles')" data-test-id="titlesTab">
					<ListTable
						:items="titles"
						:is-busy="isBusy"
						:fields="fields"
						:total-items="count"
						:per-page="pageConfig.perPage"
						:current-page="currentPage"
						:page-position-prefix="$t('Showing')"
						:page-position-join="$t('of')"
						:fetch-data="fetchData"
						table-title=" "
						hover
						@row-clicked="viewBook"
						@table-change="handleTableChange"
					>
						<template slot="prev-text" slot-scope="{}">
							{{ $t('Prev') }}
						</template>
						<template slot="next-text" slot-scope="{}">
							{{ $t('Next') }}
						</template>
						<template slot="empty">
							<span><i>{{ $t('No Data') }}</i></span>
						</template>
						<template #cell(status)="data">
							<ofs-badge :status="data.item.status" :text="getBookBadgeStatusText(data.item.status)" />
						</template>
						<template #cell(thumbnail)="data">
							<Artwork :url="data.item.thumbnail" placeholder-icon="book" />
						</template>
						<template #cell(unitPrice)="data">
							<span>{{ data.item.unitPrice | formatCurrency(accountCurrency, accountLang) }}</span>
						</template>
						<template #cell(specification.description)="data">
							<span v-if="data.item.specification">{{ data.item.specification.sourceSpecId }}</span>
							<span v-else>-</span>
						</template>
						<template #cell(status)="data">
							<ofs-badge :status="data.item.status" :text="getBookBadgeStatusText(data.item.status)" />
						</template>
					</ListTable>
				</b-tab>
			</b-tabs>
		</ofs-panel>
		<confirm-modal v-if="!!confirmModal" :options="confirmModal" :cancel="cancelConfirmModal"> </confirm-modal>
	</DefaultLayout>
</template>
<script>
import _ from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import {
	ContentHeader,
	OfsBadge,
	OfFormInput,
	ListTable,
	OfMultiSelect,
	withForm,
	OfsPanel
} from '@workflow-solutions/ofs-vue-layout';
import { currency } from '../../lib/filters';
import { getBadgeStatusVariant, getBookBadgeStatusText } from '../../lib/helpers';
import DefaultLayout from '../../components/DefaultLayout';
import Artwork from '../../components/Artwork';
import Loader from '../../components/Loader';
import ConfirmModal from '../../components/modals/ConfirmModal';

export default {
	components: {
		Artwork,
		ContentHeader,
		Loader,
		OfsBadge,
		OfsPanel,
		ListTable,
		DefaultLayout,
		OfFormInput,
		OfMultiSelect,
		ConfirmModal
	},
	filters: {
		formatCurrency: currency
	},
	mixins: [withForm('catalogueForm')],
	data() {
		return {
			isLoading: true,
			isBusy: false,
			currentPage: 1,
			memberships: [],
			confirmModal: null,
			sort: {},
			fields: [
				{ label: this.$t('Cover'), key: 'thumbnail' },
				{ label: this.$t('Title'), key: 'properties.title', sortable: true },
				{ label: this.$t('Author'), key: 'properties.author', sortable: true },
				{ label: this.$t('ISBN'), key: 'properties.isbn', sortable: true },
				{ label: this.$t('Price'), key: 'unitPrice' },
				{ label: this.$t('Spec Code'), key: 'specification.sourceSpecId' },
				{ label: this.$t('Status'), key: 'status' }
			]
		};
	},
	async created() {
		this.clearCatalogue();
		this.clearTitles();
		this.fetchMemberships();

		this.id = this.$route.params.id;

		if (this.id) {
			try {
				// Get the book
				await this.getCatalogue({ id: this.id });
				await this.fetchData();
			} catch (e) {
				// No book, jump to listings
				this.$toaster.info(this.$t('Could not load Catalogue'), { timeout: 2000 });
				this.$router.push({ name: 'catalogues' });
			} finally {
				this.isLoading = false;
				this.isBusy = false;
			}
		}

		this.setForm();
	},
	computed: {
		...mapGetters({
			getPageConfig: 'ui/getPageConfig',
			catalogue: 'catalogue/catalogue',
			titles: 'book/books',
			count: 'book/count',
			vars: 'account/vars',
			lang: 'lang/lang'
		}),
		pageConfig() {
			return (
				this.getPageConfig(this.$route.name) || {
					perPage: 10
				}
			);
		},
		isVisible: {
			get() {
				return this.show;
			},
			set(show) {
				if (!show) {
					this.onClose();
				}
			}
		},
		accountCurrency() {
			return this.vars?.oneflowAccountSettings?.currency ?? 'GBP';
		},
		accountLang() {
			return this.lang;
		},
		users() {
			return (this.memberships || []).map(membership => membership.user);
		},
		userOptions() {
			return this.users.map(user => ({
				text: user.email,
				value: user._id
			}));
		}
	},
	methods: {
		...mapActions({
			setPageConfig: 'ui/setPageConfig',
			createCatalogue: 'catalogue/create',
			updateCatalogue: 'catalogue/update',
			getCatalogue: 'catalogue/get',
			deleteCatalogue: 'catalogue/deleteById',
			getMemberships: 'account/getMemberships',
			findTitles: 'book/find',
			countTitles: 'book/count',
			clearTitles: 'book/clearItems',
			clearCatalogue: 'catalogue/clearItem'
		}),
		getBadgeStatusVariant,
		getBookBadgeStatusText,
		viewBook(book) {
			this.$router.push({ name: 'books.view', params: { id: book._id } });
		},
		handleTableChange({ currentPage, perPage, filter, sort, selectedCols }) {
			this.currentPage = currentPage;

			if (sort) {
				this.sort = sort;
			}
			if (perPage) {
				this.setPageConfig({
					route: this.$route.name,
					config: { ...this.pageConfig, perPage }
				});
			}
		},
		async fetchMemberships($skip = 0, $limit = 50) {
			const query = { $limit, $skip };
			const { skip, total, data } = await this.getMemberships({ query });

			if (_.size(data) === 0) {
				return;
			}

			this.memberships = _.concat(this.memberships, data);

			if (skip + $limit < total) {
				return this.fetchMemberships(skip + $limit);
			}
		},
		async fetchData() {
			this.isBusy = true;
			let query = {
				query: {
					catalogueIds: this.id,
					$populate: ['books'],
					$limit: this.pageConfig.perPage,
					$skip: this.pageConfig.perPage * (this.currentPage - 1),
					$sort: this.sort
				}
			};

			try {
				await this.findTitles({ query });
				await this.countTitles({ query });
			} catch (err) {
				this.$toaster.error(this.$t('Error during fetch books'), { timeout: 2000 });
			} finally {
				this.isBusy = false;
			}
		},
		async onSave() {
			if (this.id) {
				await this.updateCatalogue({
					id: this.$route.params.id,
					data: this.formData
				});
			} else {
				await this.createCatalogue(this.formData);
			}

			this.$toaster.success(this.$t('Catalogue has been updated'), { timeout: 3000 });
			this.$router.push({ name: 'catalogues' });
		},
		async onClickDelete() {
			this.openConfirmModal({
				title: this.$t('Delete catalogue'),
				text: this.$t('Are you sure you want to delete this catalogue?'),
				type: 'info',
				showCancelButton: true,
				confirmType: 'danger',
				confirmText: this.$t('Yes'),
				closeOnConfirm: true,
				success: async () => {
					await this.deleteCatalogue({ id: this.id });
					this.$toaster.success(this.$t('Catalogue succesfully deleted'), { timeout: 2000 });
					this.$router.push({ name: 'catalogues' });
				}
			});
		},
		userSelected(value) {
			return this.updateFormData({
				userIds: value.map(u => u._id)
			});
		},
		setForm() {
			this.setFormData(this.catalogue);
		},
		async refresh() {
			this.isBusy = true;
			await this.getCatalogue(this.id);
			this.setForm();
			this.isBusy = false;
		},
		openConfirmModal(options) {
			this.confirmModal = options;
		},
		cancelConfirmModal() {
			this.confirmModal = null;
		}
	}
};
</script>
<style lang="scss">
@import '~@workflow-solutions/ofs-vue-layout/dist/style/variables';
@import '~@workflow-solutions/ofs-vue-layout/dist/style/mixins';

.Catalogue {
	flex: 1;
	display: flex;
	flex-direction: column;

	.OfsPanel-content {
		flex: 1;
		display: flex;
		flex-direction: column;
	}

	&-form {
		padding: 20px 0 0;
		flex: 1;
		display: flex;
		flex-direction: column;
	}

	&-details {
		margin: 0;
		padding: 0;
		flex: 1;
	}

	&-actions {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		background: #fff;
		padding: 20px;
		margin: 0 -20px -20px;
		border-top: 1px solid rgba(0, 0, 0, 0.1);
	}

	&-tabs {
		flex: 1;
		display: flex;
		flex-direction: column;
		margin: 0 20px;

		.tab-content,
		.tab-pane {
			height: 100%;
			flex: 1;
			padding: 0 0 10px;
			display: flex;
			flex-direction: column;
		}
	}
}
</style>
