<template>
	<div class="SpecificationExtraData">
		<div class="SpecificationExtraData-header">
			<label class="SpecificationExtraData-label">{{ $t('Properties') }}</label>
			<b-btn
				class="SpecificationExtraData-add-button"
				variant="primary"
				@click="showModal"
				v-if="items.length"
				:disabled="isSpecInUse"
			>
				{{ $t('Add Property') }}
			</b-btn>
		</div>
		<template v-if="items.length">
			<b-table
				:fields="fields"
				:items="items"
				@row-clicked="rowClicked"
				sticky-header="175px"
				fixed
				:hover="!isSpecInUse"
			/>
		</template>
		<template v-else>
			<b-btn
				v-t="'New Property'"
				@click="showModal"
				:disabled="isSpecInUse"
			/>
		</template>
		<b-modal
			v-model="isVisible"
			:title="(isEdit) ? $t('Edit Property') : $t('New Property') "
			:ok-title="$t('Save')"
		>
			<b-form>
				<of-form-input
					name="key"
					type="text"
					required
					:label="$t('Key')"
				/>
				<of-form-input
					name="value"
					type="text"
					required
					:label="$t('Value')"
				/>
			</b-form>
			<template slot="modal-footer">
				<b-button v-if="isEdit" class="mr-auto" variant="outline-danger" @click.prevent="onDelete">
					{{ $t('Delete') }}
				</b-button>
				<b-button @click.prevent="onClose">
					{{ $t('Cancel') }}
				</b-button>
				<b-button variant="primary" :disabled="!canSubmit" @click.prevent="onSave">
					{{ $t('Save') }}
				</b-button>
			</template>
		</b-modal>
	</div>
</template>

<script>
import _ from 'lodash';
import { OfFormInput, withForm } from '@workflow-solutions/ofs-vue-layout';
import { mapActions, mapGetters } from 'vuex';

export default {
	components: {
		OfFormInput
	},
	mixins: [withForm('SpecificationExtraData')],
	props: {
		specification: {
			type: Object,
			default: () => ({})
		}
	},
	data() {
		return {
			fields: [
				{
					key: 'key',
					label: this.$t('Key'),
					tdClass: 'tableTd'
				},
				{
					key: 'value',
					label: this.$t('Value'),
					tdClass: 'tableTd'
				}
			],
			isVisible: false,
			isEdit: false
		};
	},
	async mounted() {
		this.id = this.$route.params.id;
	},
	watch: {
		specification: {
			immediate: true,
			handler() {
				this.properties = this.specification.extraData || {};
			}
		}
	},
	computed: {
		canSubmit() {
			return this.formState.dirty && !this.formState.invalid;
		},
		isSpecInUse() {
			return this.specification.active;
		},
		items() {
			return  _.map(this.specification?.extraData || {}, (value, prop) => ({ 'key': prop, 'value': value }));
		}
	},
	methods: {
		...mapActions({
			getSpecification: 'specification/get',
			updateSpecification: 'specification/update'
		}),
		onClose() {
			this.isEdit = false;
			this.isVisible = false;
		},
		async onDelete() {
			delete this.properties[this.formData.key];

			await this.updateSpecification({
				id: this.id,
				data: {
					extraData: this.properties
				}
			});
			await this.getSpecification({ id: this.id });

			this.onClose();
		},
		async onSave() {
			this.properties[this.formData.key] = this.formData.value;

			await this.updateSpecification({
				id: this.id,
				data: {
					extraData: this.properties
				}
			});
			await this.getSpecification({ id: this.id });

			this.onClose();
		},
		rowClicked(item) {
			if (!this.isSpecInUse) {
				this.isEdit = true;
				this.showModal(item);
			}
		},
		showModal(item) {
			this.resetFormData();
			this.setFormData(item);
			this.isVisible = true;
		}
	}
};
</script>

<style lang="scss">
.SpecificationExtraData {
	margin-top: 20px;
	display: flex;
	flex-direction: column;

	&-button {
		margin-top: 20px;
	}

	&-header {
		display: flex;
		margin-bottom: 5px;
	}

	&-label {
		font-weight: bold;
		margin-bottom: 0;
	}

	&-add-button {
		padding: 5px;
		margin-left: auto;
	}
}

.tableTd {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
</style>
