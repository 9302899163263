<template>
	<DefaultLayout>
		<ContentHeader :title="$t('Fulfilment History')" />
	</DefaultLayout>
</template>
<script>
import { ContentHeader } from '@workflow-solutions/ofs-vue-layout';
import { mapGetters, mapActions } from 'vuex';
import DefaultLayout from '../../../components/DefaultLayout';
/* eslint no-return-assign: 0 */
export default {
	components: {
		DefaultLayout,
		ContentHeader
	},
	data() {
		return {};
	},
	computed: {
		...mapGetters({
			orderSummary: 'summary/orderSummary',
			orderTotalToday: 'summary/orderTotalToday'
		})
	},
	methods: {
		...mapActions({
			getOrderSummary: 'summary/getOrderSummary'
		})
	}
};
</script>
