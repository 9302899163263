<template>
	<DefaultLayout>
		<ofs-panel class="SpecificationList">
			<Loader v-if="isLoading" class="w-100 h-100" />
			<ListTable
				v-else
				:items="specifications"
				:fields="fields"
				:busy="isBusy"
				:total-items="count"
				:per-page="pageConfig.perPage"
				:current-page="currentPage"
				:config="config"
				:fetch-data="fetchData"
				:selected="selected"
				:page-position-prefix="$t('Showing')"
				:page-position-join="$t('of')"
				hover
				@row-clicked="selectedSpecification"
				@table-change="handleTableChange"
			>
				<template slot="TableButtons-Slot-left" slot-scope="{}">
					<search
						:placeholder="$t('Filter by Description or Spec Code')"
						@change="handleSearchChange"
						@clear="clearSearch"
					/>
				</template>
				<template slot="prev-text" slot-scope="{}">
					{{ $t('Prev') }}
				</template>
				<template slot="next-text" slot-scope="{}">
					{{ $t('Next') }}
				</template>
				<template slot="empty">
					<i>{{ $t('No Data') }}</i>
				</template>
				<template #cell(components)="data">
					{{ data.item.components.length }}
				</template>
				<template #cell(dimensions)="data">
					<span v-if="data.item.width && data.item.height">
						{{ data.item.width | toFixed(3) }} x {{ data.item.height | toFixed(3) }}
					</span>
					<span v-else v-t="'N/A'" />
				</template>
				<template #cell(status)="data">
					<ofs-badge v-if="data.item.active" status="live" :text="$t('In Use')" />
					<ofs-badge v-else status="success" :text="$t('Pending')" />
				</template>
				<template #cell(subType)="data">
					{{ translateString(data.item.specTemplate.description) }}
				</template>
			</ListTable>

			<SpecificationAddModal :show="isNewSpecModalVisible" :on-close="onCloseNewSpecModal" />
		</ofs-panel>
	</DefaultLayout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import _debounce from 'lodash/debounce';
import _uniq from 'lodash/uniq';
import _get from 'lodash/get';
import _cloneDeep from 'lodash/cloneDeep';
import { OfsPanel, OfsBadge, ListTable } from '@workflow-solutions/ofs-vue-layout';
import { i18n } from 'src/vuex';
import { toFixed } from '../../lib/filters';
import { featureFlagCheckMixin } from '../../mixins/featureFlagCheck';
import DefaultLayout from '../../components/DefaultLayout';
import Loader from '../../components/Loader';
import Search from '../../components/Search';
import SpecificationAddModal from './SpecificationAddModal';

export default {
	mixins: [featureFlagCheckMixin('piazza-legacy', 'piazza')],
	components: {
		DefaultLayout,
		OfsPanel,
		OfsBadge,
		Loader,
		Search,
		SpecificationAddModal,
		ListTable
	},
	filters: {
		toFixed
	},
	data() {
		const $t = str => i18n.t(str);
		return {
			currentPage: 1,
			isBusy: false,
			search: null,
			isLoading: false,
			config: {
				breadcrumbs: { visible: false },
				filter: { visible: false },
				search: { visible: false },
				add: {
					visible: true,
					href: '/specifications/add',
					title: this.$t('Add Specification')
				},
				refresh: {
					visible: true,
					title: this.$t('Refresh')
				},
				columns: {
					visible: true,
					title: this.$t('Toggle Columns')
				}
			},
			baseFields: [
				{ key: 'status', label: $t('Status') },
				{
					key: 'description',
					label: $t('Description'),
					sortable: true
				},
				{
					key: 'sourceSpecId',
					label: $t('Spec Code'),
					sortable: true
				},
				{ key: 'components', label: $t('Components') },
				{ key: 'subType', label: $t('Type') },
				{ key: 'dimensions', label: $t('Dimensions') }
			],
			selected: ['status', 'description', 'sourceSpecId', 'components', 'subType', 'dimensions'],
			sort: '',
			specModalVisible: false
		};
	},
	computed: {
		...mapGetters({
			getPageConfig: 'ui/getPageConfig',
			specifications: 'specification/specifications',
			count: 'specification/count',
			accountSettings: 'account/accountSettings'
		}),
		pageConfig() {
			return (
				this.getPageConfig(this.$route.name) || {
					perPage: 10
				}
			);
		},
		units() {
			return _get(this.accountSettings, 'dimensionUnit', '');
		},
		fields() {
			const resultFields = _cloneDeep(this.baseFields);
			const lastItem = resultFields[resultFields.length - 1];
			lastItem.label = `${lastItem.label} (${this.units})`;
			return resultFields;
		},
		isNewSpecModalVisible() {
			return this.specModalVisible || this.$route.name === 'specifications.add';
		}
	},
	async mounted() {
		this.isLoading = true;
		this.fetchData();
	},
	methods: {
		...mapActions({
			findSpecifications: 'specification/find',
			countSpecifications: 'specification/count',
			setPageConfig: 'ui/setPageConfig'
		}),
		async fetchData() {
			this.isBusy = true;
			const query = {
				query: {
					type: 'Book',
					$populate: 'specTemplate',
					$limit: this.pageConfig.perPage,
					$skip: this.pageConfig.perPage * (this.currentPage - 1),
					$sort: this.sort
				}
			};

			if (this.search) {
				query.query.$or = [
					{
						description: {
							$regex: this.search,
							$options: 'i'
						}
					},
					{
						sourceSpecId: {
							$regex: this.search,
							$options: 'i'
						}
					}
				];
			}

			try {
				await Promise.all([this.findSpecifications({ query }), this.countSpecifications({ query })]);
			} catch (err) {
				this.$notify({ type: 'error', title: this.$t('An error occurred while fetching the data.') });
			} finally {
				this.isBusy = false;
				this.isLoading = false;
			}
		},
		handleSearchChange: _debounce(function doSearch(query) {
			this.search = query;

			// Reset pagination
			this.currentPage = 1;
			this.fetchData();
		}, 300),
		clearSearch() {
			this.search = null;
			this.fetchData();
		},
		handleTableChange({ currentPage, perPage, filter, sort, selectedCols }) {
			this.currentPage = currentPage;
			this.selected = selectedCols;
			this.filter = filter;
			if (sort) {
				this.sort = sort;
			}
			if (perPage) {
				this.setPageConfig({
					route: this.$route.name,
					config: { ...this.pageConfig, perPage }
				});
			}
		},
		selectedSpecification(item) {
			this.$router.push({ name: 'specifications.edit', params: { id: item._id } });
		},
		onClickNewSpec() {
			this.specModalVisible = true;
		},
		onCloseNewSpecModal() {
			this.specModalVisible = false;
			this.$router.push({ name: 'specifications' });
		},
		translateString(str) {
			return i18n.t(str);
		}
	}
};
</script>

<style lang="scss">
.badge.SpecBadge {
	display: block;
	padding: 0.3rem 0.6rem;
	font-size: 12px;
}
</style>
