import _ from 'lodash';
import axios from 'axios';
import moment from 'moment';
import menuStructure from '../../router/menuStructure';
import { i18n } from '../index';

const translateMenuItems = items => {
	_.each(items, item => {
		item.title = i18n.t(item.title);

		if (item.items) {
			translateMenuItems(item.items);
		}
	});
};

export default function createActions($i18n) {
	/* eslint-disable arrow-body-style */
	const setLanguage = async ({ commit }, { lang }) => {
		commit('SET_LANGUAGE', { lang });
		window.location.reload();
	};

	const loadLanguage = async ({ commit, dispatch }, { lang = 'en-GB' }) => {
		const { data } = await axios.get(
			`https://locales-static.hpsiteflow.com/
		piazza/${lang}.json`,
			{
				params: {
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded'
					}
				}
			}
		);

		// Replace the above with this block to load a mock tokens file if provided in the /static folder
		// const { data } = await axios.get('http://localhost:3001/static/tokens.json',
		// 	{
		// 		params: {
		// 			headers: {
		// 				'Content-Type':
		// 					'application/x-www-form-urlencoded'
		// 			}
		// 		}
		// 	});

		const tokens = data;
		$i18n.locale = lang;
		$i18n.setLocaleMessage(lang, tokens);
		document.querySelector('html').setAttribute('lang', lang);
		commit('SET_TOKENS', { tokens });
		translateMenuItems(menuStructure);
		dispatch('menu/updateMenu', { content: menuStructure, updateMethod: 'replace' }, { root: true });
		moment.locale(lang);
	};

	return { setLanguage, loadLanguage };
}
