<template>
	<div class="Artwork">
		<div v-if="url" class="Artwork-thumbnail" :style="{ backgroundImage: `url('${url}')` }" />
		<font-awesome-icon v-else class="Artwork-placeholder" :icon="placeholderIcon" scale="2" />
	</div>
</template>

<script>
export default {
	props: {
		url: {
			type: String,
			default: null
		},
		placeholderIcon: {
			type: String,
			default: 'file'
		}
	}
};
</script>

<style lang="scss">
@import '~@workflow-solutions/ofs-vue-layout/dist/style/variables';

.Artwork {
	display: flex;
	align-items: center;
	justify-content: center;
	background: $of-color-highlights;
	border: 1px solid #d2ddf4;
	border-radius: $of-border-radius;
	width: 70px;
	height: 70px;

	&-thumbnail {
		flex: 1;
		background-size: contain;
		background-repeat: no-repeat;
		background-position: 50%;
		height: 100%;
	}

	&-placeholder {
		flex: 1;
		width: 40%;
		height: 40%;
		color: $of-color-grey-2;
	}
}
</style>
