<template>
	<b-modal
		:visible="visible"
		modal-class="NewLinkModal"
		:title="$t('New Invitation')"
		:ok-disabled="okDisabled"
		:ok-title="$t('Send')"
		:cancel-title="$t('Cancel')"
		@ok="handleSend"
		@hide="handleClose"
	>
		<div>
			<img src="../../../../../public/img/invitation-code.svg" />
			<div class="info">
				{{ $t('Invite an account owner to link with your account. Once the invitation is accepted, orders can be submitted between accounts.') }}
			</div>
			<div>
				<of-form-input
					:label="$t('Recipient email')"
					name="email"
					type="email"
					:show-errors="true"
					required
				/>
			</div>
		</div>
	</b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { OfFormInput, withForm, validateWithMessage } from '@workflow-solutions/ofs-vue-layout';
import { required, email } from 'vuelidate/lib/validators';
import { displayError } from '../../../../lib/helpers';
import { $t } from '../../../../vuex';

export default {
	components: {
		OfFormInput
	},
	mixins: [withForm('NewLink')],
	props: {
		visible: {
			type: Boolean,
			default: false,
		}
	},
	data() {
		return {
			error: ''
		};
	},
	computed: {
		...mapGetters({ 
			linkCode: 'linkcode/linkCode'
		}),
		validationRules() {
			return {
				formData: {
					email: {
						required: validateWithMessage(this.$t('An email address is required'), required),
						email: validateWithMessage(this.$t('Must enter a valid email address'), email)
					}
				}
			};
		},
		okDisabled() {
			return !this.canSubmit || !!this.error;
		}
	},
	methods: {
		...mapActions({ 
			createLinkCode: 'linkcode/create' 
		}),
		displayError,
		async handleSend() {
			try {
				await this.createLinkCode({ email: this.formData?.email });
				if (this.linkCode?.emailSuccess === false) {
					this.$toaster.warning($t('Invitation created but sending email has failed'), { timeout: 3000 });
				} else if (this.linkCode?.emailSuccess === true) {
					this.$toaster.success($t('Invitation email sent'), { timeout: 2000 });
				}
				this.$emit('create');
			} catch (err) {
				this.$toaster.error($t('New invitation operation has failed'), { timeout: 3000 });
			} finally {
				this.handleClose();
			}
		},
		handleClose() {
			this.resetFormData();
			this.$emit('hide');
		}
	}
};
</script>

<style lang="scss">
.NewLinkModal {
	img {
		display: block;
		margin-left: auto;
		margin-right: auto;
		width: 25%;
	}

	.info {
		text-align: center;
		padding: 20px 70px;
	}
}
</style>
