<template>
	<b-modal
		v-model="show"
		:title="$t('Download Files')"
		:ok-disabled="okDisabled"
		:ok-title="okTitle"
		:cancel-title="$t('Cancel')"
		size="md"
		@ok="onSave"
	>
		<section class="ArchiveFiles">
			<b-row v-if="!downloadUrl" v-show="!archiving" class="ArchiveFiles-selected m-0">
				<div class="ArchiveFiles-selectedHeader mb-2 w-100">
					{{ $t('The following files will be downloaded as a .zip') }}:
				</div>
				<div class="d-flex flex-wrap justify-content-start align-items-center">
					<ofs-badge v-for="file in files" :key="file._id" class="mr-1 mb-1" :text="file.name" />
				</div>
			</b-row>
			<b-row v-if="archiving" class="m-0 justify-content-center">
				<span class="text-muted">{{ $t('Preparing the archive') }}...</span>
			</b-row>
			<b-row class="m-0 justify-content-center">
				<SmartLoader :is-loading="archiving" class="d-flex justify-content-center">
					<section>
						<div v-if="downloadUrl" class="d-flex justify-content-center align-items-center">
							<i class="fa fa-check-circle-o fa-lg text-success"></i>
							<span class="ml-2">{{ $t('Zip is ready to be downloaded') }}</span>
						</div>
					</section>
				</SmartLoader>
			</b-row>
		</section>
	</b-modal>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import withSubscriptionMixin from '../../../mixins/withSubscriptionMixin';
import SmartLoader from '../../../components/SmartLoader';
import { OfsBadge } from '@workflow-solutions/ofs-vue-layout';

export default {
	components: {
		SmartLoader,
		OfsBadge
	},
	mixins: [withSubscriptionMixin],
	props: {
		showModal: {
			type: Boolean,
			default: false
		},
		files: {
			type: Array,
			default: () => []
		},
		refresh: {
			type: Function,
			default: () => {}
		},
		onClose: {
			type: Function,
			default: () => {}
		}
	},
	data() {
		return {
			archiving: false,
			downloadUrl: ''
		};
	},
	computed: {
		...mapGetters({
			archive: 'archive/archive'
		}),
		show: {
			get() {
				return this.showModal;
			},
			set(value) {
				if (value === false) {
					this.onClose();
				}
			}
		},
		okTitle() {
			return this.downloadUrl ? this.$t('Download') : this.$t('Archive');
		},
		okDisabled() {
			return this.archiving && !this.downloadUrl;
		}
	},
	watch: {
		async archive() {
			if (this.archive && this.archive.status !== 'working') {
				this.archiving = false;
				this.unsubscribeAll();

				if (this.archive.status === 'ready') {
					this.$toaster.warning(this.$t('Download ready!'), { timeout: 3000 });
					this.downloadUrl = this.archive.url;
				} else {
					this.$toaster.error(this.$t('Could not download files'), { timeout: 3000 });
					this.onComplete();
				}

				try {
					await this.removeArchive(this.archive._id);
				} catch (err) {
					this.$toaster.error(this.$t('Could not remove current archive'), { timeout: 3000 });
				}
			}
		}
	},
	methods: {
		...mapActions({
			archiveFiles: 'archive/archiveFiles',
			removeArchive: 'archive/removeArchive',
			observeArchive: 'archive/observeOne'
		}),
		async startArchiving() {
			this.archiving = true;

			try {
				const fileIds = this.files.map(f => f.file._id);
				const { _id } = await this.archiveFiles(fileIds);
				await this.addSubscription(this.observeArchive({ id: _id, query: { query: null } }));
			} catch (e) {
				this.$toaster.error(this.$t('Could not download files'), { timeout: 3000 });
				this.onComplete();
			}
		},
		async onComplete() {
			this.archiving = false;
			this.downloadUrl = '';
			this.onClose();
		},
		onSave(e) {
			e.preventDefault();

			if (this.downloadUrl) {
				window.location.href = this.downloadUrl;
				this.onComplete();
				return;
			}

			if (!this.archiving) {
				this.startArchiving();
			}
		}
	}
};
</script>

<style lang="scss" scoped></style>
