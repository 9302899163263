<template>
	<div v-if="shouldShowPanel" class="ContextMenu">
		<slot v-if="$slots.text" name="text" />
		<slot></slot>
	</div>
</template>

<script>
export default {
	props: {
		show: {
			type: Boolean,
			default: false
		},
		onClose: {
			type: Function,
			default: () => {}
		},
		text: {
			type: String,
			default: null
		}
	},
	computed: {
		shouldShowPanel: {
			get() {
				return this.show;
			},
			set(show) {
				if (!show) {
					this.onClose();
				}
			}
		}
	}
};
</script>

<style lang="scss">
@import '~@workflow-solutions/ofs-vue-layout/dist/style/variables';
.ContextMenu {
	position: fixed;
	width: 100vw;
	max-width: 500px;
	bottom: 20px;
	left: 50%;
	transform: translateX(-50%);
	background: white;
	border: 1px solid $of-color-grey-3;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-radius: $of-border-radius;
	padding: 15px;
	box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.2);
	z-index: 10;
}
</style>
