<template>
	<b-modal
		v-model="isVisible"
		:title="$t('New Catalogue')"
		:ok-title="$t('Save')"
		:ok-disabled="!isValidForm"
		:cancel-title="$t('Cancel')"
		@ok="onSave"
	>
		<b-form>
			<of-form-input
				:horizontal="false"
				name="sourceCatalogueId"
				type="text"
				data-test-id="catalogueModalCatalogueName"
				:placeholder="$t('Catalogue Name')"
				:label="$t('Catalogue Name')"
				required
			>
			</of-form-input>
			<of-form-input
				:horizontal="false"
				name="description"
				type="text"
				data-test-id="catalogueModalDescription"
				:label="$t('Description')"
				:placeholder="$t('Description')"
				required
			>
			</of-form-input>
			<of-multi-select
				:horizontal="false"
				name="tags"
				class="mb-0"
				data-test-id="catalogueModalTags"
				:label="`${$t('Tags')}:`"
				label-by=""
				:searchable="false"
				:multiple="true"
				:taggable="true"
				:tag-placeholder="$t('Add this as new tag')"
				:placeholder="$t('Add a tag')"
				required
			>
			</of-multi-select>
		</b-form>
	</b-modal>
</template>

<script>
import { mapActions } from 'vuex';
import { OfFormInput, OfMultiSelect, withForm } from '@workflow-solutions/ofs-vue-layout';

export default {
	components: {
		OfFormInput,
		OfMultiSelect
	},
	mixins: [withForm('catalogueAddForm')],
	props: {
		show: Boolean,
		onClose: {
			type: Function,
			default: () => {}
		}
	},
	computed: {
		isVisible: {
			get() {
				return this.show;
			},
			set(show) {
				if (!show) {
					this.onClose();
				}
			}
		},
		isValidForm() {
			return !this.formSummary.invalid;
		}
	},
	mounted() {
		this.setFormData({});
	},
	methods: {
		...mapActions({
			createCatalogue: 'catalogue/create'
		}),
		...mapActions(['dispatchSubmit']),
		async onSave($event) {
			$event.preventDefault();
			const newCatalogue = await this.dispatchSubmit(this.createCatalogue(this.formData));
			this.$router.push({ name: 'catalogues.edit', params: { id: newCatalogue._id } });
		},
		onAddTag(newTag) {
			this.formData.tags.push(newTag);
		}
	}
};
</script>
