import Vue from 'vue';
import VueResource from 'vue-resource';
import OfsCrud from '@workflow-solutions/ofs-vuex-crud';
import authModule from './auth';
import registerOrdersModule from './orders';
import registerBooksModule from './books';
import registerBoxModule from './box';
import registerImportsModule from './imports';
import registerValidationsModule from './validations';
import registerNotificationModule from './notification';
import registerAccountModule from './account';
import registerArchiveModule from './archive';
import registerMaterialModule from './materials';
import registerHpSpecTemplateModule from './hpSpecTemplates';
import registerFulfilmentCountryModule from './fulfilmentCountry';
import registerFulfilmentCurrencyModule from './fulfilmentCurrency';
import registerFulfilmentSpecificationModule from './fulfilmentSpecification';
import registerShipmentPriceSheetModule from './shipmentPriceSheet';
import registerShipmentServiceLevelModule from './shipmentServiceLevel';
import registerShipmentWeightBandModule from './shipmentWeightBand';
import registerSpecificationModule from './specification';
import registerSpecTemplatesModule from './specTemplates';
import registerComponentsModule from './components';
import registerWallModule from './wall';
import registerReportsModule from './reports';
import registerClientModule from './client';
import registerAnalyticsOrdersModule from './analyticsOrders';
import registerAnalyticsTitlesModule from './analyticsTitles';
import registerRoutingRulesModule from './routingRules';
import registerFilesModule from './files';
import registerCataloguesModule from './catalogues';
import registerTriggersModule from './triggers';
import registerTemplatesModule from './templates';
import registerLangStoreModule from './lang';
import registerToolsModule from './tools';
import registerPreviewerModule from './previewer';
import registerBookPreviewerModule from './bookPreviewer';
import registerSkusModule from './sku';
import registerUserModule from './user';
import registerShipmentModule from './shipment';
import registerFeatureFlagsModule from './featureFlags';
import registerTriggerSourceModule from './triggerSource';
import registerFulfilmentOrderModule from './fulfilmentOrder';
import registerExportModule from './export';
import registerUIModule from './ui';
import registerAccountLinkModule from './accountlink';
import registerLinkCodeModule from './linkcode';
import httpOfBaseApiTransportInstance from './httpOfBaseApiTransportInstance';
import httpConnectProviderInstance from './httpConnectTransportInstance';
import httpFilesApiTransportInstance from './httpFilesApiTransportInstance';
import httpSummaryTransportInstance from './httpSummaryTransportInstance';
import httpProductApiTransportInstance from './httpProductApiTransportInstance';
import httpFulfilmentApiTransportInstance from './httpFulfilmentApiTransportInstance';
import httpDashboardTransportInstance from './httpDashboardApiTransportInstance';
import registerFulfilments from './fulfilment';
import registerFulfilmentItems from './fulfilmentItem';
import registerFulfilmentSubmissionError from './fulfilmentSubmissionError';

const setupModules = (store, i18n) => {
	Vue.use(VueResource);
	Vue.use(OfsCrud, { store });

	// Register Transports
	Vue.$ofsCrud.registerTransport({ name: 'ofBaseApi', fn: () => httpOfBaseApiTransportInstance });
	Vue.$ofsCrud.registerTransport({ name: 'fulfilmentApi', fn: () => httpFulfilmentApiTransportInstance });
	Vue.$ofsCrud.registerTransport({ name: 'connect', fn: () => httpConnectProviderInstance });
	Vue.$ofsCrud.registerTransport({ name: 'filesApi', fn: () => httpFilesApiTransportInstance });
	Vue.$ofsCrud.registerTransport({ name: 'summary', fn: () => httpSummaryTransportInstance });
	Vue.$ofsCrud.registerTransport({ name: 'productApi', fn: () => httpProductApiTransportInstance });
	Vue.$ofsCrud.registerTransport({ name: 'fulfilmentApi', fn: () => httpFulfilmentApiTransportInstance });
	Vue.$ofsCrud.registerTransport({
		name: 'dashboardApi',
		fn: () => httpDashboardTransportInstance
	});

	// Register Modules
	Vue.$ofsCrud.registerAuthModule(authModule);
	registerAccountModule(store);
	registerArchiveModule(store);
	registerBoxModule(store);
	registerWallModule(store);
	registerClientModule();
	registerMaterialModule();
	registerHpSpecTemplateModule();
	registerFulfilmentSpecificationModule();
	registerFulfilmentOrderModule();
	registerNotificationModule();
	registerFulfilmentCountryModule();
	registerFulfilmentCurrencyModule();
	registerShipmentPriceSheetModule();
	registerShipmentServiceLevelModule();
	registerShipmentWeightBandModule();
	registerOrdersModule(store);
	registerBooksModule(store);
	registerImportsModule(store);
	registerValidationsModule(store);
	registerSpecificationModule(store);
	registerSpecTemplatesModule(store);
	registerComponentsModule(store);
	registerReportsModule(store);
	registerAnalyticsOrdersModule();
	registerAnalyticsTitlesModule();
	registerRoutingRulesModule(store);
	registerFilesModule(store);
	registerCataloguesModule(store);
	registerTriggersModule(store);
	registerTemplatesModule(store);
	registerLangStoreModule(store, i18n);
	registerToolsModule(store);
	registerPreviewerModule(store);
	registerBookPreviewerModule(store);
	registerSkusModule(store);
	registerUserModule(store);
	registerShipmentModule(store);
	registerFeatureFlagsModule(store);
	registerTriggerSourceModule(store);
	registerExportModule();
	registerUIModule(store);
	registerAccountLinkModule();
	registerLinkCodeModule();
	registerFulfilments();
	registerFulfilmentItems();
	registerFulfilmentSubmissionError();
};

export default setupModules;
