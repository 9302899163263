<template>
	<li class="SplitShipmentItem">
		<aside v-if="!isStockItem" class="SplitShipmentItem__thumbnail Artwork" data-test-id="splitModalThumbnail">
			<thumbnail
				v-if="file && !file.localFile"
				class="Artwork_image"
				:src="file.thumbnailUrl"
				data-test-id="splitModalFileThumbnail"
			/>
			<div v-else class="localfile" data-test-id="splitModalLocalThumbnail">
				<font-awesome-icon class="localfile_icon" icon="hdd" scale="2" />
				<span class="localfile_text" v-text="$t('Local file')"></span>
			</div>
		</aside>
		<aside v-else class="SplitShipmentItem__thumbnail Artwork" data-test-id="splitModalThumbnail">
			<thumbnail class="Artwork_image" :src="stockItemThumbnailUrl" data-test-id="splitModalFileThumbnail" />
		</aside>
		<div class="SplitShipmentItem__info">
			<strong data-test-id="splitModalProductDesc" class="SplitShipmentItem__description">{{
				item.productDescription || item.name
			}}</strong>
			<span class="SplitShipmentItem__extra" data-test-id="splitModalItemBarcode">
				<ofs-badge v-if="isStockItem" :text="$t('Stock')" status="complete" class="mr-2" />
				{{ item.barcode }}
			</span>
		</div>
		<div class="SplitShipmentItem__quantities">
			<b-form-group
				class="SplitShipmentItem__quantity"
				data-test-id="reprintModalOriginalQty"
				:label="$t('Original Qty')"
				label-class="SplitShipmentItem__label"
			>
				<b-form-input v-model="maxQuantity" type="number" class="SplitShipmentItem__value" disabled />
			</b-form-group>
			<b-form-group
				class="SplitShipmentItem__quantity"
				data-test-id="reprintModalReprintQty"
				:label="$t('Reprint Qty')"
				label-class="SplitShipmentItem__label"
			>
				<b-form-input
					v-model="newQuantity"
					type="number"
					class="SplitShipmentItem__value"
					:max="maxQuantity"
					min="0"
					@update="newQuantityUpdated"
				/>
			</b-form-group>
			<b-button data-test-id="reprintModalReprintAllBtn" class="SplitShipmentItem__move" @click="reprintAll">
				{{ $t('Reprint All') }}
			</b-button>
		</div>
	</li>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import { OfsBadge } from '@workflow-solutions/ofs-vue-layout';
import Thumbnail from '../../Thumbnail';

export default {
	components: {
		OfsBadge,
		Thumbnail
	},
	props: {
		item: {
			type: Object,
			required: true,
			default: () => ({})
		},
		count: {
			type: [Number, String],
			default: 0
		}
	},
	computed: {
		...mapGetters({
			order: 'order/order',
			stockItemThumbnailUrls: 'order/stockItemThumbnailUrls'
		}),
		newQuantity: {
			get() {
				return this.count;
			},
			set(quantity) {
				this.$emit('change', { _id: this.item._id, quantity: quantity || 0 });
			}
		},
		stockItemThumbnailUrl() {
			return _.get(this, `stockItemThumbnailUrls.${this.item.stockId}`, '');
		},
		isStockItem() {
			return !!this.item.code;
		},
		file() {
			const fileId = _.get(this.item, 'components[0].fileId');
			return _.find(this.order.files, { _id: fileId });
		},
		quantity() {
			// Quantity should exclude reconciled items and items moved to new shipment
			const quantity = this.item.quantity - this.newQuantity;
			return quantity > 0 ? quantity : 0;
		},
		maxQuantity() {
			return this.item.quantity;
		},
		hasUnreconciled() {
			return this.item.quantity > this.item.scanned + this.newQuantity;
		}
	},
	methods: {
		reprintAll() {
			this.newQuantity = this.item.quantity;
		},
		moveUnreconciled() {
			this.newQuantity = this.item.quantity - this.item.scanned;
		},
		newQuantityUpdated(newQuantity) {
			if (newQuantity > this.maxQuantity) {
				this.newQuantity = this.maxQuantity;
			} else if (newQuantity < 0) {
				this.newQuantity = 0;
			}
		}
	}
};
</script>

<style lang="scss">
@import '~@workflow-solutions/ofs-vue-layout/dist/style/variables';
@import '~@workflow-solutions/ofs-vue-layout/dist/style/mixins';

.SplitShipmentItem {
	padding: 15px 0;
	border-top: 1px solid $of-color-highlights;
	display: flex;
	justify-content: space-between;
	align-items: center;

	&:last-child {
		padding-bottom: 0;
	}

	&__thumbnail {
		margin-right: 15px;
		width: 80px;
		height: 80px;
	}

	&__info {
		margin: 0;
		display: flex;
		flex-direction: column;
		flex: 1;
	}

	&__extra {
		display: flex;
		align-items: center;
	}

	&__quantities {
		display: flex;
		align-items: flex-end;
	}

	&__move {
		margin-left: 5px;
		height: 35px;
		padding: 5px 12px;
	}

	&__quantity {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		max-width: 100px;
		margin: 0 0 0 15px;
	}

	&__value {
		height: 35px;
		width: 80px;
		font-size: 18px;
		display: flex;
		align-items: center;
	}

	&__label {
		@include ofTextStyleDescriptions();
	}
}
</style>
