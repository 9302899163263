<template>
	<DefaultLayout>
		<ofs-panel>
			<section class="PendingLinksList">
				<AsyncLoader :fetch-data="fetchData">
					<ListTable
						:table-title="$t('Pending Invitations')"
						:breadcrumbs="breadcrumbs"
						:items="invitations"
						:fields="fields"
						:total-items="count"
						:per-page="perPage"
						:current-page="currentPage"
						:config="config"
						:fetch-data="fetchData"
						:page-position-prefix="$t('Showing')"
						:page-position-join="$t('of')"
						class="PendingLinksList-Table"
						@table-change="handleTableChange"
					>
						<template slot="TableButtons-Slot-right" slot-scope="{}">
							<b-button
								v-t="'Verify Invitation'"
								class="ml-2 rounded"
								variant="secondary"
								@click="openVerifyLinkModal"
							/>
							<b-button
								v-t="'New Invitation'"
								v-b-modal
								class="ml-2 rounded"
								variant="primary"
								@click="openNewLinkModal"
							/>
						</template>
						<template v-slot:cell(code)="{ item }">
							<span :class="{ expired: item.expired }">
								{{ item.code }}
							</span>
						</template>
						<template v-slot:cell(updatedAt)="{ item }">
							<span v-if="item.expired">
								{{ item.updatedAt | dateTimeFormat }}
							</span>
							<span v-else>
								{{ item.updatedAt | relativeDateTimeFormat  }}
							</span>
						</template>
						<template v-slot:cell(expirationDate)="{ item }">
							<span :class="{ expired: item.expired }">
								{{ item.expirationDate | relativeDateTimeFormat }}
							</span>
						</template>
						<template slot="prev-text" slot-scope="{}">
							{{ $t('Prev') }}
						</template>
						<template slot="next-text" slot-scope="{}">
							{{ $t('Next') }}
						</template>
						<template slot="empty">
							<span>
								<i>{{ $t('No Data') }}</i>
							</span>
						</template>
						<template v-slot:cell(resend)="{ item }">
							<b-button v-t="'Resend'" class="resend-button" @click="handleResendCode(item)" />
						</template>
						<template v-slot:cell(delete)="code">
							<font-awesome-icon
								class="delete-code-icon"
								icon="times-circle"
								@click="openDeleteCodeModal(code)"
							/>
						</template>
					</ListTable>
				</AsyncLoader>
				<VerifyLinkModal 
					:visible="modals.isVerifyLinkModalVisible"
					@hide="closeVerifyLinkModal"
				/>
				<NewLinkModal 
					:visible="modals.isNewLinkModalVisible" 
					@create="fetchData"
					@hide="closeNewLinkModal"
				/>
				<DeleteCodeModal
					v-if="modals.isDeleteCodeModalVisible"
					:show-modal="modals.isDeleteCodeModalVisible"
					:refresh="fetchData"
					:on-close="closeDeleteCodeModal"
					:item="selectedItem"
				/>
			</section>
		</ofs-panel>
	</DefaultLayout>
</template>

<script>
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';
import { ListTable, OfsPanel } from '@workflow-solutions/ofs-vue-layout';
import { dateTimeFormat, relativeDateTimeFormat } from '../../../lib/filters';
import DefaultLayout from '../../../components/DefaultLayout';
import AsyncLoader from '../../../components/AsyncLoader';
import DeleteCodeModal from './modals/DeleteCodeModal';
import VerifyLinkModal from '../LinkedAccounts/modals/VerifyLinkModal';
import NewLinkModal from '../LinkedAccounts/modals/NewLinkModal';
import { $t } from '../../../vuex';

export default {
	name: 'PendingLinksList',
	components: {
		ListTable,
		OfsPanel,
		DefaultLayout,
		AsyncLoader,
		DeleteCodeModal,
		VerifyLinkModal,
		NewLinkModal
	},
	filters: {
		dateTimeFormat,
		relativeDateTimeFormat
	},
	data() {
		return {
			fields: [
				{ key: 'code', label: this.$t('Invitation Code') },
				{ key: 'email', label: this.$t('Email') },
				{ key: 'updatedAt', label: this.$t('Date created') },
				{ key: 'expirationDate', label: this.$t('Expires') },
				{ key: 'resend', label: '' },
				{ key: 'delete', label: '' }
			],
			config: {
				filter: { visible: false },
				search: { visible: false },
				breadcrumbs: { visible: true },
				refresh: { visible: false },
				columns: { visible: false }
			},
 			perPage: 10,
			currentPage: 1,
			selected: [],
			modals: {
				isVerifyLinkModalVisible: false,
				isNewLinkModalVisible: false,
				isDeleteCodeModalVisible: false
			},
			selectedItem: null
		};
	},
	computed: {
		...mapGetters({
			count: 'linkcode/count',
			linkCode: 'linkcode/linkCode',
			linkcodes: 'linkcode/linkcodes'
		}),
		breadcrumbs() {
			return [
				{ text: $t('Linked Accounts /'), to: { name: 'settings.accountlink' } },
				{ text: $t('Pending Links'), active: true }
			];
		},
		invitations() {
			return this.linkcodes.map(linkcode => {
				linkcode.expired = moment(linkcode.expirationDate).isBefore(this.now);
				return linkcode;
			});
		}
	},
	methods: {
		...mapActions({
			getLinkCodes: 'linkcode/find',
			regenerateLinkCode: 'linkcode/regenerate'
		}),
		async fetchData() {
			await this.getLinkCodes({
				query: {
					direction: -1,
					sort: 'expirationDate',
					page: this.currentPage,
					pagesize: this.perPage
				}
			});
		},
		handleTableChange({ currentPage, perPage }) {
			this.currentPage = currentPage;
			this.perPage = perPage;
		},
		openVerifyLinkModal() {
			this.modals.isVerifyLinkModalVisible = true;
		},
		closeVerifyLinkModal() {
			this.modals.isVerifyLinkModalVisible = false;
		},
		openNewLinkModal() {
			this.modals.isNewLinkModalVisible = true;
		},
		closeNewLinkModal() {
			this.modals.isNewLinkModalVisible = false;
		},
		openDeleteCodeModal(code) {
			this.selectedItem = code.item;
			this.modals.isDeleteCodeModalVisible = true;
		},
		closeDeleteCodeModal() {
			this.modals.isDeleteCodeModalVisible = false;
		},
		async handleResendCode(item) {
			try {
				await this.regenerateLinkCode({ codeId: item._id });
				if (this.linkCode?.emailSuccess === false) {
					this.$toaster.warning($t('Invitation code renewed but sending email has failed'), { timeout: 3000 });
				} else if (this.linkCode?.emailSuccess === true) {
					this.$toaster.success($t('Invitation email resent'), { timeout: 2000 });
				}
				this.fetchData();
			} catch (err) {
				this.$toaster.error($t('Remove operation has failed'), { timeout: 3000 });
			}
		}
	}
};
</script>

<style lang="scss">
@import '~@workflow-solutions/ofs-vue-layout/dist/style/variables';
.PendingLinksList {
	.resend-button {
		float: right;
	}

	.delete-code-icon {
		color: $of-color-grey-3;
		cursor: pointer;
		float: left;

		&:hover {
			color: $of-color-grey-2;
		}
	}

	.expired {
		color: $of-color-red;
	}
}
</style>
