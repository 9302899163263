import _get from 'lodash/get';
import _find from 'lodash/find';
import _sortBy from 'lodash/sortBy';

export const account = state => state.account;
export const accountSettings = state => _get(state, 'vars.oneflowAccountSettings');
export const accounts = state => state.accounts;
export const vars = state => state.vars;
export const pspAccounts = state => state.pspAccounts;
export const currentAccount = state => state.currentAccount;
export const currentUserMemberships = state => state.currentUserMemberships;
export const memberships = state => state.memberships;
export const membershipsCount = state => state.membershipsCount;
export const member = state => state.member;
export const userMemberships = state => state.userMemberships;
export const psp = state => state.psp;
export const psps = state => _sortBy(state.psps, 'text');
export const pspAccountSettings = state => state.pspAccountSettings;
export const isPiazzaFulfilment = (state, getters, rootState, rootGetters) => {
	const isFlagEnabled = rootGetters['featureToggle/features']?.['piazza-fulfilment'];
	return isFlagEnabled;
};
export const isPiazzaLegacy = (state, getters, rootState, rootGetters) => {
	const isFlagEnabled = rootGetters['featureToggle/features']?.['piazza-legacy'];
	return isFlagEnabled;
};
export const accountList = (state, getters, rootState, rootGetters) => {
	// Fetch Brand Centre/Piazza memberships
	const memberships = getters['currentUserMemberships'] || [];
	const user = rootGetters['auth/user'] || {};

	// Fetch available brand accounts from auth
	const accounts = rootGetters['auth/availableAccounts']?.filter(account => {
		const isBrandAccount = account.type === 'brand' || account.types?.includes('brand');
		const hasCustomerRole =
			_find(memberships, {
				accountId: account._id,
				userId: user._id
			})?.role === 'customer';

		const isOneflowAccount = account.name?.indexOf('oneflow') > -1;

		return (isBrandAccount && !hasCustomerRole) || isOneflowAccount;
	});

	return _sortBy(accounts, 'name');
};
