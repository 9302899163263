<template>
	<DefaultLayout>
		<ofs-panel v-if="isLoading">
			<Loader />
		</ofs-panel>
		<ofs-panel v-else class="OrderView">
			<content-header :title="$t('Create Order')" :breadcrumbs="breadcrumbs" no-padding class="mb-3">
				<of-submit-button
					data-test-id="Orders_createOrderButton"
					variant="primary"
					class="ml-1"
					@click="createOrder"
				>
					{{ $t('Create Order') }}
				</of-submit-button>
			</content-header>
			<b-alert v-if="orderError" show variant="danger">
				{{ orderError }}
			</b-alert>
			<section class="OrderView_container">
				<section class="OrderViewItems">
					<header class="OrderViewItems_header">
						<h2 class="OrderViewItems_title">
							{{ $t('Order Items') }}
							<span class="tag-list_item ml-2">{{ orderItemsCount }}</span>
						</h2>
						<b-dropdown v-if="shipment.shipTo" no-caret size="sm" data-test-id="shipmentActionDropdown">
							<template slot="button-content">
								<font-awesome-icon icon="ellipsis-h" />
							</template>
							<b-dropdown-item data-test-id="shipmentEditShipment" @click="editShippingInfo">
								{{ $t('Edit Shipping Details') }}
							</b-dropdown-item>
						</b-dropdown>
					</header>
					<ul class="OrderShipments">
						<li class="OrderShipment">
							<section class="OrderShipment-inner">
								<section class="OrderShipment-items">
									<template v-if="items.length">
										<b-table :fields="itemFields" :items="items">
											<template #cell(thumbnail)="data">
												<Artwork
													:url="data.item.thumbnail"
													class="OrderItem__artwork"
													placeholder-icon="book"
												/>
											</template>
											<template #cell(description)="{ item, index }">
												<p class="OrderShipmentBook__description" v-text="item.description"></p>
												<p class="OrderShipmentBook__isbn" v-text="item.isbn"></p>
												<b-alert
													v-if="showItemError(index)"
													show
													variant="danger"
													class="OrderShipmentBook__error"
												>
													{{ getItemErrorMessage(index) }}
												</b-alert>
											</template>
											<template #cell(quantity)="data">
												<span class="d-flex">
													<b-form-input
														v-model="data.item.quantity"
														type="number"
														min="1"
														step="1"
														size="sm"
														:formatter="formatQuantity"
														@change="quantityChanged($event, data.item)"
													/>
													<b-button size="sm" variant="link" @click="onRemoveItem(data.item)">
														<icon name="trash-alt"></icon>
													</b-button>
												</span>
											</template>
										</b-table>
										<div class="p-2">
											<ofs-feature-button
												data-test-id="addBook"
												class="OrderShipment-add"
												:label="$t('Add Item')"
												type="add"
												@click="addBook"
											/>
										</div>
									</template>
									<div v-else class="OrderShipmentMessage">
										<p>{{ $t('No items added to Order') }}</p>
										<b-button
											data-test-id="addBook"
											class="OrderShipmentMessage__action"
											@click="addBook"
										>
											{{ $t('Add Item') }}
										</b-button>
									</div>
								</section>
								<section class="OrderShipmentDetails">
									<div v-if="!shipment.shipTo" class="OrderShipmentMessage">
										<p>{{ $t('No Shipping Details Added') }}</p>
										<b-button data-test-id="shipmentEditShipment" @click="editShippingInfo">
											{{ $t('Add Details') }}
										</b-button>
									</div>
									<template v-else>
										<header class="OrderShipmentDetails_header">
											<span class="OrderShipmentDetails_title">{{ $t('Shipping Details') }}</span>
										</header>
										<p class="OrderShipmentDetails_address">
											<span data-test-id="ship-to-name">{{ name }}</span>
											<span data-test-id="ship-to-address1">{{ address1 }}</span>
											<span data-test-id="ship-to-address2">{{ address2 }}</span>
											<span data-test-id="ship-to-town">{{ town }}</span>
											<span data-test-id="ship-to-postcode">{{ postcode }}</span>
											<span data-test-id="ship-to-country">{{ isoCountry }}</span>
											<span data-test-id="ship-to-state">{{ state }}</span>
										</p>
										<p class="OrderShipmentDetails_key">{{ $t('Service Level') }}</p>
										<p data-test-id="shipment-carrier">{{ serviceLevel | capitalise }}</p>
										<accordion v-if="returnAddress" :title="$t('Return Address')" inline>
											<p class="mb-0" data-test-id="return-address" v-text="returnTo" />
										</accordion>
									</template>
									<div v-if="shipmentErrors && shipmentErrors.length">
										<b-alert
											v-for="se in shipmentErrors"
											:key="se.message"
											show
											variant="danger"
											class="OrderShipmentDetails_error"
										>
											{{ se.message }}
										</b-alert>
									</div>
								</section>
							</section>
						</li>
					</ul>
				</section>
				<div>
					<section class="OrderView_details">
						<section class="OrderDetail">
							<h2 class="OrderDetail_title" v-text="$t('Order Details')" />
							<b-row>
								<b-col class="OrderDetail_cell">
									<of-multi-select
										name="currencyIsoCode"
										label-class="OrderDetail_label"
										:label="$t('Currency')"
										:options="currencies"
										:placeholder="$t('Select currency...')"
										:allow-clear="false"
										disabled
										data-test-id="currencyName"
										track-by="code"
										label-by="name"
									/>
								</b-col>
							</b-row>
							<b-row class="mb-3">
								<b-col class="OrderDetail_cell">
									<of-form-input
										name="sourceOrderId"
										label-class="OrderDetail_label"
										data-test-id="orderId"
										:label="$t('Order ID')"
									/>
								</b-col>
							</b-row>
						</section>
					</section>
				</div>
			</section>
		</ofs-panel>
		<book-selector
			:fulfilment="true"
			:show="isAddBookModalVisible"
			:ok-label="$t('Add to Order')"
			:modal-title="$t('Add Book to Order')"
			:on-selected="onBookSelected"
			:on-close="closeAddBookModal"
			multiple
		/>
		<shipping-modal :shipment="shipment" :show="isShippingModalVisible" :on-close="closeShippingModal" />
	</DefaultLayout>
</template>

<script>
import moment from 'moment';
import _ from 'lodash';
import {
	Accordion,
	ContentHeader,
	OfsPanel,
	OfsBadge,
	OfsFeatureButton,
	OfFormInput,
	OfMultiSelect,
	OfSubmitButton,
	withForm
} from '@workflow-solutions/ofs-vue-layout';
import { mapActions, mapGetters } from 'vuex';
import { required, requiredIf, minLength, minValue } from 'vuelidate/lib/validators';
import DefaultLayout from '../../../components/DefaultLayout';
import Loader from '../../../components/Loader';
import Artwork from '../../../components/Artwork';
import BookSelector from '../../../components/modals/BookSelector';
import { displayError } from '../../../lib/helpers';
import ShippingModal from './Components/ShippingModal';
import { featureFlagCheckMixin } from '../../../mixins/featureFlagCheck';

const formName = 'createFulfilmentOrder';

export default {
	components: {
		Artwork,
		Accordion,
		BookSelector,
		ContentHeader,
		Loader,
		OfsPanel,
		OfsFeatureButton,
		DefaultLayout,
		ShippingModal,
		OfFormInput,
		OfSubmitButton,
		OfMultiSelect
	},
	filters: {
		capitalise: string => {
			return _.startCase(string);
		}
	},
	mixins: [withForm(formName), featureFlagCheckMixin('piazza-fulfilment')],
	data() {
		return {
			isLoading: false,
			isAddBookModalVisible: false,
			isShippingModalVisible: false,
			itemFields: [
				{
					key: 'thumbnail',
					label: '',
					tdClass: 'OrderItem__thumbnail pr-0'
				},
				{
					key: 'description',
					label: this.$t('Title')
				},
				{
					key: 'quantity',
					label: this.$t('Quantity'),
					tdClass: 'OrderItem__quantity'
				}
			],
			orderError: null,
			itemErrors: null,
			shipmentErrors: null
		};
	},
	computed: {
		...mapGetters({
			vars: 'account/vars',
			lang: 'lang/lang',
			countries: 'country/countries',
			currencies: 'currency/currenciesJson'
		}),
		validationRules() {
			return {
				formData: {
					sourceOrderId: {
						required
					},
					currencyIsoCode: {
						required
					},
					items: {
						required,
						minLength: minLength(1),
						$each: {
							quantity: {
								required,
								minValue: minValue(1)
							}
						}
					},
					shipments: {
						required,
						minLength: minLength(1),
						$each: {
							serviceLevel: {
								required
							},
							shipTo: {
								name: {
									required
								},
								address1: {
									required
								},
								town: {
									required
								},
								postcode: {
									required
								},
								isoCountry: {
									required
								},
								state: {
									required: requiredIf(function() {
										return this.stateRequired;
									})
								}
							}
						}
					}
				}
			};
		},
		stateRequired() {
			return !!this.country?.requireState;
		},
		country() {
			return _.find(this.countries, { id: this.formData?.shipTo?.isoCountry });
		},
		countryOptions() {
			return _map(this.countries, country => ({
				text: country.name,
				value: country.id
			}));
		},
		stateOptions() {
			return _map(this.country?.states || [], state => ({
				text: state.displayName,
				value: state.normalisedState
			}));
		},
		orderItemsCount() {
			return this.items.length;
		},
		accountCurrency() {
			return _.get(this.vars, 'oneflowAccountSettings.currency', 'GBP');
		},
		breadcrumbs() {
			return [
				{
					text: this.$t('All Orders'),
					to: {
						name: 'fulfilment.all'
					}
				},
				{
					text: this.pageTitle,
					active: true
				}
			];
		},
		sourceOrderId() {
			return _.get(this.formData, 'sourceOrderId');
		},
		items() {
			return _.get(this.formData, 'items', []);
		},
		shipment() {
			return _.get(this.formData, 'shipments[0]', {});
		},
		name() {
			return _.get(this.shipment, 'shipTo.name');
		},
		address1() {
			return _.get(this.shipment, 'shipTo.address1');
		},
		address2() {
			return _.get(this.shipment, 'shipTo.address2');
		},
		town() {
			return _.get(this.shipment, 'shipTo.town');
		},
		postcode() {
			return _.get(this.shipment, 'shipTo.postcode');
		},
		isoCountry() {
			return _.get(this.shipment, 'shipTo.isoCountry');
		},
		state() {
			return _.get(this.shipment, 'shipTo.state');
		},
		serviceLevel() {
			return _.get(this.shipment, 'serviceLevel');
		},
		returnAddress() {
			return _.get(this.shipment, 'returnAddress');
		},
		returnTo() {
			return this.generateShippingAddress(this.returnAddress);
		}
	},
	mounted() {
		this.fetchData();

		this.initFormData({
			sourceOrderId: moment().format('YYYYMMDDHHMMSS'),
			currencyIsoCode: this.accountCurrency,
			items: [],
			shipments: [
				{
					serviceLevel: 'standard'
				}
			]
		});
	},
	methods: {
		...mapActions({
			getCurrencies: 'currency/getCurrenciesJson',
			submitOrder: 'fulfilmentOrder/create',
			updateFormField: 'form/updateFormField',
			findFulfilmentCountries: 'country/findAll'
		}),
		async fetchData() {
			try {
				this.isLoading = true;
				if (!this.currenciesJson?.length) {
					await this.getCurrencies();
				}
				if (!this.countries?.length) {
					await this.findFulfilmentCountries();
				}
			} catch (err) {
				const message = displayError(err);
				this.$notify({ type: 'error ', text: message });
			} finally {
				this.isLoading = false;
			}
		},
		async createOrder() {
			try {
				this.isLoading = true;

				// Assign SourceItemIds to order items
				_.forEach(this.formData.items, (i, index) => {
					i.sourceItemId = `${this.sourceOrderId}-${index}`;
				});

				await this.submitOrder(this.formData);
				this.clearErrors();
				this.$notify({ type: 'success ', text: this.$t('Order submitted successfully') });
				this.$router.push({ name: 'fulfilment.all' });
			} catch (e) {
				this.handleError(e);
			} finally {
				this.isLoading = false;
			}
		},
		handleError(err) {
			this.orderError = _.get(err, 'response.data.message');

			this.itemErrors = _.filter(_.get(err, 'response.data.errors', []), e => e?.dataPath?.includes('items'));

			this.shipmentErrors = _.filter(_.get(err, 'response.data.errors', []), e =>
				e?.dataPath?.includes('shipments')
			);
		},
		clearErrors() {
			this.orderError = null;
			this.itemErrors = null;
			this.shipmentErrors = null;
		},
		showItemError(i) {
			if (!this.itemErrors || !this.itemErrors.length) return false;
			return _.find(this.itemErrors, ie => {
				const itemIndex = _.first(ie?.dataPath?.split('/data/items/')?.[1]?.split('/'));
				return +itemIndex === i;
			});
		},
		getItemErrorMessage(i) {
			const error = _.find(this.itemErrors, ie => {
				const itemIndex = _.first(ie?.dataPath?.split('/data/items/')?.[1]?.split('/'));
				return +itemIndex === i;
			});

			return error?.message;
		},
		addBook() {
			this.isAddBookModalVisible = true;
		},
		closeAddBookModal() {
			this.isAddBookModalVisible = false;
		},
		editShippingInfo() {
			this.isShippingModalVisible = true;
		},
		closeShippingModal() {
			this.isShippingModalVisible = false;
		},
		formatQuantity(quantity) {
			let qtyString = quantity.toString();
			return parseInt(qtyString.replace('[\,\.][0-9]+', ''));
		},
		quantityChanged(value, item) {
			let qty = parseInt(value);

			if (!qty || qty < 1) {
				item.quantity = 1;
			}
		},
		generateShippingAddress(address) {
			if (!address) return '';
			return [
				'name',
				'companyName',
				'address1',
				'address2',
				'address3',
				'postcode',
				'town',
				'isoCountry',
				'state'
			]
				.reduce((acc, key) => (address[key] ? [...acc, address[key]] : acc), [])
				.join(', ');
		},
		updateFormPath(fieldPath, value) {
			return this.updateFormField({ formName, fieldPath, value });
		},
		getItemIndex(item) {
			return _.findIndex(_.get(this.order, 'items'), item);
		},
		onBookSelected(books) {
			let items = [];

			_.forEach(_.values(books), b => {
				if (b.quantity > 0) {
					const existingItem = _.find(this.formData.items, {
						sourceProductId: b.sourceProductId
					});

					if (existingItem) {
						// If adding an item that already exists on the shipment, sum it's quantities
						existingItem.quantity = existingItem.quantity + b.quantity;
					} else {
						// If adding a new item, push it into the item array
						let item = {
							sourceProductId: b.sourceProductId,
							retailPrice: {
								netUnitAmount: b.unitPrice
							},
							quantity: b.quantity,
							description: b.title,
							isbn: b.isbn,
							thumbnail: b.thumbnail
						};

						items.push(item);
					}
				}
			});

			return this.updateFormPath('items', [...this.formData.items, ...items]);
		},
		async onRemoveItem(item) {
			const response = await this.$bvModal.msgBoxConfirm(
				this.$t('Are you sure you want to remove this item from the shipment?'),
				{
					title: this.$t('Remove Item'),
					size: 'sm',
					okTitle: this.$t('Yes'),
					cancelTitle: this.$t('No')
				}
			);
			if (response) {
				const updatedItems = _.without(this.formData.items, item);
				this.clearErrors();
				return this.updateFormPath('items', updatedItems);
			}
		}
	}
};
</script>

<style lang="scss">
@import '~@workflow-solutions/ofs-vue-layout/dist/style/variables';

.OrderItem {
	&__quantity {
		width: 120px;
		max-width: 120px;
	}

	&__artwork {
		width: 50px;
		height: 50px;
		cursor: default;
	}

	&__thumbnail {
		width: 65px;
		max-width: 65px;
	}
}

.OrderShipmentDetails_error,
.OrderShipmentBook__error {
	margin: 10px 0 0;
	padding: 5px 10px;
}
</style>
