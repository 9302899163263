<template>
	<DefaultLayout>
		<ofs-panel class="BulkImport" :class="classes">
			<Loader v-if="isLoading" class="BulkImport-loader" />
			<template v-else>
				<ContentHeader no-padding>
					<book-upload-statistics />
				</ContentHeader>
				<section class="BookUploadLines">
					<nav class="BookUploadLines-top">
						<b-form-radio-group
							v-model="selectedFilter"
							buttons
							:options="filterOptions"
							name="filterBooks"
							@input="handleFiltersChange"
						/>
						<aside>
							<b-btn
								v-b-tooltip.hover
								v-t="'Download Errors'"
								data-test-id="bulkImportDownloadErrorsBtn"
								:title="$t('Download a csv containing only the invalid csv lines')"
								@click.prevent="onDownloadCsv"
							/>
							<b-btn
								v-if="status === 'ready'"
								v-t="'Mark as Complete'"
								data-test-id="bulkImportMarkCompleteBtn"
								class="ml-2"
								@click="markAsComplete"
							/>
							<b-btn
								v-t="'Discard'"
								data-test-id="bulkImportDiscardBtn"
								variant="danger"
								class="ml-2"
								@click="discard"
							/>
						</aside>
					</nav>
					<datatable
						:items="validations"
						:config="config"
						:fields="fields"
						:total-items="count"
						:per-page="perPage"
						:current-page="currentPage"
						class="BookUploadLines-table"
						@table-change="handleTableChange"
					>
						<template #cell(edit)="data">
							<div v-if="!data.item.isValid" class="Edit" @click="onEditValidation(data.item)">
								<font-awesome-icon icon="edit" />
							</div>
						</template>
						<template v-for="(field, index) in csvLineKeys" v-slot:[`cell(${field})`]="data">
							<div v-if="field === 'specCode'" :key="`field-${index}`">
								<template v-if="getProperty(data.item.validationErrors, field)">
									<span
										v-if="
											getProperty(data.item.validationErrors[field], 'code') ===
												'NOT_ALL_FILES_FOUND'
										"
										v-b-tooltip.hover
										:title="errorMessage(getProperty(data.item.validationErrors[field], 'code'))"
									>
										{{ data.item.originalLine[field] }}
									</span>
									<span
										v-else
										v-b-tooltip.hover
										:title="errorMessage(getProperty(data.item.validationErrors[field], 'code'))"
									>
										<icon name="exclamation-circle" class="IconError"></icon>
									</span>
								</template>
								<template v-else>
									<span>{{ data.item.originalLine[field] }}</span>
								</template>
							</div>
							<div v-else :key="`field-${index}`">
								<span
									v-if="
										data.item.originalLine[field] && getProperty(data.item.validationErrors, field)
									"
									v-b-tooltip.hover
									:title="getPresentError(field, data.item.validationErrors[field])"
								>
									<icon name="exclamation-circle" class="IconError"></icon>
								</span>
								<span v-else-if="data.item.originalLine[field]">{{
									data.item.originalLine[field]
								}}</span>
								<icon
									v-else-if="requiredLineFields.includes(field)"
									v-b-tooltip.hover
									:title="csvErrorTitle(field)"
									name="exclamation-circle"
									class="IconError"
								>
								</icon>
							</div>
						</template>
						<template #cell(tags)="data">
							<ul
								v-if="data.item.originalLine.tags"
								class="tag-list"
								:set="(tags = data.item.originalLine.tags.split('|'))"
							>
									<li
										v-for="tag in tags.slice(0, 4)"
										:key="tag"
										class="tag-list_item"
										:title="tag"
									>
										{{ tag }}
									</li>
									<li v-if="tags.length > 4" class="tag-list_item">
										{{ `... ${tags.length - 4} More` }}
									</li>
							</ul>
						</template>
						<template #cell(status)="data">
							<ofs-badge v-if="data.item.status === 'complete'" :text="$t('Complete')" status="live" />
							<ofs-badge
								v-else-if="data.item.status === 'partial'"
								:text="$t('Partial')"
								status="pending"
							/>
							<ofs-badge
								v-else
								v-b-tooltip.hover
								:text="$t('Error')"
								status="error"
								:title="
									getProperty(data.item, 'validationErrors.dbError.message')
										? `${$t('An error occurred while saving the book')}:
								${getProperty(data.item, 'validationErrors.dbError.message')}`
										: 'Error'
								"
							/>
						</template>
					</datatable>
					<validation-modal
						:parent-fields="fields"
						:required-fields="requiredLineFields"
						:validation="validationEdit"
						@hide-modal="onHideModal"
						@submit-modal="onSubmitModal"
					/>
				</section>
			</template>
		</ofs-panel>
	</DefaultLayout>
</template>

<script>
import { ContentHeader, OfsPanel, Datatable, OfsBadge } from '@workflow-solutions/ofs-vue-layout';
import { mapActions, mapGetters } from 'vuex';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import Promise from 'bluebird';
import { i18n } from 'src/vuex';
import Loader from 'src/components/Loader';
import makeDownload from '../../lib/downloadCsvFile';
import DefaultLayout from '../../components/DefaultLayout';
import ValidationModal from './ValidationEditModal';
import BookUploadStatistics from './BulkUploadStatistics';
import errorMessage from '../../data/errorMessages';

const config = {
	filter: { visible: false },
	search: { visible: false },
	add: { visible: false },
	refresh: { visible: false },
	columns: { visible: false }
};

export default {
	name: 'BulkImport',
	components: {
		Loader,
		OfsBadge,
		OfsPanel,
		ContentHeader,
		Datatable,
		DefaultLayout,
		BookUploadStatistics,
		ValidationModal
	},
	data() {
		const $t = str => i18n.t(str);
		return {
			pollingTimerId: null,
			perPage: 10,
			currentPage: 1,
			config,
			validationEdit: null,
			selectedFilter: 'all',
			filterOptions: [
				{ text: $t('All'), value: 'all' },
				{ text: $t('Partial'), value: 'partial' },
				{ text: $t('Completed'), value: 'complete' },
				{ text: $t('Errors'), value: 'error' }
			],
			requiredLineFields: [
				'sourceProductId',
				'isbn',
				'author',
				'title',
				'spineBulk',
				'weight',
				'unitPrice',
				'specCode'
			],
			isLoading: false
		};
	},
	computed: {
		...mapGetters({
			import: 'import/import',
			accountSettings: 'account/accountSettings',
			validations: 'validation/validations',
			count: 'validation/count'
		}),
		units() {
			return _get(this.accountSettings, 'dimensionUnit', '');
		},
		fields() {
			return [
				{ label: ' ', key: 'edit' },
				{ label: this.$t('Line'), key: 'lineNumber' },
				{ label: this.$t('Product Id'), key: 'sourceProductId' },
				{ label: this.$t('Isbn'), key: 'isbn' },
				{ label: this.$t('Author'), key: 'author' },
				{ label: this.$t('Title'), key: 'title' },
				{ label: this.$t('Description'), key: 'description' },
				{ label: this.$t('Publisher'), key: 'publisher' },
				{ label: this.$t('Isbn'), key: 'isbn' },
				{ label: this.$t('Version'), key: 'version' },
				{ label: this.$t('Edition'), key: 'edition' },
				{ label: this.$t('Volume'), key: 'volume' },
				{ label: `${this.$t('Spine')} (${this.units})`, key: 'spineBulk' },
				{ label: this.$t('Weight'), key: 'weight' },
				{ label: this.$t('Price'), key: 'unitPrice' },
				{ label: this.$t('Spec'), key: 'specCode' },
				{ label: this.$t('Tags'), key: 'tags' },
				{ label: this.$t('Catalogues'), key: 'catalogueIds' },
				{ label: this.$t('Extra Data'), key: 'extraData' },
				{ label: this.$t('Status'), key: 'status' }
			];
		},
		status() {
			return _get(this.import, 'status') || '';
		},
		csvLineKeys() {
			return _get(this.import, 'fields') || [];
		},
		classes() {
			return {
				'is-loading': this.isLoading
			};
		}
	},
	async mounted() {
		try {
			this.isLoading = true;
			const bulk = await this.getImport({ id: this.$route.params.id });

			if (['discarded', 'complete', 'error'].includes(bulk.status)) {
				if (bulk.status === 'error') {
					this.$toaster.error(this.errorMessage(bulk.errorMessage), 3000);
				}

				this.$router.push({ name: 'books.upload' });
				return;
			}

			if (['pending', 'processing'].includes(bulk.status)) {
				await this.pollingBulkStats(bulk._id);
			}

			this.fetchData();
		} catch (err) {
			this.$toaster.error(this.$t("Can't find a import resource."), 3000);
			this.$router.push({ name: 'books.upload' });
		} finally {
			this.isLoading = false;
		}
	},
	destroyed() {
		clearInterval(this.pollingTimerId);
	},
	methods: {
		...mapActions({
			getImport: 'import/get',
			getValidations: 'validation/find',
			countValidations: 'validation/count',
			completeImport: 'import/update',
			discardImport: 'import/deleteById',
			downloadCsv: 'validation/downloadCsv'
		}),
		errorMessage(key) {
			return _get(errorMessage, key);
		},
		getProperty(obj, key) {
			return _get(obj, key);
		},
		onEditValidation(validation) {
			this.validationEdit = validation;
		},
		onHideModal() {
			this.validationEdit = null;
		},
		onSubmitModal() {
			this.getImport({ id: this.$route.params.id });
			this.fetchData();
		},
		csvErrorTitle(key) {
			let error = _get(this.validations, `[0].validationErrors.${key}`);

			if (!error) {
				error = `${key.charAt(0).toUpperCase() + key.slice(1)} ${this.$t('is not provided')}`;
			} else {
				error = this.errorMessage(error.code);
			}

			return error;
		},
		getPresentError(key, val) {
			if (key === 'catalogueIds') {
				const plural = value => (value.length > 1 ? 's' : '');
				let error;
				const found = (_get(val, 'found') || []).join(', ');
				const notFound = (_get(val, 'notFound') || []).join(', ');

				if (found) {
					const message = this.$t('are present in the system.');
					error = `Catalogue${plural(_get(val, 'found'))} '${found}' ${message}`;
				}

				if (notFound) {
					const message = this.$t('not found in the system.');
					const nf = `Catalogue${plural(_get(val, 'notFound'))} '${notFound}' ${message}`;
					error = error ? `${error} ${nf}` : nf;
				}

				return error;
			}

			return this.errorMessage(val.code);
		},
		async fetchData() {
			const query = {
				query: {
					importId: _get(this.import, '_id'),
					$limit: this.perPage,
					$skip: this.perPage * (this.currentPage - 1),
					$sort: { lineNumber: 1 }
				}
			};

			if (this.selectedFilter !== 'all') {
				query.query.status = this.selectedFilter;
			}
			try {
				this.isLoading = true;
				await Promise.all([await this.getValidations({ query }), await this.countValidations({ query })]);
			} catch (err) {
				this.$toaster.error(`${this.$t('Error during the request to the server')}: ${err.message}`, 3000);
			} finally {
				this.isLoading = false;
			}
		},
		handleTableChange({ currentPage, perPage }) {
			this.currentPage = currentPage;
			this.perPage = perPage;
			this.fetchData();
		},
		handleFiltersChange() {
			this.currentPage = 1;
			this.fetchData();
		},
		async onDownloadCsv() {
			try {
				const csv = await this.downloadCsv(_get(this.import, '_id'));

				if (!_isEmpty(csv)) {
					makeDownload(csv, 'invalid-lines.csv');
				} else {
					this.$toaster.info(this.$t('Import resource does not contain error lines.'), 3000);
				}
			} catch (err) {
				this.$toaster.error(this.$t("Can't download a .csv file with only invalid lines."), 3000);
			}
		},
		async pollingBulkStats(id) {
			return new Promise(resolve => {
				this.pollingTimerId = setInterval(async () => {
					let bulk;
					try {
						bulk = await this.getImport({ id });
						this.fetchData();
					} catch (err) {
						clearInterval(this.pollingTimerId);
						this.$toaster.error(this.$t("Can't get statistics."), 3000);
					}

					const status = _get(bulk, 'status');

					if (status === 'ready') {
						clearInterval(this.pollingTimerId);
						resolve(bulk);
					} else if (status === 'error') {
						clearInterval(this.pollingTimerId);
						this.$toaster.error(this.errorMessage(bulk.errorMessage), 3000);
						this.$router.push({ name: 'books.upload' });
						resolve(bulk);
					} else if (status === 'discarded') {
						clearInterval(this.pollingTimerId);
						this.$toaster.error(this.$t("The .csv file doesn't have any lines."), 3000);
						this.$router.push({ name: 'books.upload' });
						resolve(bulk);
					}
				}, 2000);
			});
		},
		async markAsComplete() {
			try {
				this.isLoading = true;
				const id = _get(this.import, '_id');
				await this.completeImport({ id });
				this.$toaster.info(this.$t('Current import has been completed.'), 3000);
				this.$router.push({ name: 'books.upload' });
			} catch (err) {
				this.$toaster.error(this.$t("Can't confirm current import."), 3000);
			} finally {
				this.isLoading = false;
			}
		},
		async discard() {
			try {
				this.isLoading = true;
				if (this.status !== 'ready') {
					clearInterval(this.pollingTimerId);
				}

				const id = _get(this.import, '_id');
				await this.discardImport({ id });
				this.$toaster.info(this.$t('Import was discarded.'), 3000);
				this.$router.push({ name: 'books.upload' });
			} catch (err) {
				this.$toaster.error(this.$t("Can't delete a books."), 3000);
			} finally {
				this.isLoading = false;
			}
		}
	}
};
</script>

<style lang="scss">
.BookUploadLines {
	margin-top: 20px;

	&-top {
		display: flex;
		justify-content: space-between;

		&Left {
			margin-bottom: -0.5rem;
		}

		.btn {
			cursor: pointer;
		}
	}

	&-table {
		margin-top: 20px;

		.Edit {
			cursor: pointer;
		}

		.IconError {
			width: 1em;
			height: 1em;
		}

		.IconStatus {
			width: 2em;
			height: 2em;
		}
	}
}

.BulkImport {
	&.is-loading {
		.OneflowAppLayout-MainContent {
			overflow: hidden;
		}
	}
	.OneflowAppLayout-MainContent {
		position: relative;
	}
	.BulkImport-loader {
		position: absolute;
		z-index: 100;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		background: rgba(#edeff3, 0.8);
	}
}
</style>
