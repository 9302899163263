<template>
	<b-form-group
		label-for="DatepickerInput"
		:horizontal="horizontal"
		:label="label"
		:description="description"
		:label-cols="labelCols"
		:label-class="labelClass"
	>
		<Datepicker
			v-model="date"
			:lang="lang"
			:clearable="clearable"
			:editable="editable"
			:disabled="disabled"
			class="DatepickerInput"
			input-class="form-control"
			:first-day-of-week="1"
			:placeholder="placeholder"
			:input-attr="{ id: 'DatepickerInput' }"
			:format="momentFormat"
			value-type="format"
		/>
	</b-form-group>
</template>

<script>
import moment from 'moment-timezone';
import get from 'lodash/get';
import includes from 'lodash/includes';
import { mapGetters } from 'vuex';
import Datepicker from 'vue2-datepicker';

export default {
	components: {
		Datepicker
	},
	props: {
		value: {
			type: String,
			default: ''
		},
		type: {
			type: String,
			default: 'date',
			validator: type => {
				const types = ['date', 'datetime', 'year', 'month', 'time'];
				return includes(types, type);
			}
		},
		placeholder: {
			type: String,
			default: ''
		},
		label: {
			type: String,
			default: ''
		},
		labelCols: {
			type: Number,
			default: 12
		},
		labelClass: {
			type: [String, Object],
			default: ''
		},
		description: {
			type: String,
			default: ''
		},
		clearable: {
			type: Boolean,
			default: true
		},
		editable: {
			type: Boolean,
			default: true
		},
		disabled: {
			type: Boolean,
			default: false
		},
		horizontal: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			momentFormat: {
				stringify: date => {
					if (!date) {
						return '';
					}
					const year = date.getFullYear();
					let month = date.getMonth() + 1;
					if (month < 10) {
						month = '0' + month;
					}
					let day = date.getDate();
					if (day < 10) {
						day = '0' + day;
					}
					return moment(`${year}-${month}-${day}`).accountFormat();
				},
				parse: value => {
					if (!value) {
						return null;
					}
					const date = moment(value);
					return new Date(`${date.year()}-${date.month() + 1}-${date.date()}`);
				}
			},
			// custom lang
			lang: {
				days: [
					this.$t('Sun'),
					this.$t('Mon'),
					this.$t('Tue'),
					this.$t('Wed'),
					this.$t('Thu'),
					this.$t('Fri'),
					this.$t('Sat')
				],
				months: [
					this.$t('Jan'),
					this.$t('Feb'),
					this.$t('Mar'),
					this.$t('Apr'),
					this.$t('May'),
					this.$t('Jun'),
					this.$t('Jul'),
					this.$t('Aug'),
					this.$t('Sep'),
					this.$t('Oct'),
					this.$t('Nov'),
					this.$t('Dec')
				],
				pickers: [
					this.$t('next 7 days'),
					this.$t('next 30 days'),
					this.$t('previous 7 days'),
					this.$t('previous 30 days')
				]
			}
		};
	},
	computed: {
		...mapGetters({
			vars: 'account/vars'
		}),
		dateFormat() {
			return get(this.vars, 'oneflowAccountSettings.dateFormat', '').toUpperCase();
		},
		date: {
			get() {
				return this.value;
			},
			set(val) {
				this.$emit('input', val ? moment(val, this.dateFormat).format() : null);
			}
		}
	}
};
</script>

<style lang="scss">
@import '~@workflow-solutions/ofs-vue-layout/dist/style/variables';

.DatepickerInput {
	width: 100%;

	.mx-input-append {
		svg,
		i {
			color: $of-color-grey-1;
		}
	}
}
</style>
