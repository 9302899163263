<template>
	<DefaultLayout>
		<ofs-panel>
			<section class="TriggerList">
				<ListTable
					:table-title="$t('Trigger List')"
					:config="config"
					bordered
					:items="triggers"
					:filter="filter"
					:current-page="currentPage"
					:fields="fields"
					:total-items="count"
					:per-page="perPage"
					:selected="selected"
					:page-position-prefix="$t('Showing')"
					:page-position-join="$t('of')"
					:active-filter-options="activeFilterOptions"
					:default-active-filter-value="filter"
					hover
					@row-clicked="handleRowClick"
					@table-change="handleTableChange"
				>
					<template slot="prev-text" slot-scope="{}">
						{{ $t('Prev') }}
					</template>
					<template slot="next-text" slot-scope="{}">
						{{ $t('Next') }}
					</template>
					<template slot="empty">
						<span>
							<i>{{ $t('No Data') }}</i>
						</span>
					</template>
					<template slot="TableHeader" slot-scope="{}">
						<OfFilterBar
							:filters="filters"
							:label="$t('Filter')"
							:reset-label="$t('Reset Filters')"
							:values="queryFilters"
							@change="filtersChanged"
						/>
					</template>

					<template slot="TableButtons-Slot-left" slot-scope="{}">
						<OfInlineFilter
							:filters="filters"
							:label="$t('Filter')"
							:reset-label="$t('Reset Filters')"
							:values="queryFilters"
							@change="filtersChanged"
						/>
					</template>

					<template #cell(updatedAt)="{ item }">
						{{ item.updatedAt | niceDate }}
					</template>
					<template #cell(event)="{ item }">
						{{ getTriggerEvent(item) }}
					</template>
				</ListTable>
			</section>
		</ofs-panel>
	</DefaultLayout>
</template>

<script>
import _get from 'lodash/get';
import { mapGetters, mapActions } from 'vuex';
import { ListTable, OfsPanel, OfInlineFilter, OfFilterBar } from '@workflow-solutions/ofs-vue-layout';
import _ from 'lodash';
import DefaultLayout from '../../components/DefaultLayout';
import { dateFormatMixin } from 'src/mixins/dateFormatMixin';
import tableMixin from '../../mixins/tableMixin';

export default {
	components: {
		DefaultLayout,
		ListTable,
		OfsPanel,
		OfInlineFilter,
		OfFilterBar
	},
	mixins: [tableMixin, dateFormatMixin()],
	data() {
		return {
			form: {},
			fields: [
				{ key: 'name', label: this.$t('Name') },
				{ key: 'event', label: this.$t('Event') },
				{ key: 'type', label: this.$t('Type') },
				{ key: 'updatedAt', label: this.$t('Date Updated') }
			],
			config: {
				filter: { visible: true },
				search: { visible: false },
				columns: { visible: false },
				add: {
					visible: true,
					href: 'triggers/add',
					title: this.$t('Add Trigger')
				},
				refresh: {
					visible: true,
					title: this.$t('Refresh')
				},
				activeFilter: {
					visible: true,
					title: this.$t('View Active/Inactive')
				}
			},
			isBusy: false,
			activeFilterOptions: [
				{ text: this.$t('Active'), value: true },
				{ text: this.$t('Inactive'), value: false }
			],
			selected: []
		};
	},
	computed: {
		...mapGetters({
			triggers: 'trigger/triggers',
			count: 'trigger/count',
			triggerSources: 'triggerSource/triggerSources'
		}),
		filters() {
			return [
				{
					header: this.$t('Event'),
					key: 'triggerSourceId',
					type: 'checkbox',
					items: this.triggerSources.map(({ name, _id }) => ({ title: name, value: _id }))
				},
				{
					header: this.$t('Type'),
					key: 'type',
					type: 'checkbox',
					items: [{ title: this.$t('HTTP'), value: 'http' }, { title: this.$t('Email'), value: 'email' }]
				}
			];
		}
	},
	mounted() {
		this.getTriggerSources();
		this.fetchData();
	},
	methods: {
		...mapActions({ getTriggers: 'trigger/find', getTriggerSources: 'triggerSource/findAll' }),
		async fetchData() {
			const query = {
				active: this.filter,
				direction: 1,
				sort: 'name',
				page: this.currentPage,
				pagesize: this.perPage,
				...this.queryFilters
			};

			try {
				this.updateUrl({ filter: this.filter, queryFilters: this.queryFilters });
				await this.getTriggers({ query });
			} catch (err) {
				this.$notify({ type: 'error', title: this.$t('An error occurred while fetching the data.') });
				throw err;
			}
		},
		filtersChanged(queryFilters) {
			this.updateUrl({
				currentPage: this.firstPage,
				perPage: this.minPerPage,
				queryFilters,
				filter: this.filter
			});
		},
		handleRowClick(trigger) {
			this.$router.push({ name: 'settings.triggers.edit', params: { id: trigger._id } });
		},
		getTriggerEvent({ triggerSourceId }) {
			const triggerSource = _.find(this.triggerSources, { _id: triggerSourceId });

			return _get(triggerSource, 'name');
		}
	}
};
</script>

<style lang="scss">
.TriggerList {
	.breadcrumb.ListTable-breadcrumb {
		display: none;
	}
}
</style>
