<template>
	<section class="OrderDetail">
		<h2 class="OrderDetail_title" v-text="$t('Order Details')" />
		<b-row class="mb-3">
			<b-col class="OrderDetail_cell">
				<of-form-input
					class="mb-0"
					name="orderData.sourceOrderId"
					data-test-id="orderDataOrderId"
					:label="$t('Order ID')"
					label-class="OrderDetail_label"
				/>
			</b-col>
		</b-row>
		<b-row class="mb-3">
			<b-col class="OrderDetail_cell OrderDetail_cell--inline">
				<of-multi-select
					class="mb-0 OrderDetail_customer"
					name="orderData.clientId"
					:label="$t('Customer')"
					required
					data-test-id="orderDataCustomer"
					label-class="OrderDetail_label"
					:options="clientOptions"
					@search-change="onClientSearch"
					:placeholder="$t('Select option')"
				/>
				<a
					v-b-tooltip.hover
					href="#"
					class="OrderDetail_add"
					data-test-id="addCustomerBtn"
					:title="$t('Add Customer')"
					position="topright"
					@click.stop="showAddClientModal"
				>
					<font-awesome-icon icon="plus-circle" />
				</a>
			</b-col>
		</b-row>
		<b-row class="mb-3">
			<b-col class="OrderDetail_cell">
				<of-form-input
					v-if="isEnteringNewProvider"
					class="mb-0"
					name="destination.name"
					data-test-id="orderDetailsDestinationPsp"
					label-class="OrderDetail_label"
					:label="$t('Destination PSP')"
					required
					:description="$t('The account identifier for the PSP you want to submit the order to')"
				/>
				<template v-else>
					<of-multi-select
						class="mb-2"
						required
						name="destination.name"
						data-test-id="orderDetailsDestinationPsp"
						:label="$t('Destination PSP')"
						:allow-clear="false"
						:options="psps"
						:placeholder="$t('Select option')"
					/>
					<p class="mb-0">
						{{ $t('Print provider not displaying?') }}
						<a href="#" @click.prevent="newProvider = true">{{ $t('Enter a new one') }}</a> {{ $t('or') }}
						<a href="#" @click.prevent="onClearPsps">{{ $t(`clear PSP's`) }}</a>
					</p>
				</template>
			</b-col>
		</b-row>
		<b-row class="mb-3">
			<b-col class="OrderDetail_cell" data-test-id="orderDataTag">
				<span class="OrderDetail_label">{{ $t('Tags') }}</span>
				<of-multi-select
					name="orderData.tags"
					no-label
					:placeholder="$t('Add custom tags')"
					:taggable="true"
					data-test-id="orderTagInputField"
				/>
			</b-col>
		</b-row>
		<b-row class="mb-3">
			<b-col class="OrderDetail_cell" data-test-id="orderDataOrderSource">
				<span class="OrderDetail_label">{{ $t('Order Source') }}</span>
				<a href="#" @click="viewSource">{{ $t('View Order Source') }}</a>
			</b-col>
		</b-row>
		<b-row>
			<b-col class="OrderDetail_cell">
				<of-form-textarea
					class="mb-0"
					label-class="OrderDetail_label"
					name="orderData.instructions"
					data-test-id="orderDataNotes"
					:label="$t('Notes')"
					:placeholder="$t('Any additional order information can be stored here')"
				/>
			</b-col>
		</b-row>
		<b-modal v-model="addClientModalVisible" size="lg" :title="$t('Add Client')" hide-footer>
			<ClientForm @on-cancel="onHideClientModal" @on-submit="onAddNewClient" />
		</b-modal>
		<code-viewer
			:code="sourceCode"
			:title="$t('Order Source')"
			:show="isSourceModalVisible"
			:on-close="closeSourceModal"
		/>
	</section>
</template>

<script>
import { OfMultiSelect, OfFormInput, OfFormTextarea } from '@workflow-solutions/ofs-vue-layout';
import { mapActions, mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import _ from 'lodash';
import { displayError } from '../../../../../lib/helpers';
import ClientForm from '../../../../Client/Form';
import CodeViewer from '../../../../../components/siteflow/Modal/CodeViewer';
import { dateFormatMixin } from 'src/mixins/dateFormatMixin';

export default {
	name: 'OrderCreateDetails',
	components: {
		OfFormInput,
		OfMultiSelect,
		OfFormTextarea,
		ClientForm,
		CodeViewer
	},
	mixins: [dateFormatMixin()],
	data() {
		return {
			newProvider: false,
			addClientModalVisible: false,
			isSourceModalVisible: false,
			formName: 'orderCreateForm'
		};
	},
	computed: {
		...mapGetters({
			clients: 'client/clients',
			psps: 'account/psps',
			getFormData: 'form/getFormData'
		}),
		formData() {
			return this.getFormData({ formName: this.formName });
		},
		validationRules() {
			return {
				formData: {
					sourceOrderId: {
						required
					}
				}
			};
		},
		isEnteringNewProvider() {
			return this.psps.length === 0 || this.newProvider;
		},
		orderData() {
			return _.get(this.formData, 'orderData');
		},
		sourceCode() {
			return JSON.stringify(this.formData, null, 2);
		},
		orderPriority() {
			return _.get(this.orderData, 'colour', 'black');
		},
		clientOptions() {
			return _.map(this.clients, client => ({
				text: client.name,
				value: client._id
			}));
		}
	},
	async mounted() {
		await this.findClients({
			query: {
				active: true
			}
		});
	},
	methods: {
		...mapActions({
			updateFormField: 'form/updateFormField',
			updateFormData: 'form/updateFormData',
			findClients: 'client/find',
			updateOrder: 'order/update',
			clearPsps: 'account/clearPsps',
			updateOrderDetails: 'order/updateOrderDetails'
		}),
		async onClientSearch(search) {
			if (search && search.text) {
				await this.findClients({
					query: {
						active: true,
						$name: search.text
					}
				});
			}
		},
		onClearPsps() {
			this.$bvModal
				.msgBoxConfirm(this.$t('Are you sure you wish to clear your saved print providers?'), {
					title: `Clear PSP's`,
					size: 'sm'
				})
				.then(okay => {
					if (okay) {
						this.clearPsps();
						this.updateFormPath('destination.name', '');
					}
				});
		},
		viewSource() {
			this.isSourceModalVisible = true;
		},
		closeSourceModal() {
			this.isSourceModalVisible = false;
		},
		updatePriority(priority) {
			this.updateFormPath('orderData.colour', priority);
		},
		updateFormPath(fieldPath, value) {
			this.updateFormField({ formName: this.formName, fieldPath, value });
		},
		showAddClientModal() {
			this.addClientModalVisible = true;
		},
		onHideClientModal() {
			this.addClientModalVisible = false;
		},
		async onAddNewClient({ _id: clientId }) {
			try {
				await this.updateFormPath('orderData.clientId', clientId);
				await this.findClients();
				this.onHideClientModal();
			} catch (err) {
				this.$notify({ type: 'error', text: displayError(err) });
			}
		}
	}
};
</script>

<style lang="scss"></style>
