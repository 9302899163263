<template>
	<tippy interactive :animate-fill="false" placement="right" animation="fade" trigger="mouseenter focus" arrow>
		<template #trigger>
			<slot />
		</template>
		<div class="Popover">
			<div>
				<b>{{ $t('Status') }}</b
				>: <ofs-badge :status="preflightStatus" :text="preflightStatus" />
			</div>

			<div v-if="component.preflightReportUrl" class="mt-2">
				<div v-if="isPreflightReportExpired">
					<i>{{ $t('Preflight annotation file has been expired, please re-run the checks.') }}</i>
				</div>
				<b-link v-else :href="component.preflightReportUrl" target="_blank">
					{{ $t('Download Annotation File') }}
				</b-link>
			</div>

			<div v-if="!['pending', 'started'].includes(status) && component.preflightReport" class="mt-2">
				<div v-for="(fixup, index) in component.preflightReport" :key="index" class="Popover-row">
					<div>
						<b>{{ $t('Name') }} </b>: <i>{{ fixup.fixup_id }}</i> - {{ fixup.display_name }}
					</div>
					<div v-if="fixup.display_comment">
						<b>{{ $t('Comment') }} </b>: {{ fixup.display_comment }}
					</div>
					<div>
						<b>{{ $t('Count') }} </b>: {{ fixup.count }}
					</div>
				</div>
			</div>
		</div>
	</tippy>
</template>

<script>
import { mapGetters } from 'vuex';
import _ from 'lodash';
import { OfsBadge } from '@workflow-solutions/ofs-vue-layout';
import { toFixed } from '../../../lib/filters';

export default {
	components: {
		OfsBadge
	},
	filters: {
		toFixed,
		range: array => _.join(array, ',')
	},
	props: {
		target: String,
		title: String,
		component: null,
		isPreflightReportExpired: Boolean,
		status: String
	},
	computed: {
		...mapGetters({
			accountSettings: 'account/accountSettings'
		}),
		units() {
			return _.get(this.accountSettings, 'dimensionUnit', '');
		},
		parsedErrors() {
			if (Array.isArray(this.errors)) {
				return this.errors.map(error => ({
					...error,
					pages: error.pages || []
				}));
			}

			return this.errors;
		},
		preflightStatus() {
			return this.component?.preflightStatus || this.$t('pending');
		}
	}
};
</script>
<style lang="scss" scoped>
.Popover {
	max-height: 80vh;
	overflow-y: auto;
	font-size: 12px;
	text-align: left;
	padding: 5px 10px;

	&-row {
		margin-bottom: 10px;

		&:last-child {
			margin-bottom: 0;
		}
	}
}
</style>
