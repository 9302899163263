<template>
	<DefaultLayout>
		<product-selector
			:show="isProductSelectorVisible"
			:on-selected="onProductSelected"
			:on-close="closeProductSelector"
		/>
		<ofs-panel v-if="isLoading">
			<Loader />
		</ofs-panel>
		<ofs-panel v-else>
			<header class="ContentHeader-header">
				<ol class="breadcrumb ContentHeader-breadcrumbs">
					<li
						v-for="breadcrumb in breadcrumbs"
						:key="breadcrumb.text"
						:class="{ 'breadcrumb-item': true, active: breadcrumb.active }"
					>
						<router-link v-if="breadcrumb.to" :to="breadcrumb.to">
							{{ breadcrumb.text }}
						</router-link>
						<span v-else aria-current="location">{{ breadcrumb.text }}</span>
					</li>
				</ol>
				<h1 class="ContentHeader-title">
					{{ pageTitle }}
					<span v-if="displaySkuName" :class="skuClasses">{{ specTemplate.siteflowSKU }}</span>
				</h1>
			</header>
			<b-form>
				<b-tabs>
					<b-tab :title="$t('Specs')">
						<b-row class="mt-3">
							<b-col lg="8">
								<b-row>
									<b-col lg="6">
										<of-form-input
											name="description"
											data-test-id="description"
											:placeholder="$t('e.g. 210 x 297 (mm) Perfect bound')"
											:label="$t('Product Description')"
											:disabled="isLive"
										/>
									</b-col>
									<b-col lg="6">
										<of-form-input
											name="sourceSpecId"
											data-test-id="sourceSpecId"
											:placeholder="$t('e.g. A4 Perfect Bound')"
											:label="$t('Source Spec ID')"
											:disabled="isLive"
										/>
									</b-col>
								</b-row>
								<b-row>
									<b-col lg="6">
										<of-multi-select
											data-test-id="format"
											:label="$t('Format')"
											name="formatId"
											:options="formatOptions"
											:allow-clear="false"
											:disabled="isLive"
										/>
									</b-col>
									<b-col lg="6">
										<div class="hs-code-tooltip">
											<icon
												id="hscode-description"
												class="hs-code-tooltip-icon input"
												name="info-circle"
											/>
											<b-tooltip target="hscode-description" triggers="hover">
												{{ hsTooltip }}
											</b-tooltip>
										</div>
										<of-multi-select
											name="tariffId"
											data-test-id="fulfilmentSpecificationHsCodes"
											:label="$t('HS Code')"
											:placeholder="$t('Select HS Code')"
											:options="tariffOptions"
											:allow-clear="false"
											:disabled="isLive"
										/>
									</b-col>
								</b-row>
								<b-row>
									<b-col lg="6">
										<of-multi-select
											data-test-id="reproduction"
											:label="$t('Reproduction Quality')"
											name="reproductionId"
											:options="reproductionOptions"
											:allow-clear="false"
											:disabled="isLive"
										/>
									</b-col>
								</b-row>
							</b-col>
						</b-row>
						<template v-if="formData.reproductionId">
							<section v-for="(component, i) in components" :key="component.code">
								<h3 class="form-header FulfilmentSpecificationComponent__header">
									{{ component.code }}
								</h3>
								<b-row>
									<b-col lg="6">
										<of-multi-select
											:data-test-id="`${component.code}-material`"
											:label="$t('Material')"
											:name="`componentsConfig[${i}].materialGradeId`"
											:options="getMaterialOptionsForComponent(component.code)"
											:allow-clear="false"
											:disabled="isLive"
										/>
									</b-col>
									<template v-for="finishingOption in component.finishingOptions">
										<b-col v-if="finishingOption.editable" :key="finishingOption.name" lg="6">
											<of-multi-select
												:data-test-id="`${component.code}-${finishingOption.name}`"
												:label="finishingOption.name"
												:name="
													`componentsConfig[${i}].finishingOptions[${getFinishingOptionIndex(
														i,
														finishingOption._id
													)}].value`
												"
												:options="finishingOptionValues(finishingOption)"
												:allow-clear="false"
												:disabled="isLive"
											/>
										</b-col>
									</template>
								</b-row>
							</section>
						</template>
					</b-tab>
					<!-- <b-tab :title="$t('Pricing')" disabled></b-tab>
					<b-tab :title="$t('Availability')" disabled></b-tab> -->
				</b-tabs>
			</b-form>
			<template slot="actions">
				<of-submit-button :disabled="isLive" @click.prevent="save">{{ $t('Save') }}</of-submit-button>
			</template>
		</ofs-panel>
	</DefaultLayout>
</template>

<script>
import _ from 'lodash';
import {
	OfsPanel,
	OfsFeatureButton,
	OfFormInput,
	OfMultiSelect,
	OfSubmitButton,
	withForm
} from '@workflow-solutions/ofs-vue-layout';
import { required, minLength, minValue } from 'vuelidate/lib/validators';
import DefaultLayout from '../../components/DefaultLayout';
import Loader from '../../components/Loader';
import { displayError, valueToInt, valueToFloat } from '../../lib/helpers';
import { featureFlagCheckMixin } from '../../mixins/featureFlagCheck';
import { mapActions, mapGetters } from 'vuex';
import ProductSelector from './ProductSelector.vue';

const formName = 'editMaterial';
const testProductSkus = ['HP_TEST_PBB'];

export default {
	components: {
		Loader,
		OfsPanel,
		DefaultLayout,
		OfFormInput,
		OfMultiSelect,
		OfSubmitButton,
		ProductSelector
	},
	mixins: [withForm(formName), featureFlagCheckMixin('piazza-fulfilment')],
	data() {
		return {
			testProductSkus,
			specTemplateName: null,
			isLoading: false,
			isProductSelectorVisible: false,
			hsTooltip: this.$t('The HS code indicates the classification of the product for tax and trading purposes.')
		};
	},
	computed: {
		...mapGetters({
			materials: 'material/materials',
			fulfilmentSpecification: 'fulfilmentSpecification/fulfilmentSpecification',
			hpSpecTemplate: 'hpSpecTemplate/hpSpecTemplate',
			hpSpecTemplates: 'hpSpecTemplate/hpSpecTemplates',
			tariffs: 'hpSpecTemplate/tariffs'
		}),
		id() {
			return this.$route.params.id;
		},
		specTemplate() {
			return this.id ? this.hpSpecTemplate : this.hpSpecTemplates[0];
		},
		pageTitle() {
			return this.id ? this.$t('Edit Specification') : this.$t('New Specification');
		},
		isLive() {
			return this.formData?.active || false;
		},
		components() {
			return this.specTemplate?.components || [];
		},
		materialGradeHash() {
			// This is used for performant lookups of materials by materialGradeId
			const hash = {};

			_.each(this.materials, m => {
				_.each(m.grades, g => {
					hash[g._id] = {
						label: `${m.name} (${g.weight} ${g.weightUnit}) ${g.grade} ${g.gradeUnit}`,
						weight: `${g.weight} ${g.weightUnit}`,
						grade: `${g.grade} ${g.gradeUnit}`
					};
				});
			});

			return hash;
		},
		formatOptions() {
			return _.map(this.specTemplate?.formats || [], r => ({
				value: r._id,
				text: r.name
			}));
		},
		reproduction() {
			return _.find(this.specTemplate?.reproductions || [], {
				_id: this.formData?.reproductionId
			});
		},
		reproductionOptions() {
			return _.map(this.specTemplate?.reproductions || [], r => ({
				value: r._id,
				text: r.name
			}));
		},
		breadcrumbs() {
			return [
				{
					text: this.$t('HP Specs'),
					to: {
						name: 'fulfilment-specification.list'
					}
				},
				{
					text: this.pageTitle,
					active: true
				}
			];
		},
		validationRules() {
			let rules = {
				formData: {
					sourceSpecId: {
						required
					},
					hpSpecTemplateId: {
						required
					},
					productType: {
						required
					},
					reproductionId: {
						required
					},
					formatId: {
						required
					},
					componentsConfig: {
						required,
						minLength: minLength(1),
						$each: {
							materialGradeId: {
								required
							},
							code: {
								required
							}
						}
					}
				}
			};

			return rules;
		},
		tariffOptions() {
			return (this.tariffs || []).map(t => ({
				text: t.name,
				value: t._id
			}));
		},
		displaySkuName() {
			return (
				(this.id && this.specTemplate?.siteflowSKU) || (this.specTemplateName && this.specTemplate?.siteflowSKU)
			);
		},
		skuClasses() {
			return this.testProductSkus.includes(this.specTemplate?.siteflowSKU)
				? ['SiteflowSku', 'SiteflowSku__test']
				: ['SiteflowSku'];
		}
	},
	watch: {
		$route: 'fetchData',
		id: 'fetchData',
		fulfilmentSpecification: {
			immediate: true,
			handler(value) {
				// If the fulfilmentSpecification updates, update the form data
				if (this.id) {
					this.setFormData(value);
				}
			}
		},
		specTemplateName: {
			async handler(name) {
				if (!name) return;
				try {
					await this.findSpecTemplate({
						query: {
							query: {
								name
							}
						}
					});
					if (this.specTemplate) this.generateSpecification();
				} catch (err) {
					this.$notify({ type: 'error', text: displayError(err) });
				} finally {
					if (!this.specTemplate) {
						// eslint-disable-next-line prettier/prettier, max-len
						this.$notify({ type: 'error', text: this.$t('There was a problem creating a specification for this product. Please contact HP Support.') });
						this.$router.push({ name: 'fulfilment-specification.list' });
					}
				}
			}
		},
		specTemplate: {
			async handler(specTemplate) {
				await this.findTariffs({ id: specTemplate._id });
			}
		}
	},
	mounted() {
		this.fetchData();
	},
	methods: {
		...mapActions({
			updateFormField: 'form/updateFormField',
			findMaterials: 'material/find',
			findFulfulmentSpecification: 'fulfilmentSpecification/findById',
			findSpecTemplateById: 'hpSpecTemplate/findById',
			findSpecTemplate: 'hpSpecTemplate/find',
			updateFulfulmentSpecification: 'fulfilmentSpecification/update',
			createFulfulmentSpecification: 'fulfilmentSpecification/create',
			findTariffs: 'hpSpecTemplate/findTariffs'
		}),
		async fetchData() {
			this.isLoading = true;
			try {
				const maxItemsQuery = {
					query: {
						query: {
							$limit: 1000,
							$sort: { name: 1 }
						}
					}
				};

				// Load Material Options
				await this.findMaterials(maxItemsQuery);

				if (this.id) {
					await this.findFulfulmentSpecification({ id: this.id });
					await this.findSpecTemplateById({
						id: this.fulfilmentSpecification?.hpSpecTemplateId
					});
				} else {
					// If a new fulfilmentSpecification

					// Show product selector
					this.isProductSelectorVisible = true;

					// initialise the form
					this.initFormData({});
				}
			} catch (err) {
				this.$notify({ type: 'error', text: this.$t('Unable to load HP Spec') });
				this.$router.push({ name: 'fulfilment-specification.list' });
			} finally {
				this.isLoading = false;
			}
		},
		async save() {
			try {
				if (this.id) {
					await this.updateFulfulmentSpecification({
						id: this.id,
						data: this.formData
					});
					this.$notify({ type: 'success', text: this.$t('HP Spec updated successfully') });
				} else {
					await this.createFulfulmentSpecification(this.formData);
					this.$notify({ type: 'success', text: this.$t('HP Spec created successfully') });
				}
				this.$router.push({ name: 'fulfilment-specification.list' });
			} catch (err) {
				this.$notify({ type: 'error', text: displayError(err) });
			}
		},
		generateSpecification() {
			const specification = {
				sourceSpecId: null,
				productType: this.specTemplate.productType,
				hpSpecTemplateId: this.specTemplate._id,
				formatId: null,
				reproductionId: null,
				componentsConfig: []
			};

			// Setup Components
			_.each(this.specTemplate.components, c => {
				const component = {
					code: c.code,
					materialGradeId: null,
					finishingOptions: []
				};

				// Setup Component Finishing Options
				_.each(c.finishingOptions, fo => {
					if (fo.editable) {
						component.finishingOptions.push({
							finishingOptionId: fo._id,
							value: fo.defaultValue
						});
					} else {
						component.finishingOptions.push({
							finishingOptionId: fo._id
						});
					}
				});
				specification.componentsConfig.push(component);
			});

			// Update Form Data
			this.setFormData(specification);
		},
		getFinishingOptionIndex(componentIndex, finishingOption) {
			return _.findIndex(
				this.formData.componentsConfig[componentIndex].finishingOptions,
				fo => fo.finishingOptionId === finishingOption
			);
		},
		finishingOptionValues(finishingOption) {
			return _.map(finishingOption.enum, fo => ({
				text: fo,
				value: fo
			}));
		},
		getMaterialGrade(materialGradeId) {
			return this.materialGradeHash[materialGradeId];
		},
		getMaterialOptionsForComponent(code) {
			// Get material options for component
			const materialOptions = _.find(this.reproduction?.components, { code })?.materialOptions ?? [];

			// Map to material grades and sort ascending
			return _.sortBy(
				_.map(materialOptions, mo => {
					const materialGrade = this.getMaterialGrade(mo.materialGradeId);

					// Check corresponding material grade exists
					if (materialGrade) {
						return {
							text: materialGrade.label,
							value: mo.materialGradeId
						};
					}
				}),
				'text'
			);
		},
		onProductSelected(product) {
			this.specTemplateName = product;
			this.closeProductSelector();
		},
		selectProduct() {
			this.isProductSelectorVisible = true;
		},
		closeProductSelector() {
			if (!this.specTemplateName) {
				this.$router.push({ name: 'fulfilment-specification.list' });
			}
			this.isProductSelectorVisible = false;
		}
	}
};
</script>

<style lang="scss">
@import '~@workflow-solutions/ofs-vue-layout/dist/style/variables';
.hs-code-tooltip {
	position: relative;
	.hs-code-tooltip-icon {
		color: $of-color-grey-2;
		position: absolute;
		top: 8px;
		left: 50px;
		height: 13px;
		width: 13px;
		z-index: 5;
	}
}

.FulfilmentSpecificationComponent {
	&__header {
		text-transform: capitalize;
		padding: 7px 10px;
		margin: 20px 0;
		background: $of-color-highlights;
		border-radius: $of-border-radius;
	}
}

.SiteflowSku {
	background: #e3e9f2;
	color: #506480;
	margin-left: 10px;
	padding: 5px 10px;
	border-radius: 5px;
	font-weight: bold;
	font-size: 13px;
	&__test {
		background: #fce6f6;
		color: #e008a7;
	}
}
</style>
