<template>
	<DefaultLayout v-if="member" id="app-content">
		<ofs-panel>
			<ContentHeader v-if="member && member.user" class="mb-3" :no-padding="true" :title="member.user.email">
				<b-button
					v-if="isMyself"
					variant="danger"
					:disabled="isResetPasswordBtnDisabled"
					@click="handleResetPasswordBtnClick"
				>
					{{ $t('Reset password') }}
				</b-button>
			</ContentHeader>
			<b-container fluid class="px-0">
				<b-row>
					<b-col>
						<b-card :header="$t('Details')">
							<p>
								<strong>{{ $t('ID') }}:</strong> {{ member && member.userId }}
							</p>
							<p>
								<strong>{{ $t('Role') }}:</strong> {{ member && member.role | roleName }}
							</p>
							<p>
								<strong>{{ $t('Created') }}:</strong> {{ member && formatDate(member.created) }}
							</p>
						</b-card>
					</b-col>
					<b-col v-if="showCredentials" class="pl-0">
						<b-card :header="$t('API Token/Secret')">
							<b-row class="m-0 mb-3 justify-content-between align-items-center">
								<section class="d-flex">
									<div class="d-flex flex-column mr-3">
										<b>{{ $t('Token') }}</b>
										<b>{{ $t('Secret') }}</b>
									</div>
									<div>
										<div>{{ currentRole && currentRole.token }}</div>
										<div>{{ currentRole && currentRole.secret }}</div>
									</div>
								</section>
							</b-row>
							<template #footer>
								<section class="d-flex justify-content-end align-items-center">
									<b-button
										v-if="isMyself"
										v-b-tooltip.hover
										variant="primary"
										:title="$t('Generate new API token/secret')"
										@click.prevent="onGenerateToken"
									>
										{{ $t('Generate new') }}
									</b-button>
								</section>
							</template>
						</b-card>
					</b-col>
				</b-row>
			</b-container>
			<ConfirmModal v-if="!!confirmModal" :options="confirmModal" :cancel="cancelConfirmModal" />
		</ofs-panel>
	</DefaultLayout>
</template>

<script type="text/javascript">
import { mapActions, mapGetters } from 'vuex';
import { ContentHeader, OfsPanel } from '@workflow-solutions/ofs-vue-layout';
import get from 'lodash/get';
import { i18n } from 'src/vuex';
import DefaultLayout from '../../components/DefaultLayout';
import ConfirmModal from '../../components/modals/ConfirmModal';
import { dateFormatMixin } from 'src/mixins/dateFormatMixin';

export default {
	components: {
		DefaultLayout,
		OfsPanel,
		ContentHeader,
		ConfirmModal
	},
	mixins: [dateFormatMixin()],
	filters: {
		roleName(role) {
			const $t = str => i18n.t(str);
			switch (role) {
				case 'customer':
					return $t('Customer');
				case 'user':
					return $t('Manager');
				case 'admin':
					return $t('Admin');
				default:
					return '-';
			}
		}
	},
	data() {
		return {
			confirmModal: null,
			isResetPasswordBtnDisabled: false,
			currentRole: null,
			userMember: null
		};
	},
	computed: {
		...mapGetters('account', ['currentAccount', 'member']),
		...mapGetters('auth', ['user', 'userTokenData']),
		isMyself() {
			if (!(this.user || this.member)) {
				return false;
			}

			return this.user._id === this.member.user._id;
		},
		accountRoles() {
			return get(this.userTokenData, 'accountRoles', []);
		},
		isAdmin() {
			return this.userMember ? this.userMember.role === 'admin' : false;
		},
		showCredentials() {
			return (this.isMyself || this.isAdmin) && !_.isEmpty(this.currentRole);
		}
	},
	watch: {
		'$route.params.id': () => {
			this.fetchData();
		}
	},
	async mounted() {
		if (!this.user) {
			await this.getUser();
		}

		this.fetchData();
	},
	methods: {
		...mapActions('account', ['getMember']),
		...mapActions('auth', ['getUser', 'resetPassword', 'getUserTokens', 'updateUserAccountRole']),
		...mapActions('user', ['getUserAccountRole']),
		async fetchData() {
			await this.fetchMemberData();

			if (this.isMyself) {
				await this.getUserTokens();
				this.currentRole = this.accountRoles.find(
					role => !role.expirationDate && role.id === this.currentAccount._id
				);
				return;
			}

			await this.getCurrentUserMemberData();
			await this.getMemberAccountRole();
		},
		fetchMemberData() {
			const userId = this.$route.params.id;
			if (userId) {
				return this.getMember({ userId });
			}

			return this.getMember({ userId: this.user._id });
		},
		async getCurrentUserMemberData() {
			this.userMember = await this.getMember({ userId: this.user._id, skipMutations: true });
		},
		async getMemberAccountRole() {
			const userId = this.$route.params.id;
			if (!this.isAdmin || !userId) return;
			this.currentRole = await this.getUserAccountRole(userId);
		},
		async handleResetPasswordBtnClick() {
			await this.resetPassword(this.user.email);
			this.$toaster.info(this.$t('Password reset email has been sent'));
			this.isResetPasswordBtnDisabled = true;
		},
		onGenerateToken() {
			const currentRoleIndex = this.accountRoles.findIndex(role => role.id === this.currentAccount._id);
			if (!this.currentRole || currentRoleIndex === -1) {
				return;
			}

			this.openConfirmModal({
				title: this.$t('Generate'),
				text: this.$t('Are you sure you want to generate new API token/secret?'),
				type: 'info',
				showCancelButton: true,
				confirmType: 'danger',
				confirmText: this.$t('Yes'),
				closeOnConfirm: true,
				success: async () => {
					try {
						const newRole = await this.updateUserAccountRole(this.currentRole?._id);
						this.$toaster.success(this.$t('New API token/secret successfully generated'), {
							timeout: 3000
						});
						this.$toaster.info(this.$t('Previous API token/secret will be removed after 3 days'), 3000);
						this.currentRole = newRole;
						this.getUserTokens();
					} catch (err) {
						this.$toaster.error(this.$t('Error during generation API token/secret'), { timeout: 3000 });
					}
				}
			});
		},
		openConfirmModal(options) {
			this.confirmModal = options;
		},
		cancelConfirmModal() {
			this.confirmModal = null;
		}
	}
};
</script>
