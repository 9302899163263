<template>
	<DefaultLayout>
		<b-form novalidate class="form-horizontal" @submit.prevent="onSave">
			<ofs-panel>
				<ContentHeader class="mb-3" :no-padding="true" :title="formTitle">
					<b-button v-t="'Test'" :disabled="formType !== 'Edit'" variant="secondary" @click="openModal" />
				</ContentHeader>
				<section class="TriggerCreate">
					<of-toggle name="active" :label="`${$t('Active')}:`" />
					<of-form-input
						name="name"
						type="text"
						:label="`${$t('Name')}:`"
						:placeholder="$t('Name')"
						required
					/>
					<of-form-select
						name="triggerSourceId"
						:label="`${$t('Event')}:`"
						:options="triggerEventsOptions"
						required
					/>
					<!-- TODO: Find out why it's not re-rendering when type is changed -->
					<of-form-select name="type" :label="`${$t('Type')}:`" :options="dropdownTypes" required />

					<of-form-select
						name="templateId"
						:label="`${$t('Template')}:`"
						:options="triggerTemplatesOptions"
						@input="updateEditorText"
					/>

					<!--
						// todo: check with siteflow-team if it's needed:
						<of-toggle name="savePostback" :label="`${$t('Save Postback To Order')}:`" />
					-->
					<of-toggle name="retry" :label="`${$t('Retry on failure')}:`" />
					<of-toggle name="share" :label="`${$t('Share')}:`" />

					<div v-if="formData && formData.type === 'email'" class="emailContainer">
						<h3>{{ $t('Email Request') }}</h3>
						<of-form-input
							name="address"
							type="text"
							:label="`${$t('Address')}:`"
							:placeholder="$t('Address')"
							required
						/>
					</div>

					<div v-if="formData && formData.type === 'http'" class="httpContainer">
						<h3>{{ $t('HTTP/HTTPS Request') }}</h3>
						<of-form-input
							name="address"
							data-test-id="address"
							:label="$t('Address')"
							:placeholder="$t('URL')"
							:show-errors="true"
							required
						/>
						<p v-if="isHttp" class="pending-info">
							{{
								$t(`It is strongly recommended that you use a secure HTTPS endpoint. This will ensure that
								any information sent to the endpoint is securely encrypted.`)
							}}
						</p>
						<of-form-select name="method" :label="$t('Method')" :options="dropdownMethods" required />
						<b-row class="align-items-center">
							<b-col cols="5">
								<of-form-input
									name="requestHeaderHeader"
									type="text"
									:label="$t('Header')"
									:placeholder="$t('Header')"
								/>
							</b-col>
							<b-col cols="5">
								<of-form-input
									name="requestHeaderValue"
									type="text"
									:label="$t('Value')"
									:placeholder="$t('Value')"
								/>
							</b-col>
							<b-col cols="2" class="d-flex justify-content-center pt-2">
								<div class="TriggerAddEdit-add" @click="addRequestHeader">
									<icon name="plus-circle" class="text-info" />
								</div>
							</b-col>
						</b-row>
						<b-row
							v-for="(header, index) in Object.keys(formData.headers || {})"
							:key="index"
							class="align-items-center mb-3"
						>
							<b-col cols="5">
								<b-form-input
									type="text"
									data-test-id="addedHttpHeader"
									:value="header"
									:label="$t('Header')"
									:placeholder="$t('Header')"
									disabled
								/>
							</b-col>
							<b-col cols="5">
								<b-form-input
									type="text"
									data-test-id="addedHttpValue"
									:value="formData.headers[header]"
									:label="$t('Value')"
									:placeholder="$t('Value')"
									disabled
								/>
							</b-col>
							<b-col cols="2" class="d-flex justify-content-center">
								<div
									class="TriggerAddEdit-remove"
									data-test-id="removeHttpRequest"
									@click="removeRequestHeader(header)"
								>
									<icon name="times-circle" />
								</div>
							</b-col>
						</b-row>
					</div>
				</section>

				<template #actions>
					<of-submit-button v-t="'Save'" />
				</template>

				<b-modal v-model="showModal" size="lg" :busy="true">
					<template #modal-title>
						<div v-t="'Test Trigger'" />
					</template>
					<!-- eslint-disable-next-line -->
					<p v-t="'The following template will be sent. Feel free to edit the data in the handlebars before sending the test trigger.'" />
					<of-form-select
						name="templateId"
						:label="`${$t('Template')}:`"
						:options="triggerTemplatesOptions"
						@input="updateEditorText"
					>
					</of-form-select>

					<div class="TriggerCreate-modal">
						<editor
							v-model="templateText"
							:lang="editorLang"
							theme="chrome"
							width="100%"
							height="400px"
							@input="onCodeChange"
						/>
					</div>

					<template #modal-footer>
						<div>
							<b-button v-t="'Cancel'" variant="secondary" class="mr-1" @click="showModal = false" />
							<b-button v-t="'Run Test'" variant="primary" @click="runTestTrigger" />
						</div>
					</template>
				</b-modal>

				<confirm-modal v-if="!!triggerModal" :options="triggerModal" :cancel="closeTriggerModal">
				</confirm-modal>
				<b-modal
					v-model="showModalWarning"
					size="md"
					:title="$t('Insecure HTTP Address')"
					:ok-title="$t('Save Anyway')"
					@ok="onSave(null, true)"
				>
					<p>
						{{
							$t(`The URL you have specified for this trigger is not using the HTTPS protocol.
							 Information sent via this trigger will not be encrypted.`)
						}}
					</p>
					<p class="mb-0">
						{{
							$t(`We strongly recommend that you use a secure endpoint for this trigger, this will ensure that
							any sensitive data sent by the trigger is encrypted.`)
						}}
					</p>
				</b-modal>
			</ofs-panel>
		</b-form>
	</DefaultLayout>
</template>

<script>
import Editor from '../../lib/aceEditor';
import { mapActions, mapGetters } from 'vuex';
import _set from 'lodash/fp/set';
import _get from 'lodash/get';
import _omit from 'lodash/fp/omit';
import _isObject from 'lodash/isObject';
import {
	ContentHeader,
	OfFormInput,
	OfFormSelect,
	OfsPanel,
	OfSubmitButton,
	OfToggle,
	withForm
} from '@workflow-solutions/ofs-vue-layout';
import { email, required, url } from 'vuelidate/lib/validators';
import DefaultLayout from '../../components/DefaultLayout';
import ConfirmModal from '../../components/modals/ConfirmModal';

export default {
	name: 'TriggerEdit',
	components: {
		DefaultLayout,
		ContentHeader,
		OfFormInput,
		OfFormSelect,
		OfSubmitButton,
		OfToggle,
		OfsPanel,
		ConfirmModal,
		Editor
	},
	mixins: [withForm('triggerForm')],
	data() {
		return {
			form: {},
			dropdownTypes: [
				{ text: this.$t('HTTPS'), value: 'http' },
				{ text: this.$t('email'), value: 'email' }
			],
			dropdownMethods: [
				{ text: 'POST', value: 'POST' },
				{ text: 'PUT', value: 'PUT' },
				{ text: 'GET', value: 'GET' }
			],
			requestHeaders: [],
			dropdownAccounts: [],
			showModal: false,
			selectedAccount: '',
			options: {},
			formType: '',
			triggerModal: null,
			templateText: '',
			editorLang: 'plain_text',
			showModalWarning: false
		};
	},
	computed: {
		...mapGetters({
			trigger: 'trigger/trigger',
			accounts: 'account/accounts',
			triggerEvents: 'triggerSource/triggerSources',
			triggerTemplates: 'trigger/triggerTemplates',
			accountSettings: 'account/accountSettings',
			isFeatureFlagActive: 'featureToggle/isActive'
		}),
		formTitle() {
			return this.formType === 'Edit' ? this.$t('Edit Trigger') : this.$t('Create Trigger');
		},
		triggerEventsOptions() {
			// All events except 'Component Printed' per Barney's request.
			// If account is not a psp, psp-specific events should be hidden
			return (
				this.triggerEvents.filter(
					ev =>
						ev.exchange !== 'oneflow.component.printed' &&
						(this.accountSettings && this.accountSettings.psp ? true : ev.resource !== 'subbatch')
				) || []
			).map(te => ({
				text: te.name,
				value: te._id
			}));
		},
		triggerTemplatesOptions() {
			return (this.triggerTemplates || []).map(tt => ({ text: tt.name, value: tt._id }));
		},
		activeTemplate() {
			if (!this.trigger) return {};
			return (this.triggerTemplates || []).find(item => item._id === this.formData.templateId);
		},
		validationRules() {
			const type = _get(this, 'formData.type');

			if (type === 'http') {
				return {
					formData: {
						address: {
							url,
							required
						}
					}
				};
			} else if (type === 'email') {
				return {
					formData: {
						address: {
							email,
							required
						}
					}
				};
			}
			return false;
		},
		systems() {
			const systems = [];
			if (!this.$HP_MODE) {
				systems.push('siteflow');
			} else {
				if (this.isFeatureFlagActive('piazza-legacy')) {
					systems.push('piazza');
				}
				if (this.isFeatureFlagActive('piazza-fulfilment')) {
					systems.push('fulfilment');
				}
			};
			return systems;
		},
		isHttp() {
			const type = _.get(this.formData, 'type');
			const address = _.get(this.formData, 'address');
			return type === 'http' && /^http:/.test(address);
		}
	},
	watch: {
		trigger() {
			this.setFormData(this.trigger);
		},
		$route: {
			deep: true,
			immediate: true,
			handler() {
				this.initForm();
			}
		},
		activeTemplate: {
			immediate: true,
			handler() {
				const { text = '', type = 'plain_text' } = this.activeTemplate || {};
				this.templateText = this.stringify(text);
				this.editorLang = type;
			}
		},
		systems: {
			immediate: true,
			handler() {
				if (this.systems.length > 0) this.initForm();
			}
		}
	},
	created() {
		this.editorInit();
	},
	methods: {
		...mapActions({
			getTrigger: 'trigger/get',
			testTrigger: 'trigger/testTrigger',
			updateTrigger: 'trigger/update',
			createTrigger: 'trigger/create',
			getTriggerSources: 'triggerSource/findAll',
			getTemplates: 'trigger/getTemplates',
			getAccount: 'account/getAccount'
		}),
		async editorInit() {
			await Promise.all([
				import('brace/mode/json'),
				import('brace/theme/chrome'),
				import('brace/mode/html'),
				import('brace/mode/xml'),
				import('brace/mode/svg'),
				import('brace/mode/plain_text')
			]);
		},
		stringify(object) {
			if (_isObject(object)) {
				return JSON.stringify(object, null, 4);
			}

			return object;
		},
		addRequestHeader() {
			const { requestHeaderHeader, requestHeaderValue } = this.formData;
			if (requestHeaderHeader && requestHeaderValue) {
				this.updateFormData({
					headers: _set(requestHeaderHeader, requestHeaderValue, this.formData.headers),
					requestHeaderHeader: '',
					requestHeaderValue: ''
				});
			}
		},
		removeRequestHeader(propName) {
			this.updateFormData({
				headers: _omit(propName, this.formData.headers)
			});
		},
		async runTestTrigger() {
			if (!this.formData.templateId) {
				this.$toaster.error(this.$t('Please select a template before testing your trigger.'), 3000);
				return;
			}

			this.openTriggerModal({
				title: this.$t('Test trigger?'),
				text: this.$t('Are you sure you want to test this trigger?'),
				type: 'info',
				showCancelButton: true,
				confirmType: 'primary',
				confirmText: this.$t('Yes'),
				closeOnConfirm: true,
				success: async () => {
					this.showModal = false;
					await this.testTrigger({ trigger: this.formData, template: this.activeTemplate });
					this.$toaster.success(this.$t('Trigger test has been sent'), { timeout: 3000 });
				}
			});
		},
		openTriggerModal(options) {
			this.triggerModal = options;
		},
		closeTriggerModal() {
			this.triggerModal = null;
		},
		async onSave(event, forceSave) {
			const type = _.get(this.formData, 'type');
			const address = _.get(this.formData, 'address');
			this.showModalWarning = type === 'http' && /^http:/.test(address);
			if (!forceSave && this.showModalWarning) return;
			if (this.$route.name === 'settings.triggers.edit') {
				await this.updateTrigger({
					id: this.$route.params.id,
					data: this.formData
				});
			} else {
				await this.createTrigger(this.formData);
			}

			this.$toaster.success(this.$t('Trigger has been updated'), { timeout: 3000 });

			this.$router.push({ name: 'settings.triggers' });
		},
		openModal() {
			this.showModal = true;
			this.updateEditorText();
		},
		async updateEditorText() {
			if (this.editor) {
				await this.$nextTick();
				this.editor.setValue(this.templateText || '');
			}
		},
		async onCodeChange() {
			if (this.activeTemplate) {
				this.activeTemplate.text = this.templateText;
			}
		},
		async initForm() {
			const systemsQuery = this.systems?.reduce(
				(acc, system, index) => ({
					...acc,
					[`systems[$in][${index}]`]: system
				}),
				{}
			);
			this.getTriggerSources({ query: { ...systemsQuery } });
			this.getTemplates({
				query: {
					active: true
				}
			});

			if (this.$route.name === 'settings.triggers.edit') {
				this.formType = 'Edit';
				await this.getTrigger({ id: this.$route.params.id });
				this.setFormData(this.trigger);
			} else {
				this.formType = 'Create';
				await this.$nextTick();
				this.setFormData({ active: true });
			}
		}
	}
};
</script>

<style lang="scss">
.TriggerCreate {
	&-Table {
		fieldset {
			margin-bottom: 0;
		}
		.form-row {
			.col-sm-3 {
				display: none;
			}
		}
		&-Button {
			position: relative;
			width: 32px;
			height: 32px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			svg {
				position: absolute;
			}
		}
	}
	span.custom-control-indicator {
		display: none; // Temp fix for hover effect on slider-checkbox
	}
	&-modal {
		.editor {
			height: 400px;
		}
	}
}
.pending-info {
	padding: 10px 15px;
	background: #fef3ce;
	color: rgba(0, 0, 0, 0.7);
	border: 1px solid #cbc5af;
	border-radius: 5px;
}
</style>
