<template>
	<DefaultLayout class="SubmissionErrorView">
		<SmartLoader :fetch-data="fetchData">
			<OfsPanel class="mx-3 mb-3">
				<ContentHeader :title="$t('Order Submission Error')" no-padding class="mb-3" />
				<b-row class="SubmissionErrorView-row">
					<b-col class="SubmissionErrorView-details">
						<b-table :fields="detailsFields" :items="[submissionError || {}]" stacked striped>
							<template #cell(createdAt)="{}">
								<span>{{ createdAt | dateTimeFormat }}</span>
							</template>
							<template #cell(resubmissionTimestamp)="{}">
								<span>{{ timestamp }}</span>
							</template>
						</b-table>
					</b-col>
					<b-col class="SubmissionErrorView-details">
						<div class="SubmissionErrorView-details-heading Title">
							{{ $t('Errors') }}
						</div>
						<b-table :fields="errorsFields" :items="errorList" striped />
					</b-col>
				</b-row>
				<b-row class="SubmissionErrorView-row">
					<b-col>
						<accordion :title="$t('Show details')" data-test-id="showDetails">
							<codemirror :value="errorListString" :options="codeMirrorReadOnlyOptions" />
						</accordion>
					</b-col>
				</b-row>
				<b-row class="SubmissionErrorView-row">
					<b-col>
						<accordion :title="$t('Postbacks')" data-test-id="postbacks">
							<b-table
								class="SubmissionErrorView-pastbacksTable"
								:fields="postbacksFields"
								:items="notifications || []"
							>
								<template #cell(open)="data">
									<font-awesome-icon
										class="SubmissionErrorView-icon"
										:icon="data.detailsShowing ? 'caret-down' : 'caret-right'"
										@click="data.toggleDetails"
									/>
								</template>
								<template #cell(timestamp)="data">
									<span>{{ data.item.timestamp | dateTimeFormat }}</span>
								</template>
								<template #cell(statusCode)="{ item }">
									<span>{{ item && item.type !== 'email' ? getPostbackStatusCode(item) : '-' }}</span>
								</template>
								<template #cell(notes)="data">
									<span>{{ data.index ? $t('Resent') : '-' }}</span>
								</template>
								<template #cell(status)="data">
									<ofs-badge :status="getStatusField(data.item)" :text="getStatusField(data.item)" />
								</template>
								<template #row-details="data">
									<div class="SubmissionErrorView-table">
										<table class="table b-table b-table-stacked table-striped" role="table">
											<tbody>
												<tr>
													<td :data-label="$t('Payload')">
														<b-button
															v-if="getPayload(data.item)"
															v-t="'View Payload'"
															variant="link"
															@click="onShowCode($t('Payload'), getPayload(data.item))"
														></b-button>
														<div v-else>-</div>
													</td>
													<td :data-label="$t('Result')">
														<b-button
															v-if="getPostbackResult(data.item)"
															v-t="'View Result'"
															variant="link"
															@click="
																onShowCode($t('Result'), getPostbackResult(data.item))
															"
														></b-button>
														<div v-else>-</div>
													</td>
													<td :data-label="$t('Status')">
														<div>
															<ofs-badge
																:status="getStatusField(data.item)"
																:text="getStatusField(data.item)"
															/>
														</div>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</template>
							</b-table>
						</accordion>
					</b-col>
				</b-row>
				<b-row class="SubmissionErrorView-row">
					<b-col class="Title">
						{{ $t('Data submitted') }}
					</b-col>
				</b-row>
				<b-row class="SubmissionErrorView-row">
					<b-col>
						<section v-if="editting">
							<form name="orderSubmissionForm">
								<codemirror v-model="orderBody" :options="codeMirrorEditedOptions" />
								<div class="mt-3 text-right">
									<b-button variant="secondary" class="mr-2" @click="discardEdition">
										{{ $t('Discard changes') }}
									</b-button>
									<b-button variant="primary" :disabled="!credentials" @click="confirmSubmission">
										{{ $t('Submit order') }}
									</b-button>
								</div>
							</form>
						</section>
						<section v-else>
							<div class="mb-3">
								<b-button
									v-clipboard:copy="body"
									v-clipboard:success="onOrderCopied"
									v-clipboard:error="onOrderCopiedError"
									size="sm"
									class="mr-2"
									data-test-id="copyOrderContent"
									variant="secondary"
									clipboard
								>
									{{ $t('Copy order content') }}
								</b-button>
								<a
									v-if="bodyUrl"
									v-t="'Download order'"
									class="btn btn-secondary btn-sm mr-2"
									data-test-id="downloadOrder"
									:href="bodyUrl"
									@click.prevent="downloadFile"
								/>
								<b-button
									size="sm"
									variant="primary"
									data-test-id="retrySubmission"
									@click="enableEditMode"
								>
									{{ $t('Retry submission') }}
								</b-button>
							</div>
							<codemirror :value="body" :options="codeMirrorReadOnlyOptions" />
						</section>
					</b-col>
				</b-row>
			</OfsPanel>
		</SmartLoader>
		<code-viewer
			:on-close="onModalClose"
			:show="codeModal.visible"
			:title="codeModal.title"
			:code="codeModal.data"
			:content-type="codeModal.contentType"
		/>
	</DefaultLayout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import _ from 'lodash';
import { Accordion, ContentHeader, OfsPanel, OfsBadge } from '@workflow-solutions/ofs-vue-layout';
import DefaultLayout from '../../../components/DefaultLayout';
import SmartLoader from '../../../components/SmartLoader';
import CodeViewer from '../../../components/siteflow/Modal/CodeViewer';
import { $t } from '../../../vuex';
import { dateTimeFormat } from '../../../lib/filters';
import { displayError } from '../../../lib/helpers';
import { featureFlagCheckMixin } from '../../../mixins/featureFlagCheck';

export default {
	components: {
		CodeViewer,
		Accordion,
		DefaultLayout,
		SmartLoader,
		ContentHeader,
		OfsPanel,
		OfsBadge
	},
	filters: {
		dateTimeFormat
	},
	mixins: [featureFlagCheckMixin('piazza-fulfilment')],
	data() {
		return {
			postbacksFields: [
				{ key: 'open', label: '' },
				{ key: 'additionalInfo.category', label: $t('Event') },
				{ key: 'timestamp', label: $t('Date Time') },
				{ key: 'type', label: $t('Method') },
				{ key: 'statusCode', label: $t('Status Code') },
				{ key: 'notes', label: $t('Notes') },
				{ key: 'status', label: $t('Status') }
			],
			detailsFields: [
				{ key: 'orderId', label: $t('Order ID') },
				{ key: 'createdAt', label: $t('Timestamp') },
				{ key: 'resubmissionTimestamp', label: $t('Resubmission Timestamp') }
			],
			errorsFields: [
				{
					key: 'name',
					label: $t('Name')
				},
				{
					key: 'description',
					label: $t('Message'),
					class: 'align-items-start'
				}
			],
			codeMirrorReadOnlyOptions: {
				tabSize: 4,
				readOnly: true,
				mode: { name: 'javascript', json: true },
				theme: 'rubyblue',
				lineNumbers: true,
				line: true
			},
			editting: false,
			body: '',
			orderBody: '',
			credentials: null,
			codeModal: {
				visible: false,
				data: '',
				onClose: this.onModalClose
			}
		};
	},
	computed: {
		...mapGetters({
			notifications: 'notification/notifications',
			vars: 'account/vars',
			submissionError: 'fulfilmentSubmissionError/fulfilmentSubmissionError',
			accountRoles: 'user/userAccountRoles'
		}),
		orderId() {
			return _.get(this.submissionError, 'orderId');
		},
		createdAt() {
			return _.get(this.submissionError, 'createdAt');
		},
		accountId() {
			return _.get(this.submissionError, 'accountId');
		},
		errorList() {
			return _.get(this.submissionError, 'errorList', []);
		},
		bodyUrl() {
			return _.get(this.submissionError, 'bodyUrl');
		},
		timestamp() {
			const resubmittedAt = _.get(this.submissionError, 'resubmittedAt');
			return resubmittedAt ? dateTimeFormat(resubmittedAt) : '-';
		},
		errorListString() {
			const errorList = _.get(this.submissionError, 'errorList', []);

			return JSON.stringify(errorList, null, 4);
		},
		codeMirrorEditedOptions() {
			return { ...this.codeMirrorReadOnlyOptions, readOnly: false };
		},
		userId() {
			return _.get(this.vars, 'userId');
		},
		test() {
			return _.find(this.accountRoles, { id: this.submissionError.accountId });
		},
		id() {
			return this.$route.params.id;
		}
	},
	watch: {
		id: {
			handler: 'fetchData'
		}
	},
	methods: {
		...mapActions({
			getUserAccountRoles: 'user/getUserAccountRoles',
			getSubmissionError: 'fulfilmentSubmissionError/findById',
			getNotifications: 'notification/find',
			updateSubmissionError: 'fulfilmentSubmissionError/update',
			submitOrder: 'fulfilmentOrder/create'
		}),
		async fetchData() {
			await this.getUserAccountRoles({ userId: this.userId });
			await this.getSubmissionError({ id: this.$route.params.id });

			const body = this.submissionError?.bodyJson;
			if (_.isObject(body)) {
				this.body = JSON.stringify(body, null, 4);
			} else {
				this.body = body;
			}

			this.getOrderPostbacks();
		},
		onOrderCopied() {
			this.$notify({ type: 'warn', text: $t('Order copied to clipboard') });
		},
		onOrderCopiedError() {
			this.$notify({ type: 'error', text: $t('Error copying the order to the clipboard') });
		},
		enableEditMode() {
			this.credentials = _.find(this.accountRoles, { id: this.submissionError.accountId });
			this.editting = true;
			this.orderBody = _.cloneDeep(this.body);
		},
		getPayload(item) {
			const payload = _.get(item, 'payload.additionalInfo.payload');

			try {
				return JSON.parse(payload);
			} catch (e) {
				return payload;
			}
		},
		discardEdition() {
			this.editting = false;
			this.orderBody = null;
		},
		onShowCode(title, data) {
			this.codeModal.title = title;

			if (typeof data === 'object') {
				this.codeModal.contentType = 'application/json';
				this.codeModal.data = JSON.stringify(data, null, '\t');
			} else {
				this.codeModal.contentType = 'text/plain';
				this.codeModal.data = data;
			}

			this.codeModal.visible = true;
		},
		onModalClose() {
			this.codeModal.title = '';
			this.codeModal.data = '';
			this.codeModal.visible = false;
		},
		async addOrderResubmittedFlag() {
			const data = {
				resubmittedAt: moment().toISOString()
			};

			return this.updateSubmissionError({ id: this.submissionError._id, data });
		},
		confirmSubmission() {
			this.$bvModal
				.msgBoxConfirm(this.$t('Do you want to continue submitting this order using the above credentials?'), {
					title: `${this.credentials.name} (${this.credentials.role})`,
					okTitle: this.$t('Confirm'),
					cancelTitle: this.$t('Cancel'),
					centered: true
				})
				.then(confirmed => {
					if (confirmed) this.handleSubmitOrder();
				})
				.catch(err => {
					const message = displayError(err);
					this.$notify({ type: 'error ', text: message });
				});
		},
		async handleSubmitOrder() {
			try {
				await this.submitOrder(JSON.parse(this.orderBody));
				this.$notify({ type: 'info', text: $t('Order successfully submitted') });
				this.discardEdition();
				await this.addOrderResubmittedFlag();
			} catch (err) {
				const message = displayError(err);
				this.$notify({ type: 'error ', text: message });
			}
		},
		getOrderPostbacks() {
			const query = {
				where: {
					'source.ref': 'submissionError',
					'source.refId': this.id
				},
				direction: 1,
				sort: '-timestamp',
				pagesize: 50,
				page: 1
			};

			return this.getNotifications({ query });
		},
		getPostbackStatusCode({ type, results }) {
			const result = _.head(results);

			if (result) {
				return result.statusCode;
			}

			return '';
		},
		getPostbackResult({ results }) {
			const result = _.head(results);

			if (result && result.response) {
				return result.response;
			}

			return '-';
		},
		getStatusField(notification) {
			const statusCode = this.getPostbackStatusCode(notification);

			if (statusCode < 300 && notification.type === 'email') {
				return $t('sent');
			}

			if (statusCode < 300 && notification.type !== 'email') {
				return $t('success');
			}

			return 'fail';
		},
		downloadFile() {
			const filename = `order-${this.orderId}`;
			const file = new Blob([this.body], { type: 'application/json' });
			const url = window.URL.createObjectURL(file);
			const a = document.createElement('a');
			a.href = url;
			a.download = filename;
			a.click();
			window.URL.revokeObjectURL(url);
		}
	}
};
</script>

<style lang="scss">
@import '~@workflow-solutions/ofs-vue-layout/dist/style/mixins';
@import '~@workflow-solutions/ofs-vue-layout/dist/style/variables';

.SubmissionErrorView {
	.Title {
		@include ofTextStyleSubheading();
	}

	&-icon {
		cursor: pointer;
	}

	&-row {
		margin-top: 20px;
	}

	&-details {
		&-heading {
			margin-bottom: 20px;
		}
	}
}
</style>
