<template>
	<b-modal id="modalPrevent" size="lg" ref="editModal" :title="$t('Edit line')" @show="onShow" @hide="onClose">
		<b-form novalidate>
			<template v-for="(label, index) in labels">
				<of-form-select
					v-if="label === $t('Spec')"
					name="specCode"
					:label="$t('Specification')"
					:description="$t('You will be able to configure the components for this specification later')"
					class="col-sm-12"
					:options="specificationOptions"
					:key="`label-spec-${index}`"
				>
				</of-form-select>
				<of-multi-select
					v-else-if="label === $t('Tags')"
					:label="label"
					name="tags"
					labelBy=""
					:searchable="false"
					:multiple="true"
					:taggable="true"
					:tag-placeholder="$t('Add this as new tag')"
					:placeholder="$t('Add a tag')"
					class="col-sm-12"
					:key="`label-tag-${index}`"
				>
				</of-multi-select>
				<of-multi-select
					v-else-if="label === $t('Catalogues')"
					:label="label"
					name="catalogueIds"
					:options="catalogues || []"
					labelBy="sourceCatalogueId"
					trackBy="sourceCatalogueId"
					:multiple="true"
					:placeholder="$t('Add a catalogue')"
					class="col-sm-12"
					:key="`label-catalogue-${index}`"
				>
				</of-multi-select>
				<of-form-input
					v-else
					:name="fields[index]"
					type="text"
					:placeholder="label"
					:label="label"
					class="col-sm-12"
					:key="`label-${index}`"
				>
				</of-form-input>
			</template>
		</b-form>
		<template #modal-footer>
			<div class="col-sm-12 text-right">
				<of-submit-button variant="danger" @click="submit" class="btn-primary" v-t="'Update'" />
				<b-btn class="btn-secondary ml-1" @click="cancel" v-t="'Cancel'" />
			</div>
		</template>
	</b-modal>
</template>

<script>
import { OfFormInput, OfSubmitButton, OfMultiSelect, OfFormSelect, withForm } from '@workflow-solutions/ofs-vue-layout';
import { mapActions, mapGetters } from 'vuex';
import _get from 'lodash/get';
import _clone from 'lodash/clone';

export default {
	name: 'ValidationEditModal',
	components: {
		OfFormInput,
		OfMultiSelect,
		OfSubmitButton,
		OfFormSelect
	},
	mixins: [withForm('ValidationEditModalForm')],
	props: {
		validation: {
			type: Object,
			default: () => {}
		},
		parentFields: {
			type: Array,
			default: () => []
		},
		requiredFields: {
			type: Array,
			default: () => []
		}
	},
	computed: {
		...mapGetters({
			specifications: 'specification/specifications',
			import: 'import/import',
			catalogues: 'catalogue/catalogues'
		}),
		fields() {
			return this.parentFields.filter((f, key) => ![0, 1, 17, 18].includes(key)).map(f => _get(f, 'key') || f);
		},
		labels() {
			return this.parentFields
				.filter((f, key) => ![0, 1, 17, 18].includes(key))
				.map(f => _get(f, 'label') || f.charAt(0).toUpperCase() + f.slice(1));
		},
		specificationOptions() {
			return [...(this.specifications || []).map(s => ({ value: s.sourceSpecId, text: s.sourceSpecId || '' }))];
		}
	},
	watch: {
		validation(validation) {
			if (validation && !validation.isValid) {
				const line = _clone(validation.originalLine);
				line.tags = line.tags ? line.tags.split('|') : [];
				this.setFormData(line);
				this.$refs.editModal.show();
			}
		}
	},
	methods: {
		...mapActions({
			findSpecifications: 'specification/find',
			updateValidation: 'validation/update',
			findCatalogues: 'catalogue/find'
		}),
		onShow() {
			this.findCatalogues();
			this.findSpecifications({ query: { query: { type: 'Book', $limit: 1000 } } });
		},
		onClose() {
			this.$emit('hide-modal');
		},
		cancel() {
			this.$refs.editModal.hide();
		},
		async submit() {
			try {
				const line = _clone(this.formData);
				const { catalogueIds, tags } = line;
				line.tags = tags instanceof Array ? tags.join('|') : tags;
				line.catalogueIds = catalogueIds instanceof Array ? catalogueIds.join('|') : catalogueIds;
				await this.updateValidation({ id: this.validation._id, data: { originalLine: line } });
			} catch (err) {
				this.$toaster.error(this.$t("Can't update the validation."), 3000);
			}

			this.$emit('submit-modal');
			this.$refs.editModal.hide();
		}
	}
};
</script>
