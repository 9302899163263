<template>
	<section class="ShipmentItem">
		<header
			class="ShipmentItemHeader"
			:class="{
				'ShipmentItemHeader--loading': isLoading,
				'ShipmentItemHeader--expanded': isExpanded,
				'ShipmentItemHeader--error': erroredFilesForItem.length
			}"
			@click="toggleExpanded"
		>
			<Loader v-if="isLoading" class="ShipmentItemHeader-loader" />
			<template v-else>
				<font-awesome-icon v-if="isExpanded" class="ShipmentItemHeader_arrow" icon="angle-down" />
				<font-awesome-icon v-else class="ShipmentItemHeader_arrow" icon="angle-right" />

				<router-link
					v-if="book"
					class="Artwork ShipmentItemHeader-thumb"
					data-test-id="itemThumbnail"
					:to="{ name: 'books.view', params: { id: book._id } }"
				>
					<thumbnail
						class="Artwork_image"
						:src="book ? book.thumbnail : ''"
						data-test-id="itemFileThumbnail"
					/>
				</router-link>

				<div class="ShipmentItemHeader-column">
					<span class="ShipmentItemHeader-title" data-test-id="itemProductName">
						<span class="ShipmentItemHeader-description">{{ item.description || item.sku }}</span>
					</span>

					<div class="ShipmentItemHeader-inner">
						<ofs-badge
							:status="status"
							:text="getBadgeStatusText(status)"
							data-test-id="shipmentItemStatusBadge"
							class="ShipmentItemHeader-badge"
						/>
						<ul v-if="book" class="ShipmentItemHeader-stats">
							<li class="ShipmentItemHeader-stat" data-test-id="shipmentItemQuantity">
								<span
									class="ShipmentItemHeader-key"
									data-test-id="shipmentItemQuantityKey"
									v-text="`${$t('Quantity')}:`"
								/>
								<span
									class="ShipmentItemHeader-value"
									data-test-id="shipmentItemQuantityValue"
									v-text="item.quantity"
								/>
							</li>
							<li class="ShipmentItemHeader-stat">
								<span
									class="ShipmentItemHeader-key"
									data-test-id="shipmentItemProductIDKey"
									v-text="`${$t('Product ID')}:`"
								/>
								<span
									class="ShipmentItemHeader-value"
									data-test-id="shipmentItemProductIDValue"
									v-text="sourceProductId"
								/>
							</li>
							<li class="ShipmentItemHeader-stat">
								<span
									class="ShipmentItemHeader-key"
									data-test-id="shipmentItemSpecCode"
									v-text="`${$t('Spec Code')}:`"
								/>
								<router-link
									class="ShipmentItemHeader-value"
									data-test-id="shipmentItemSpecCodeValue"
									:title="specCode"
									:to="{ name: 'specifications.edit', params: { id: specificationId } }"
								>
									{{ specCode }}
								</router-link>
							</li>
						</ul>
						<ul v-else class="ShipmentItemHeader-stats">
							<li v-if="showPricing" class="ShipmentItemHeader-stat" data-test-id="shipmentItemPrice">
								<span
									class="ShipmentItemHeader-key"
									data-test-id="shipmentItemPriceKey"
									v-text="`${$t('Price')}:`"
								/>
								<span
									class="ShipmentItemHeader-value"
									data-test-id="shipmentItemPriceValue"
									v-text="price"
								/>
							</li>
							<li class="ShipmentItemHeader-stat" data-test-id="shipmentItemQuantity">
								<span
									class="ShipmentItemHeader-key"
									data-test-id="shipmentItemQuantityKey"
									v-text="`${$t('Quantity')}:`"
								/>
								<span
									class="ShipmentItemHeader-value"
									data-test-id="shipmentItemQuantityValue"
									v-text="item.quantity"
								/>
							</li>
							<li class="ShipmentItemHeader-stat">
								<span
									class="ShipmentItemHeader-key"
									data-test-id="shipmentItemBarcodeKey"
									v-text="`${$t('Barcode')}:`"
								/>
								<span
									class="ShipmentItemHeader-value"
									data-test-id="shipmentItemBarcodeValue"
									v-text="`${item.barcode}`"
								/>
							</li>
							<li class="ShipmentItemHeader-stat" data-test-id="shipmentItemItemId">
								<span
									class="ShipmentItemHeader-key"
									data-test-id="shipmentItemItemIdKey"
									v-text="`${$t('Item ID')}:`"
								/>
								<span
									class="ShipmentItemHeader-value"
									data-test-id="shipmentItemItemIdValue"
									@click.stop
									v-text="item.sourceItemId"
								/>
							</li>
						</ul>
						<!-- <a
							v-if="showReprint"
							data-test-id="shipmentItemReprintBtn"
							href="#"
							@click.stop="reprint()"
							v-text="$t('Reprint')"
						/> -->
					</div>
				</div>
			</template>
		</header>
		<template v-if="isExpanded">
			<item-component
				v-for="(component, index) in item.components"
				:key="component._id"
				:component="component"
				:class="{ 'OrderComponent-borderless': index === 0 }"
				:errored-files="erroredFilesForItem"
			/>
		</template>
	</section>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import { OfsBadge } from '@workflow-solutions/ofs-vue-layout';
import { currency } from '../../../../../lib/filters';
import { getBadgeStatusText } from '../../../../../lib/helpers';
import ItemComponent from './Component';
import Loader from '../../../../../components/Loader';
import Thumbnail from '../../../../../components/siteflow/Thumbnail';

export default {
	components: {
		OfsBadge,
		ItemComponent,
		Loader,
		Thumbnail
	},
	filters: {
		currency
	},
	props: {
		item: {
			type: Object,
			required: true
		},
		expanded: {
			type: Boolean,
			default: false
		},
		erroredFiles: {
			type: Array,
			default: () => []
		},
		shipment: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			isLoading: true,
			isExpanded: this.expanded,
			book: null
		};
	},
	computed: {
		...mapGetters({
			order: 'order/order',
			vars: 'account/vars',
			lang: 'lang/lang',
			showReprint: 'order/showReprint',
			specifications: 'specification/specifications'
		}),
		showPricing() {
			return _.get(this.vars, 'oneflowAccountSettings.enablePricing', false);
		},
		isbn() {
			return _.get(this.book, 'properties.isbn');
		},
		specCode() {
			const specification = this.specifications.find(s => s._id === this.specificationId);
			return _.get(specification, 'description', this.$t('Specification'));
		},
		specificationId() {
			return _.get(this.book, 'specificationId');
		},
		accountCurrency() {
			return _.get(this.vars, 'oneflowAccountSettings.currency', 'GBP');
		},
		price() {
			return currency(this.item.unitPrice, this.accountCurrency, this.lang);
		},
		sourceProductId() {
			return _.get(this.item, 'sourceProductId');
		},
		erroredFilesForItem() {
			const files = _.map(this.item.components, component => component.fileId);
			return _.filter(this.erroredFiles, file => _.includes(files, file._id));
		},
		orderStatus() {
			return _.get(this.order, 'order.orderData.status');
		},
		status() {
			const fileIds = _.map(this.item.components, component => component.fileId);
			const jobs = _.filter(this.order.jobs, j => fileIds.includes(j.fileId));
			const files = _.filter(this.order.files, file => fileIds.includes(file._id));

			// If one file/component file has an error = item status is error
			if (this.erroredFilesForItem.length) return 'error';
			// Else if order is calcelled - item status = cancelled
			else if (this.orderStatus === 'cancelled') return 'cancelled';
			// Else if Shipment is shipped  - item status is shipped
			else if (this.shipment.status === 'shipped') return 'shipped';
			// Else if item has a live job with a batch number - item status is Live
			else if (_.find(jobs, j => j.status === 'live' && j.batchId)) return 'live';
			// Else if one file component is "notreceived" - item status is Data Ready
			else if (_.find(files, f => f.status === 'notreceived')) return 'dataready';
			return 'printready';
		}
	},
	watch: {
		sourceProductId: {
			immediate: true,
			async handler() {
				if (this.sourceProductId) {
					const { data } = await this.findBooks({
						query: { query: { sourceProductId: this.sourceProductId } }
					});
					this.book = _.first(data);
					this.isLoading = false;
				} else {
					this.isLoading = false;
				}
			}
		}
	},
	methods: {
		getBadgeStatusText,
		...mapActions({
			findBooks: 'book/find'
		}),
		toggleExpanded() {
			this.isExpanded = !this.isExpanded;
		}
	}
};
</script>

<style lang="scss"></style>
