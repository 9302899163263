<template xmlns:v-clipboard="http://www.w3.org/1999/xhtml">
	<DefaultLayout>
		<b-form name="ruleSetForm" @submit.prevent="submit">
			<ofs-panel>
				<loader v-if="isLoading" />
				<template v-else>
					<content-header
						no-padding
						class="mb-3"
						:title="isNewRoutingRule ? $t('Add new Rule Set') : ruleSet.name"
					></content-header>
					<template v-if="ruleSet">
						<!-- TOP HEADER -->
						<b-card>
							<b-row>
								<b-col md="6">
									<b-form-group :label="$t('Rule Set Name')" class="mb-2">
										<b-form-input v-model="ruleSet.name" required />
									</b-form-group>
								</b-col>

								<b-col md="6">
									<b-form-group
										:label="$t('Order Routing Submission URL')"
										:description="$t('POST endpoint, this is automatically generated')"
										class="mb-0"
									>
										<b-form-input
											:disabled="true"
											:value="submissionUrl"
											:class="ruleSet.name"
											clipboard
											@click="onSubmissionUrlCopied"
										/>
									</b-form-group>
								</b-col>
							</b-row>
						</b-card>

						<div v-if="!ruleSet.rules || !ruleSet.rules.length" class="text-right">
							<b-btn class="mt-3" @click="addRule"> <i class="fa fa-plus" /> {{ $t('Add rule') }} </b-btn>
						</div>
						<div
							v-for="(rule, index) in ruleSet.rules"
							:key="rule._id"
							class="card mt-3"
							data-test-id="rules"
						>
							<header class="card-header rule-header">
								<span>{{ $t('Rule') }} {{ index + 1 }}</span>
								<b-button-toolbar>
									<b-button-group>
										<b-button size="sm" @click="addRule">
											<icon name="plus" scale="0.6" />
										</b-button>
										<b-button size="sm" @click="removeRule(rule)">
											<icon name="minus" scale="0.6" />
										</b-button>
									</b-button-group>
								</b-button-toolbar>
							</header>
							<div class="card-body">
								<routing-rule :rule="rule" :open-confirm-modal="openConfirmModal" />

								<div v-if="ruleSet.rules.length === 0" class="RoutingRuleForm-no-rules text-right m-b">
									<button
										type="button"
										class="btn btn-default RoutingRuleForm-add-rule"
										@click="addRule()"
									>
										<icon name="plus" scale="0.6" />
										<span v-t="'Add rule'" />
									</button>
								</div>
							</div>
						</div>

						<!-- DEFAULT ACTIONS -->
						<b-card :header="$t('Otherwise (if no Rule has been matched)')" class="mt-3">
							<div v-for="(action, index) in ruleSet.defaults" :key="`default-action-${index}`">
								<b-row>
									<b-col md="9">
										<routing-rule-action :action="action" />
									</b-col>
									<b-col class="d-flex align-items-center">
										<b-button
											class="RoutingRuleForm-defaults-remove-action"
											size="sm"
											@click="removeDefaultAction(action)"
										>
											<icon name="minus" scale="0.6" />
										</b-button>
									</b-col>
								</b-row>
							</div>

							<b-button
								v-show="ruleSet.defaults && ruleSet.defaults.length <= 0"
								v-t="'Add default action'"
								@click="addDefaultAction()"
							/>
						</b-card>
					</template>
				</template>
				<template v-if="ruleSet && !isLoading" #actions>
					<b-button
						v-if="!isNewRoutingRule"
						v-t="'Delete'"
						variant="danger"
						class="mr-2"
						@click="deleteRuleset"
					/>
					<b-button v-t="'Save'" type="submit" class="float-right" variant="primary" />
				</template>
			</ofs-panel>
		</b-form>
		<confirm-modal v-if="!!confirmModal" :options="confirmModal" :cancel="cancelConfirmModal"> </confirm-modal>
	</DefaultLayout>
</template>

<script type="text/javascript">
import { mapGetters, mapActions } from 'vuex';
import { OfsPanel, ContentHeader } from '@workflow-solutions/ofs-vue-layout';
import { i18n } from 'src/vuex';
import { displayError } from '../../../lib/helpers';
import DefaultLayout from '../../../components/DefaultLayout';
import Loader from '../../../components/Loader';
import ConfirmModal from '../../../components/modals/ConfirmModal';
import RoutingRule from './RoutingRule';
import RoutingRuleAction from './RoutingRuleAction';
import './RoutingRules.scss';
import { featureFlagCheckMixin } from '../../../mixins/featureFlagCheck';

export default {
	components: {
		OfsPanel,
		ContentHeader,
		DefaultLayout,
		RoutingRule,
		RoutingRuleAction,
		ConfirmModal,
		Loader
	},
	mixins: [featureFlagCheckMixin('piazza-legacy', 'piazza')],
	data() {
		const $t = str => i18n.t(str);
		return {
			submissionUrl: '',
			name: '',
			isNewRoutingRule: true,
			confirmModal: null,
			isLoading: true,
			ruleSet: {
				name: '',
				rules: [],
				defaults: []
			}
		};
	},
	computed: {
		...mapGetters({ theRuleSet: 'routingRule/routingRule' })
	},
	async created() {
		this.clearRoutingRule();
		if (this.$route.params.id) {
			try {
				this.isLoading = true;
				this.isNewRoutingRule = false;
				await this.getRoutingRule(this.$route.params.id);
				this.ruleSet = { ...this.theRuleSet };
				this.submissionUrl = `${window.$config.orderSubmissionApiBase}/order/route/${this.ruleSet._id}`;
			} catch (err) {
				const message = displayError(err);
				this.$notify({ type: 'error ', text: message });
			} finally {
				this.isLoading = false;
			}
		} else {
			this.ruleSet.name = this.$t('New Rule Set');
			this.ruleSet.rules = [];
			this.ruleSet.defaults = [];
			this.isLoading = false;

			this.addRule();
			this.addDefaultAction();
		}
	},
	methods: {
		...mapActions({
			getRoutingRule: 'routingRule/getRoutingRule',
			updateRoutingRule: 'routingRule/updateRoutingRule',
			createRoutingRule: 'routingRule/createRoutingRule',
			clearRoutingRule: 'routingRule/clearItem',
			deleteRoutingRule: 'routingRule/deleteRoutingRule'
		}),
		async submit() {
			try {
				this.isLoading = true;

				const data = {
					name: this.ruleSet.name,
					rules: this.ruleSet.rules,
					defaults: this.ruleSet.defaults
				};

				if (this.ruleSet._id) {
					await this.updateRoutingRule({ id: this.$route.params.id, data });
					this.$toaster.info(this.$t('Routing rules updated'), { timeout: 2000 });
				} else {
					await this.createRoutingRule(data);
					this.$toaster.info(this.$t('Routing rules created'), { timeout: 2000 });
					this.$router.push({ path: '/routing-rules/list' });
				}
			} catch (err) {
				const message = displayError(err);
				this.$notify({ type: 'error ', text: message });
			} finally {
				this.isLoading = false;
			}
		},
		addDefaultAction() {
			const actions = this.ruleSet.defaults || [];
			actions.push({ setOrderDestinationName: '' });
		},
		removeDefaultAction(action) {
			this.openConfirmModal({
				title: this.$t('Remove default action'),
				text: this.$t('Are you sure you want to remove this default action?'),
				type: 'info',
				showCancelButton: true,
				confirmType: 'danger',
				confirmText: this.$t('Are you sure you want to remove this action?'),
				closeOnConfirm: true,
				success: () => {
					const actions = this.ruleSet.defaults;
					const index = actions.indexOf(action);
					actions.splice(index, 1);
				}
			});
		},
		addRule() {
			this.ruleSet.rules = this.ruleSet.rules || [];
			this.ruleSet.rules.push({
				if: { and: [{ orderShipmentsHaveIsoCountry: ['any'] }] },
				then: [],
				else: [],
				stopIfMatches: false
			});
		},
		removeRule(rule) {
			this.openConfirmModal({
				title: this.$t('Remove rule'),
				text: this.$t('Are you sure you want to remove this rule?'),
				type: 'info',
				showCancelButton: true,
				confirmType: 'danger',
				confirmText: this.$t('Yes, remove it!'),
				closeOnConfirm: true,
				success: () => {
					const index = this.ruleSet.rules.indexOf(rule);
					this.ruleSet.rules.splice(index, 1);
				}
			});
		},
		onSubmissionUrlCopied(event) {
			event.target.select();
			console.log(event);
			try {
				const successful = document.execCommand('copy');
				if (successful) {
					this.$toaster.info(this.$t('URL copied to the clipboard'), { timeout: 2000 });
				} else {
					this.$toaster.error(this.$t('Error copying the URL to the clipboard'), { timeout: 2000 });
				}
			} catch (err) {
				console.log('!', err);
				this.$toaster.error(this.$t('Error copying the URL to the clipboard'), { timeout: 2000 });
			}
		},
		deleteRuleset() {
			this.openConfirmModal({
				title: this.$t('Delete Rule Set?'),
				text: this.$t('Are you sure you want to delete this Rule Set?'),
				type: 'info',
				showCancelButton: true,
				confirmType: 'danger',
				confirmText: this.$t('Yes, delete it!'),
				closeOnConfirm: true,
				success: async () => {
					await this.deleteRoutingRule({ id: this.ruleSet._id });
					this.$toaster.success(this.$t('Rule Set succesfully deleted'), { timeout: 2000 });
					this.$router.push({ path: '/routing-rules/list' });
				}
			});
		},
		openConfirmModal(options) {
			this.confirmModal = options;
		},
		cancelConfirmModal() {
			this.confirmModal = null;
		}
	}
};
</script>
<style lang="scss">
.rule-header {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}
</style>
