<template>
	<DefaultLayout>
		<ofs-panel>
			<section v-if="isLoading">
				<Loader />
			</section>
			<list-table
				v-else
				:table-title="$t('HP Specs')"
				:items="fulfilmentSpecifications"
				:fields="fields"
				:total-items="fulfilmentSpecificationCount"
				:per-page="perPage"
				:current-page="currentPage"
				:is-busy="isBusy"
				:page-position-prefix="$t('Showing')"
				:page-position-join="$t('of')"
				hover
				@row-selected="rowSelected"
				@row-clicked="itemClicked"
				@table-change="handleTableChange"
			>
				<template slot="prev-text" slot-scope="{}">
					{{ $t('Prev') }}
				</template>
				<template slot="next-text" slot-scope="{}">
					{{ $t('Next') }}
				</template>
				<template slot="empty">
					<span>
						<i>{{ $t('No Data') }}</i>
					</span>
				</template>
				<template slot="TableButtons-Slot-left" slot-scope="{}">
					<b-button variant="primary" :to="{ name: 'fulfilment-specification.create' }">
						{{ $t('New Spec') }}
					</b-button>
				</template>
				<template v-slot:cell(active)="data">
					<ofs-badge v-if="data.item.active" :text="$t('Live')" status="live" />
					<ofs-badge v-else :text="$t('Pending')" status="pending" />
				</template>
				<template v-slot:cell(description)="data">
					{{ data.item.description || '-' }}
				</template>
				<template v-slot:cell(updatedAt)="data">
					{{ data.item.updatedAt | duration }}
				</template>
				<template v-slot:cell(actions)="data">
					<b-dropdown no-caret size="sm" data-test-id="materialActions">
						<template slot="button-content">
							<font-awesome-icon icon="ellipsis-h" />
						</template>
						<b-dropdown-item :disabled="data.item.active" @click="deleteItem(data.item)">
							{{ $t('Delete') }}
						</b-dropdown-item>
					</b-dropdown>
				</template>
			</list-table>
		</ofs-panel>
	</DefaultLayout>
</template>

<script>
import { OfsPanel, ListTable, OfsBadge } from '@workflow-solutions/ofs-vue-layout';
import DefaultLayout from '../../components/DefaultLayout';
import Loader from '../../components/Loader';
import { displayError, duration } from '../../lib/helpers';
import { featureFlagCheckMixin } from '../../mixins/featureFlagCheck';
import tableMixin from '../../mixins/tableMixin';
import { mapActions, mapGetters } from 'vuex';

export default {
	components: {
		DefaultLayout,
		OfsPanel,
		OfsBadge,
		Loader,
		ListTable
	},
	filters: {
		duration
	},
	mixins: [tableMixin, featureFlagCheckMixin('piazza-fulfilment')],
	data() {
		return {
			sort: {
				updatedAt: -1
			},
			isLoading: false,
			isBusy: false,
			fields: [
				{ key: 'active', label: this.$t('Status') },
				{ key: 'sourceSpecId', label: this.$t('Spec Code'), sortable: true },
				{ key: 'description', label: this.$t('Description') },
				{ key: 'componentIds.length', label: this.$t('Components'), class: 'text-center' },
				{ key: 'productType', label: this.$t('Product Type') },
				{ key: 'updatedAt', label: this.$t('Last Modified'), sortable: true },
				{ key: 'actions', label: ' ', tdClass: 'text-right' }
			]
		};
	},
	computed: {
		...mapGetters({
			fulfilmentSpecifications: 'fulfilmentSpecification/fulfilmentSpecifications',
			fulfilmentSpecificationCount: 'fulfilmentSpecification/count'
		})
	},
	async mounted() {
		this.isLoading = true;
		await this.fetchData();
		this.isLoading = false;
	},
	methods: {
		...mapActions({
			findFulfilmentSpecifications: 'fulfilmentSpecification/find',
			deleteFulfilmentSpecification: 'fulfilmentSpecification/deleteById'
		}),
		async fetchData() {
			try {
				const query = {
					query: {
						$limit: this.perPage,
						$skip: this.perPage * (this.currentPage - 1),
						$sort: this.sort
					}
				};
				this.isBusy = true;
				await this.findFulfilmentSpecifications({ query });
			} catch (err) {
				this.$notify({ type: 'error', text: displayError(err) });
			} finally {
				this.isBusy = false;
			}
		},
		itemClicked(item) {
			this.$router.push({ name: 'fulfilment-specification.edit', params: { id: item._id } });
		},
		rowSelected(items) {
			this.selectedItems = items;
		},
		async deleteItem(item) {
			const confirm = await this.$bvModal.msgBoxConfirm(
				this.$t('Are you sure you want to delete this HP Spec?'),
				{
					title: this.$t('Delete HP Spec'),
					size: 'sm',
					okTitle: this.$t('Yes'),
					cancelTitle: this.$t('No')
				}
			);

			if (confirm) {
				try {
					this.isBusy = true;
					await this.deleteFulfilmentSpecification({
						id: item._id
					});
					this.$notify({
						type: 'success',
						text: this.$t('HP Spec successfully deleted'),
						title: this.$t('HP Spec Deleted')
					});
					await this.fetchData();
				} catch (err) {
					this.$notify({ type: 'error', text: displayError(err) });
				} finally {
					this.isBusy = false;
				}
			}
		}
	}
};
</script>

<style lang="scss"></style>
