<template>
	<b-container fluid class="LoginPage d-flex flex-column">
		<b-row align-h="center">
			<b-col sm="6" lg="5">
				<div class="LoginPage-form">
					<div class="LoginPage-top">
						<img
							class="LoginPage-logo"
							:src="$HP_MODE ? 'img/hp-piazza-logo.svg' : 'img/bc-logo.svg'"
						/>
					</div>
					<b-card class="mx-auto LoginPage-width" header-tag="header" align="center" body-class="body">
						<b-card-text class="text-left">
							<h2 class="title text-center">
								{{ $t('Sign in with your') }} <b>{{ $t('HP account') }}</b>
							</h2>
							<b-button v-t="'Sign in'" class="w-100 hpid" variant="info" @click="loginWithHPID" />
							<h6 class="title text-center" style="margin-top:10px">
								{{ $t('If you have any logging issues, please contact ') }}
								<a style="color:#23B7E5" href="mailto:siteflowsupport@hp.com">siteflowsupport@hp.com</a>
							</h6>
						</b-card-text>
					</b-card>
				</div>
			</b-col>
		</b-row>
		<div class="LoginPage-bottom">
			<div class="container">
				<b-row align-v="center">
					<b-col cols="7">
						© {{ $t('Copyright') }} {{ new Date().getFullYear() }} {{ $t('HP Development Company, L.P.') }}
					</b-col>
					<b-col cols="1">
						<a v-t="'Privacy'" href="http://www.hp.com/go/privacy" />
					</b-col>
					<b-col cols="2">
						<a v-t="'Terms & Conditions'" href="http://www.hp.com/go/termsofuse" />
					</b-col>
					<b-col cols="2">
						<b-dropdown ref="languageDropdown" size="sm" variant="link" right class="user-dropdown dropup">
							<template #button-content>
								{{ languages.find(el => el.code === lang).name }}
							</template>
							<button
								v-for="(languageOption, i) in languages"
								:key="i"
								role="menuitem"
								type="button"
								class="dropdown-item"
								:class="{ active: languageOption.code === lang }"
								@click="onLanguagesSelection(languageOption)"
								v-text="languageOption.name"
							/>
						</b-dropdown>
					</b-col>
				</b-row>
			</div>
		</div>
	</b-container>
</template>

<script type="text/javascript">
import { mapActions, mapGetters } from 'vuex';
import _get from 'lodash/get';
import { dateFormatMixin } from '@/mixins/dateFormatMixin';

export default {
	name: 'LoginPage',
	mixins: [dateFormatMixin()],
	computed: {
		...mapGetters('auth', ['isAuthenticated']),
		...mapGetters('lang', ['lang', 'languages']),
		...mapGetters({ isFeatureFlagActive: 'featureToggle/isActive' })
	},
	created() {
		if (this.isAuthenticated) this.$router.push('/');
	},
	methods: {
		...mapActions('lang', ['setLanguage']),
		_get,
		async onLanguagesSelection(lang) {
			this.$refs.languageDropdown.hide();
			await this.setLanguage({ lang: lang.code });
		},
		loginWithHPID() {
			window.location.href = `${window.$config.hpIdAuthorize}&locale=${this.lang}`;
		}
	}
};
</script>

<style lang="scss">
@import '~@workflow-solutions/ofs-vue-layout/dist/style/variables';
.LoginPage {
	height: 100vh;
	width: 100vw;

	> .row.justify-content-center {
		height: 100%;

		> .col-sm-6.col-lg-4 {
			align-self: center;
		}
	}

	&-width {
		max-width: 380px;
	}

	&-top {
		padding: 10px 20px;
		margin-bottom: 20px;
		text-align: center;
		img {
			max-height: 40px;
		}
	}

	&-bottom {
		background-color: $of-color-white;
		font-size: 14px;
		color: $of-color-dark;
		padding: 20px 20px;
		line-height: 25px;
		margin: 0 -15px;

		a {
			color: $of-color-aqua;
		}

		a:hover {
			text-decoration: none;
		}

		.dropdown-toggle {
			color: $of-color-dark;
		}
		.dropdown-item.active {
			background: $of-color-aqua;
		}
	}

	&-logo {
		height: 100px;
	}

	&-form {
		margin: 20px;
	}
}
</style>
