import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import * as actions from './actions';

const createStore = () => {
	Vue.use(Vuex);
	return new Vuex.Store({
		config: window.$config,
		plugins: [
			createPersistedState({
				key: 'brand-centre',
				paths: [
					'ui.pageConfigs',
					'auth.token',
					'auth.isAuthenticated',
					'account.currentAccount',
					'account.psps',
					'account.psp',
					'analytics.filters',
					'lang.lang',
					'bookPreviewer.activeBoxes',
					'bookPreviewer.zoom',
					'order.selectedShippingMethod',
					'order.shippingMethods'
				]
			})
		],
		actions
	});
};

export default createStore;
