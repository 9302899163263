<template>
	<DefaultLayout>
		<ofs-panel v-if="isLoading">
			<Loader />
		</ofs-panel>
		<ofs-panel class="OrderView" v-else>
			<content-header :title="pageTitle" :breadcrumbs="breadcrumbs" no-padding class="mb-3">
				<b-button
					v-if="isRejectButtonVisible"
					data-test-id="FulfilmentOrders_rejectOrderButton"
					variant="danger"
					class="ml-1 OrderView_Reject"
					@click="rejectOrder"
				>{{ $t('Reject Order') }}</b-button
				>
				<b-button
					v-if="isCancelButtonVisible"
					data-test-id="FulfilmentOrders_cancelOrderButton"
					variant="danger"
					class="ml-1"
					@click="cancelOrder"
				>{{ $t('Cancel Order') }}</b-button>
			</content-header>
			<notification v-if="fulfilments.length > 1" dismissable class="mb-3">
				{{ $t('This order has been split into ') }}
				{{ this.fulfilments.length }}
				{{ $t('Shipments') }}
			</notification>
			<section class="OrderView_container">
				<section class="OrderViewItems">
					<header class="OrderViewItems_header">
						<h2 class="OrderViewItems_title">
							{{ $t('Order Items') }}
							<span
								v-if="fulfilmentItemsCount"
								class="tag-list_item ml-2"
							>{{ fulfilmentItemsCount }}</span>
						</h2>
					</header>
					<ul class="OrderShipments">
						<li class="OrderShipment">
							<section class="OrderShipment-inner">
								<section class="OrderShipment-items">
									<FulfilmentItem v-for="item in fulfilmentItems" :key="item._id" :item="item" />
									<b-button
										variant="link"
										v-if="additionalFulfilmentItemsCount"
										size="lg"
										@click="isFulfilmentItemModalVisible = true"
										data-test-id="order-view-all-items"
										>{{ `+ ${ additionalFulfilmentItemsCount } - ${ $t('View All') }` }}</b-button>
								</section>
								<section class="OrderShipmentDetails OrderShipmentDetails--no-padding">
									<section class="OrderShipmentDetails_section">
										<header class="OrderShipmentDetails_header">
											<span class="OrderShipmentDetails_title" v-text="$t('Shipping Details')" />
										</header>
										<p class="OrderShipmentDetails_address">
											<span data-test-id="ship-to-name">{{ name }}</span>
											<span data-test-id="ship-to-address1">{{ address1 }}</span>
											<span data-test-id="ship-to-address2">{{ address2 }}</span>
											<span data-test-id="ship-to-town">{{ town }}</span>
											<span data-test-id="ship-to-postcode">{{ postcode }}</span>
											<span data-test-id="ship-to-country">{{ isoCountry }}</span>
											<span data-test-id="ship-to-state">{{ state }}</span>
										</p>

										<p class="OrderShipmentDetails_key" v-text="$t('Service Level')" />
										<p
											data-test-id="shipment-carrier"
											v-text="getShipmentServiceLevelText(shipment.serviceLevel)"
										/>

										<accordion v-if="shipment.returnAddress" :title="$t('Return Address')" inline>
											<p class="mb-0" data-test-id="ship-return-address" v-text="returnTo" />
										</accordion>
									</section>
									<fulfilment-shipment
										v-for="(fulfilment, i) in fulfilments"
										:index="i"
										:key="fulfilment._id"
										:fulfilment="fulfilment"
										:shipment="getShipmentById(fulfilment.shipmentId)"
									/>
								</section>
							</section>
						</li>
					</ul>
				</section>
				<div>
					<section class="OrderView_summary">
						<section class="OrderSummary">
							<h2 class="OrderSummary_title" v-text="$t('Order Summary')" />
							<div class="OrderSummary_table">
								<b-row class="CurrencyName">
									<of-form-input
										:value="currencyDescription"
										data-test-id="orderSummaryCurrency"
										disabled
										class="w-100"
									/>
								</b-row>
								<b-row class="OrderSummary_info">
									<b-col class="OrderSummary_cell">
										<span class="OrderSummary_value">{{ $t('Items subtotal') }}</span>
									</b-col>
									<b-col class="OrderSummary_cell">
										<span class="OrderSummary_value_right" data-test-id="itemsNetTotalAmount">
											<Currency :value="itemsNetTotalAmount" :currency="selectedCurrency" />
										</span>
									</b-col>
								</b-row>
								<b-row class="OrderSummary_info" v-if="itemsVoided">
									<b-col class="OrderSummary_cell">
										<span class="OrderSummary_value_voided">{{ $t('VOIDED') }}</span>
									</b-col>
									<b-col class="OrderSummary_cell">
										<span class="OrderSummary_value_right_voided" data-test-id="itemsTotalVoided">
											<Currency :value="voidedItemsAmount" :currency="selectedCurrency" />
										</span>
									</b-col>
								</b-row>
								<b-row class="OrderSummary_info">
									<b-col class="OrderSummary_cell">
										<span class="OrderSummary_value">{{ $t('Shipping') }}</span>
									</b-col>
									<b-col class="OrderSummary_cell">
										<span class="OrderSummary_value_right" data-test-id="orderSummaryShipping">
											<Currency :value="shippingNetTotalAmount" :currency="selectedCurrency" />
										</span>
									</b-col>
								</b-row>
								<b-row class="OrderSummary_info">
									<b-col class="OrderSummary_cell">
										<span class="OrderSummary_value">{{ $t('Applied Tax') }}</span>
									</b-col>
									<b-col class="OrderSummary_cell">
										<span class="OrderSummary_value_right" data-test-id="taxTotalAmount">
											<Currency :value="taxTotalAmount" :currency="selectedCurrency" />
										</span>
									</b-col>
								</b-row>
								<b-row class="OrderSummary_info_border">
									<b-col class="OrderSummary_cell">
										<span class="OrderSummary_value">
											<b>{{ $t('Total') }}</b>
										</span>
									</b-col>
									<b-col class="OrderSummary_cell">
										<span class="OrderSummary_value_right" data-test-id="orderSummaryTotal">
											<b>
												<Currency
													:value="orderWithShippingTotal"
													:currency="selectedCurrency"
													:billable="orderBillable"
												/>
											</b>
										</span>
									</b-col>
								</b-row>
							</div>
						</section>
					</section>
					<section class="OrderView_details">
						<section class="OrderDetail">
							<h2 class="OrderDetail_title" v-text="$t('Order Details')" />
							<b-row class="mb-3">
								<b-col class="OrderDetail_cell">
									<span class="OrderDetail_label">{{ $t('Order Id') }}</span>
									<span class="OrderDetail_value" data-test-id="orderDataOrderId">
										{{ sourceOrderId }}
									</span>
								</b-col>
								<b-col class="OrderDetail_cell">
									<span class="OrderDetail_label">{{ $t('Status') }}</span>
									<span class="d-flex">
										<ofs-badge
											v-if="status === 'error'"
											v-b-tooltip.hover
											:title="$t('Contact HP Support')"
											:status="status"
											:text="getBadgeStatusText(status)"
											data-test-id="orderStatus"
										/>
										<ofs-badge
											v-else
											:status="status"
											:text="getBadgeStatusText(status)"
											data-test-id="orderStatus"
										/>
									</span>
								</b-col>
							</b-row>
							<b-row class="mb-3">
								<b-col class="OrderDetail_cell">
									<span class="OrderDetail_label">{{ $t('Submitted') }}</span>
									<span
										class="OrderDetail_value"
										data-test-id="order-submitted"
										:title="formatDate(submitted)"
										>{{ submitted | duration }}</span>
								</b-col>
							</b-row>
						</section>
					</section>
					<b-tabs class="OrderView_tabs" fill>
						<b-tab :title="$t('Postbacks')">
							<Postbacks :order="fulfilmentOrder" />
						</b-tab>
						<!-- <b-tab :title="$t('Order Logs')" disabled>
						</b-tab> -->
					</b-tabs>
				</div>
			</section>
		</ofs-panel>
		<fulfilment-items-modal
			:show="isFulfilmentItemModalVisible"
			:on-close="closeFulfilmentItemModal"
			:order-id="id"
			:source-order-id="sourceOrderId"
			:fulfilments="fulfilments"
		/>
	</DefaultLayout>
</template>

<script>
import _ from 'lodash';
import {
	ContentHeader,
	OfsPanel,
	Accordion,
	OfsBadge,
	OfsFeatureButton,
	OfFormInput,
	OfFormSelect,
	OfMultiSelect,
	OfSubmitButton,
	withForm
} from '@workflow-solutions/ofs-vue-layout';
import { mapActions, mapGetters } from 'vuex';
import DefaultLayout from '../../../components/DefaultLayout';
import Loader from '../../../components/Loader';
import Notification from '../../../components/Notification';
import FulfilmentShipment from './Components/FulfilmentShipment';
import FulfilmentItem from './Components/FulfilmentItem';
import FulfilmentItemsModal from './Components/Modal/FulfilmentItems';
import {
	displayError,
	valueToInt,
	getShipmentServiceLevelText,
	valueToFloat,
	getBadgeStatusText,
	duration
} from '../../../lib/helpers';
import Postbacks from '../../../components/Postbacks';
import { dateFormatMixin } from '../../../mixins/dateFormatMixin';
import { featureFlagCheckMixin } from '../../../mixins/featureFlagCheck';
import Currency from '../../../components/Currency';

const formName = 'editMaterial';

export default {
	components: {
		Accordion,
		ContentHeader,
		OfsBadge,
		Notification,
		Loader,
		OfsPanel,
		DefaultLayout,
		FulfilmentShipment,
		FulfilmentItem,
		FulfilmentItemsModal,
		OfSubmitButton,
		OfFormSelect,
		Currency,
		OfFormInput,
		Postbacks
	},
	mixins: [dateFormatMixin(), withForm(formName), featureFlagCheckMixin('piazza-fulfilment')],
	data() {
		return {
			isLoading: true,
			isFulfilmentItemModalVisible: false,
			shipments: [],
			fulfilments: [],
			fulfilmentItems: []
		};
	},
	computed: {
		...mapGetters({
			currencies: 'currency/currencies',
			fulfilment: 'fulfilment/fulfilment',
			fulfilmentItemsCount: 'fulfilmentItem/count',
			fulfilmentOrder: 'fulfilmentOrder/fulfilmentOrder',
			lang: 'lang/lang',
			isFulfilmentAdmin: 'auth/isFulfilmentAdmin'
		}),
		id() {
			return this.$route.params.id;
		},
		pageTitle() {
			return `${this.sourceOrderId}`;
		},
		additionalFulfilmentItemsCount() {
			return this.fulfilmentItemsCount - this.fulfilmentItems.length;
		},
		sourceOrderId() {
			return _.get(this.formData, 'sourceOrderId');
		},
		submitted() {
			return _.get(this.formData, 'createdAt');
		},
		status() {
			return _.get(this.formData, 'status');
		},
		name() {
			return _.get(this.shipment, 'shipTo.name');
		},
		address1() {
			return _.get(this.shipment, 'shipTo.address1');
		},
		address2() {
			return _.get(this.shipment, 'shipTo.address2');
		},
		town() {
			return _.get(this.shipment, 'shipTo.town');
		},
		postcode() {
			return _.get(this.shipment, 'shipTo.postcode');
		},
		isoCountry() {
			return _.get(this.shipment, 'shipTo.isoCountry');
		},
		state() {
			return _.get(this.shipment, 'shipTo.state');
		},
		returnTo() {
			return this.generateShippingAddress(this.shipment.returnAddress);
		},
		shipment() {
			return this.shipments[0];
		},
		orderNetTotalAmount() {
			return this.formData?.totalFulfilmentPrice?.netAmount;
		},
		itemsNetTotalAmount() {
			return _.sum([
				this.formData?.originalTotalFulfilmentPrice?.netAmount,
				-this.originalShippingNetTotalAmount
			]);
		},
		itemsVoided() {
			return _.round(this.voidedItemsAmount) !== 0;
		},
		voidedItemsAmount() {
			return _.sum([
				this.formData?.totalFulfilmentPrice?.netAmount,
				-this.shippingNetTotalAmount,
				-this.formData?.originalTotalFulfilmentPrice?.netAmount,
				this.originalShippingNetTotalAmount
			]);
		},
		shippingNetTotalAmount() {
			return _.sumBy(this.shipments, shipment => shipment?.fulfilmentPrice?.netAmount) || 0;
		},
		originalShippingNetTotalAmount() {
			return _.sumBy(this.shipments, shipment => shipment?.originalFulfilmentPrice?.netAmount) || 0;
		},
		taxTotalAmount() {
			const shippingTax = _.sumBy(this.shipments, shipment => +shipment?.fulfilmentPrice?.taxAmount) || 0;
			const orderTax = this.formData?.totalFulfilmentPrice?.taxAmount;
			return shippingTax + orderTax;
		},
		orderWithShippingTotal() {
			return this.formData?.totalFulfilmentPrice?.amount || 0;
		},
		orderBillable() {
			return this.formData?.totalFulfilmentPrice ? this.formData.totalFulfilmentPrice.billable : true;
		},
		currencyOptions() {
			return this.currencies?.map(currency => ({
				value: currency._id,
				text: currency.name + ' - ' + currency._id + ' (' + currency.symbol + ')'
			}));
		},
		selectedCurrency() {
			return this.formData?.currencyIsoCode;
		},
		consignments() {
			const consignments = [];

			_.forEach(this.fulfilments, f => {
				_.merge(consignments, f.consignments);
			});

			return consignments;
		},
		packages() {
			const packages = [];

			_.forEach(this.consignments, f => {
				_.merge(packages, f.packages);
			});

			return packages;
		},
		currencyDescription() {
			const currency = this.currencies?.find(currency => currency.id === this.formData?.currencyIsoCode);
			if (currency) {
				return currency.name + ' - ' + currency._id + ' (' + currency.symbol + ')';
			}
			return this.$t('Unknown Currency');
		},
		breadcrumbs() {
			return [
				{
					text: this.$t('Orders'),
					to: {
						name: 'fulfilment.all'
					}
				},
				{
					text: this.pageTitle,
					active: true
				}
			];
		},
		isCancelButtonVisible() {
			return !['shipped', 'rejected', 'cancelled'].includes(this.status);
		},
		isRejectButtonVisible() {
			return this.isFulfilmentAdmin && this.status === 'error';
		}
	},
	watch: {
		$route: 'fetchData',
		id: 'fetchData',
		fulfilmentOrder: {
			immediate: true,
			handler(value) {
				// If the material updates, update the form data
				this.setFormData(value);
			}
		}
	},
	mounted() {
		this.fetchData();
	},
	filters: { duration },
	methods: {
		getShipmentServiceLevelText,
		...mapActions({
			findFulfilmentOrder: 'fulfilmentOrder/findById',
			getShipmentsForOrder: 'fulfilmentOrder/getShipmentsForOrder',
			cancelOrderRequest: 'fulfilmentOrder/cancelOrder',
			rejectOrderRequest: 'fulfilmentOrder/rejectOrder',
			getFulfilments: 'fulfilmentOrder/getFulfilments',
			findItemsByOrderId: 'fulfilmentItem/findByOrderId',
			updateFormSummary: 'form/updateFormSummary',
			getCurrencies: 'currency/findAll'
		}),
		async fetchData() {
			this.isLoading = true;
			try {
				if (this.id) {
					await this.findFulfilmentOrder({ id: this.id });
					this.shipments = await this.getShipmentsForOrder(this.id);
					this.fulfilments = await this.getFulfilments(this.id);
					this.fulfilmentItems = await this.findItemsByOrderId({ id: this.id });
					await this.getCurrencies();
				}
			} catch (err) {
				this.$notify({ type: 'error', text: displayError(err) });
				this.$router.push({ name: 'fulfilment.orders' });
			} finally {
				this.isLoading = false;
			}
		},
		async save() {
			try {
				if (this.id) {
					await this.updateFulfilmentOrder({
						id: this.id,
						data: this.formData
					});
					this.$notify({ type: 'success', text: this.$t('Material updated successfully') });
				}
			} catch (err) {
				this.$notify({ type: 'error', text: displayError(err) });
			} finally {
				this.$router.push({ name: 'fulfilment.orders' });
			}
		},
		closeFulfilmentItemModal() {
			this.isFulfilmentItemModalVisible = false;
		},
		generateShippingAddress(address) {
			if (!address) return '';
			return [
				'name',
				'companyName',
				'address1',
				'address2',
				'address3',
				'postcode',
				'town',
				'isoCountry',
				'state'
			]
				.reduce((acc, key) => (address[key] ? [...acc, address[key]] : acc), [])
				.join(', ');
		},
		cancelOrder() {
			this.$bvModal
				.msgBoxConfirm(this.$t('Are you sure you want to cancel this order?'), {
					title: this.$t('Cancel order'),
					okTitle: this.$t('Confirm'),
					cancelTitle: this.$t('Cancel'),
					centered: true
				})
				.then(confirmed => {
					if (confirmed) this.handleCancelOrder();
					else this.$notify({ type: 'error', text: this.$t('Order has not been cancelled') });
				})
				.catch(err => {
					const message = displayError(err);
					this.$notify({ type: 'error ', text: message });
				});
		},
		async handleCancelOrder() {
			try {
				await this.cancelOrderRequest(this.id);
				await this.fetchData();
				this.$notify({
					type: 'success',
					title: 'Success',
					text: this.$t('Order has been cancelled successfully')
				});
			} catch (err) {
				this.$notify({
					type: 'error',
					title: this.$t('Error'),
					text: this.$t('An error occurred while cancelling order')
				});
			}
		},
		rejectOrder() {
			this.$bvModal
				.msgBoxConfirm(this.$t('Are you sure you want to reject this order?'), {
					title: this.$t('Reject order'),
					okTitle: this.$t('Confirm'),
					cancelTitle: this.$t('Cancel'),
					centered: true
				})
				.then(confirmed => {
					if (confirmed) this.handleRejectOrder();
					else this.$notify({ type: 'error', text: this.$t('Order has not been rejected') });
				})
				.catch(err => {
					const message = displayError(err);
					this.$notify({ type: 'error ', text: message });
				});
		},
		async handleRejectOrder() {
			try {
				await this.rejectOrderRequest(this.id);
				await this.fetchData();
				this.$notify({
					type: 'success',
					title: 'Success',
					text: this.$t('Order has been rejected successfully')
				});
			} catch (err) {
				this.$notify({
					type: 'error',
					title: this.$t('Error'),
					text: this.$t('An error occurred while rejecting order')
				});
			}
		},
		getShipmentById(shipmentId) {
			return _.find(this.shipments, {
				_id: shipmentId
			});
		},
		valueToInt,
		valueToFloat,
		getBadgeStatusText
	}
};
</script>

<style lang="scss">
@import '~@workflow-solutions/ofs-vue-layout/dist/style/variables';

.GradeOptions {
	display: flex;
	align-items: center;
}

.GradeUnit {
	height: 100%;
	border-radius: 0 3px 3px 0;
}

.RemoveGrade {
	display: flex;
	flex: 1;
	height: 100%;
	align-items: center;
	min-width: 30px;
	width: 30px;
	justify-content: flex-end;

	&Icon {
		color: $of-color-grey-2;
	}

	&:hover {
		.RemoveGradeIcon {
			color: $of-color-grey-1;
		}
	}
}

.CurrencyName {
	margin: 0 0 7px 10px;
}

.OrderView {
	&_Reject {
		margin-right: 30px;
	}
}
</style>
