<template>
	<DefaultLayout>
		<ofs-panel>
			<ContentHeader :no-padding="true" :title="$t('Providers')" />
			<section>
				<!--<SearchInput @input="onSearchChange" :placeholder="$t('Search all files')" class="mt-3 pt-3"/>-->
				<b-row v-if="isLoading" class="Providers-loader d-flex justify-content-center align-items-center">
					<Loader />
				</b-row>
				<ListTable
					v-else
					:items="specifications"
					:fields="fields"
					:total-items="specificationsCount"
					:per-page="perPage"
					:current-page="currentPage"
					:config="config"
					:fetch-data="fetchData"
					:page-position-prefix="$t('Showing')"
					:page-position-join="$t('of')"
					class="w-100"
					@table-change="handleTableChange"
				>
					<template slot="prev-text" slot-scope="{}">
						{{ $t('Prev') }}
					</template>
					<template slot="next-text" slot-scope="{}">
						{{ $t('Next') }}
					</template>
					<template slot="empty">
						<span>
							<i>{{ $t('No Data') }}</i>
						</span>
					</template>
					<template v-for="psp in pspAccounts" #[`cell(${psp._id})`]="{ item }">
						<section :key="psp._id" class="Providers-tableIcon h-100">
							<icon
								v-if="findSku(item.sourceSpecId, psp._id)"
								name="custom-check"
								class="text-primary"
								scale="1.2"
							/>
							<icon v-else name="custom-minus" scale="1.2" class="Providers-tableIconMuted" />
							<!--<b-dropdown v-else variant="link" size="lg" no-caret menu-class="p-0">
							<template #button-content>
								<icon
									v-b-tooltip.hover
									:title="$t('Actions')"
									name="custom-minus"
									scale="1.2"
									class="Providers-tableIconMuted"/>
							</template>
							<b-dropdown-item @click="onClickInvite">Invite</b-dropdown-item>
							<b-dropdown-item @click="onClickSpineAjust">Spine Adjust</b-dropdown-item>
						</b-dropdown>-->
						</section>
					</template>
				</ListTable>
			</section>
		</ofs-panel>
	</DefaultLayout>
</template>

<script>
import Promise from 'bluebird';
import { mapGetters, mapActions } from 'vuex';
import find from 'lodash/find';
import { ListTable, ContentHeader, OfsPanel } from '@workflow-solutions/ofs-vue-layout';
import Loader from 'src/components/Loader';
import DefaultLayout from '../../components/DefaultLayout';
import { featureFlagCheckMixin } from '../../mixins/featureFlagCheck';

export default {
	name: 'Providers',
	components: {
		DefaultLayout,
		ListTable,
		ContentHeader,
		Loader,
		OfsPanel
	},
	mixins: [ featureFlagCheckMixin('piazza-legacy', 'piazza') ],
	data() {
		return {
			isLoading: false,
			search: '',
			config: {
				filter: { visible: false },
				search: { visible: false },
				breadcrumbs: { visible: false },
				add: { visible: false },
				refresh: { visible: false },
				columns: { visible: false }
			},
			perPage: 50,
			sort: '',
			skus: [],
			currentPage: 1,
			isBusy: false
		};
	},
	computed: {
		...mapGetters({
			specifications: 'specification/specifications',
			specificationsCount: 'specification/count',
			pspAccounts: 'account/pspAccounts'
		}),
		fields() {
			const base = { label: this.$t('Spec Code'), key: 'sourceSpecId', sortable: true };
			const pspAccounts = this.pspAccounts.map(psp => ({ label: psp.name, key: psp._id }));
			return [base, ...pspAccounts];
		}
	},
	async mounted() {
		this.fetchData();
	},
	methods: {
		...mapActions({
			findSpecifications: 'specification/find',
			countSpecifications: 'specification/count',
			getPspAccounts: 'account/getPspAccounts',
			getSkus: 'sku/find'
		}),
		async fetchData() {
			const query = {
				query: {
					type: 'Book',
					$limit: this.perPage,
					$skip: this.perPage * (this.currentPage - 1),
					$sort: this.sort
				}
			};

			try {
				this.isLoading = true;
				this.fetchSkus(1);
				await Promise.all([
					this.getPspAccounts(),
					this.findSpecifications({ query }),
					this.countSpecifications({ query })
				]);
			} catch (err) {
				this.$toaster.error(this.$t('Error occurred during request providers'), { timeout: 3000 });
			} finally {
				this.isLoading = false;
			}
		},
		async fetchSkus(page) {
			const query = { query: { page, pagesize: 50 } };
			const { data = [], pages } = await this.getSkus(query);
			this.skus = this.skus.concat(data);
			if (pages > page) {
				this.fetchSkus(page + 1);
			}
		},
		handleTableChange({ currentPage, perPage, sort }) {
			this.currentPage = currentPage;
			this.perPage = perPage;
			if (sort) {
				this.sort = sort;
			}
		},
		onSearchChange() {
			//	TODO Search functionality
		},
		onClickInvite() {
			//	TODO Invite functionality
		},
		onClickSpineAjust() {
			//	TODO Implement Spine Ajust functionality
		},
		findSku(specCode, accountId) {
			return find(this.skus, { code: specCode, accountId });
		}
	}
};
</script>

<style lang="scss">
.Providers {
	&-loader {
		height: 40vh;
	}

	&-table {
		&Icon {
			&Muted {
				color: #bdbdbd;
			}
		}

		.Datatable-table {
			overflow-x: auto;
		}
	}
}
</style>
