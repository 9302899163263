<template>
	<ConfirmModal v-if="!!confirmModal && showModal" :options="confirmModal" :cancel="cancelConfirmModal">
		<template #default>
			<div class="d-flex flex-wrap justify-content-start align-items-center">
				<ofs-badge :text="path.name" class="mr-1 mb-1" />
			</div>
		</template>
	</ConfirmModal>
</template>
<script>
import { mapActions } from 'vuex';
import trim from 'lodash/trim';
import { OfsBadge } from '@workflow-solutions/ofs-vue-layout';
import ConfirmModal from '../../../components/modals/ConfirmModal';
import { i18n } from '../../../vuex';

const $t = str => i18n.t(str);
export default {
	components: {
		ConfirmModal,
		OfsBadge
	},
	props: {
		showModal: {
			type: Boolean,
			default: false
		},
		onClose: {
			type: Function,
			default: () => {}
		},
		path: {
			type: Object,
			default: () => {}
		},
		currentRoutePath: {
			type: String,
			default: ''
		},
		refresh: {
			type: Function,
			default: () => {}
		}
	},
	data() {
		return {
			confirmModal: null
		};
	},
	mounted() {
		this.openConfirmModal({
			title: $t('Duplicate file'),
			text: $t('Are you sure you want to duplicate?'),
			type: 'info',
			showCancelButton: true,
			confirmType: 'danger',
			confirmText: $t('Yes'),
			closeOnConfirm: true,
			success: async () => {
				try {
					const data = {
						createParent: true,
						check: true,
						path: { name: this.path.name, type: 'file' },
						file: {
							name: this.path.file.name,
							size: this.path.file.currentVersion.size,
							contentType: this.path.file.contentType,
							s3Path: this.path.file.currentVersion.s3Path
						}
					};

					const pathParts = this.path.path
						? this.path.path.split('/')
						: [...(this.currentRoutePath || '').split('/'), this.path.name];

					pathParts.pop();
					const folder = trim(pathParts.join('/'), '/');
					await this.createFile({ data, folder });
					this.refresh();
					this.$toaster.success($t('Duplicated successfully'), { timeout: 2000 });
					this.$nextTick(() => this.onClose());
				} catch (err) {
					this.$toaster.error($t('Error during duplicate file'), { timeout: 2000 });
					throw err;
				}
			}
		});
	},
	methods: {
		...mapActions({
			createFile: 'file/createFile',
			fileCheck: 'file/fileCheck'
		}),
		openConfirmModal(options) {
			this.confirmModal = options;
		},
		cancelConfirmModal() {
			this.onClose();
			this.confirmModal = null;
		}
	}
};
</script>
