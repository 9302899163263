<template>
	<div class="FileDetail">
		<section class="FileDetail__info">
			<aside class="FileDetail__aside">
				<div v-if="!file.localFile" class="FilePreview FileDetail__preview">
					<template v-for="(thumbnail, index) in thumbnails">
						<img
							v-if="shouldShowThumbnail(index)"
							:key="index"
							v-img-error
							class="hidden"
							:src="thumbnail"
							@error="showPlaceholder(index)"
						/>
					</template>
					<ul class="FilePreview__thumbnails">
						<!--Hidden image to listen to error events-->
						<Loader v-if="processingFile" class="FilePreview__loader" />
						<li
							v-for="(thumbnail, index) in thumbnails"
							v-else
							:key="`${thumbnail}-${index}`"
							class="FilePreview__thumbnail"
							:class="{
								'FilePreview__thumbnail--active': index === thumbnailIndex,
								'FilePreview__thumbnail--unavailable': !shouldShowThumbnail(index),
								'FilePreview__thumbnail--noImage': thumbnail.includes('no-image')
							}"
							:data-unavailable-title="$t('No Preview')"
							:data-unavailable-text="$t('Download file to view this page')"
							:style="{
								backgroundImage: shouldShowThumbnail(index) ? `url('${thumbnail}')` : 'none'
							}"
						/>
					</ul>
					<div v-if="thumbnails.length > 1" class="FilePreview__actions">
						<b-button class="FilePreview__action" @click="previousThumbnail">
							<font-awesome-icon icon="angle-left" />
						</b-button>
						<span class="FilePreview__status" v-text="`${thumbnailIndex + 1}/${thumbnails.length}`" />
						<b-button class="FilePreview__action" @click="nextThumbnail">
							<font-awesome-icon icon="angle-right" />
						</b-button>
					</div>
				</div>
				<div v-else class="FilePreview FileDetail__preview">
					<ul class="FilePreview__thumbnails">
						<li class="FilePreview__thumbnail FilePreview__thumbnail--active">
							<font-awesome-icon class="FilePreview__thumbnail-icon" icon="hdd" />
							<span v-t="'Local File'" />
						</li>
					</ul>
				</div>
				<!-- <ul v-if="!hideFileActions" class="FileActions">
					<li class="FileActions_item">
						<h3 v-t="'View More'" class="FileActions_heading" />
						<router-link
							v-if="file.infotechFileId"
							:to="{ name: 'file.view', params: { id: file.infotechFileId } }"
						>
							{{ $t('View Infotech') }}
						</router-link>
						<router-link :to="{ name: 'file.view', params: { id: file._id } }">
							{{ $t('View File') }}
						</router-link>
					</li>
					<li v-if="downloadUrl || infotechUrl" class="FileActions_item">
						<h3 v-t="'Download'" class="FileActions_heading" />
						<b-link v-if="infotechUrl" variant="link" :href="infotechUrl">
							{{ $t('Download Infotech') }}
						</b-link>
						<b-link v-if="downloadUrl" variant="link" :href="downloadUrl">
							{{ $t('Download File') }}
						</b-link>
					</li>
				</ul> -->
				<ul class="FileActions">
					<li class="FileActions_item">
						<h3 v-t="'File Actions'" class="FileActions_heading" />
						<b-link
							variant="link"
							data-test-id="fileModalVisualInspectorLink"
							@click="handleOpenPreviewerBtnClick"
						>
							{{ $t('Visual Inspector') }}
						</b-link>
					</li>
					<li v-if="downloadUrl || infotechUrl" class="FileActions_item">
						<h3 v-t="'Download'" class="FileActions_heading" />
						<b-link
							v-if="infotechUrl"
							variant="link"
							data-test-id="fileModalDownloadInfotechLink"
							:href="infotechUrl"
							target="_blank"
						>
							{{ $t('Download Infotech') }}
						</b-link>
						<b-link
							v-if="downloadUrl"
							variant="link"
							data-test-id="fileModalDownloadFileLink"
							:href="downloadUrl"
							target="_blank"
						>
							{{ $t('Download File') }}
						</b-link>
					</li>
				</ul>
			</aside>
			<div class="FileDetail__specs">
				<section
					class="FileDetailSpec"
					:class="{
						'FileDetailSpec--full': componentType === '3d' || (!processColors.length && !spotColors.length)
					}"
				>
					<h3 v-t="'Document'" class="FileDetailSpec__title" />
					<ul class="FileDetailSpec__list">
						<li class="FileDetailSpec__item">
							<p
								v-if="currentVersion.revision || file.currentRevision"
								class="FileDetailSpec__value"
								data-test-id="fileModalFileVersion"
							>
								<span v-t="'Version:'" class="FileDetailSpec__key" />
								{{ currentVersion.revision || file.currentRevision }}
							</p>
							<p
								v-if="currentVersion.contentType"
								class="FileDetailSpec__value"
								data-test-id="fileModalFileType"
							>
								<span v-t="'Type:'" class="FileDetailSpec__key" />
								{{ currentVersion.contentType }}
							</p>
							<p
								v-if="componentType !== '3d' && (file.pageCount || currentVersion.pageCount)"
								class="FileDetailSpec__value"
								data-test-id="fileModalFilePageCount"
							>
								<span v-t="'Page Count:'" class="FileDetailSpec__key" />
								{{ currentVersion.pageCount || file.pageCount }}
							</p>
							<p
								v-if="currentVersion.fileSize"
								class="FileDetailSpec__value"
								data-test-id="fileModalFileSize"
							>
								<span v-t="'File Size:'" class="FileDetailSpec__key" />
								{{ currentVersion.fileSize | fileSize }}
							</p>
							<p
								v-if="file.cleaned && file.cleaned.version"
								class="FileDetailSpec__value"
								data-test-id="fileModalFileDateCleaned"
							>
								<span v-t="'Date Cleaned:'" class="FileDetailSpec__key" />
								{{ file.cleaned.lastTime | date }}
							</p>
							<p class="FileDetailSpec__value" data-test-id="fileModalFileDateCreated">
								<span v-t="'Date Created:'" class="FileDetailSpec__key" />
								{{ file.created | date }}
							</p>
							<p class="FileDetailSpec__value" data-test-id="fileModalFileDateModified">
								<span v-t="'Date Modified:'" class="FileDetailSpec__key" />
								{{ file.updatedAt | date }}
							</p>
							<p class="FileDetailSpec__value" data-test-id="fileModalFileStatus">
								<span v-t="'Status:'" class="FileDetailSpec__key" />
								<ofs-badge
									class="d-inline-flex"
									:status="file.cleaned && file.cleaned.version ? $t('cleaned') : file.status"
									:text="file.cleaned && file.cleaned.version ? $t('cleaned') : file.status"
								>
								</ofs-badge>
							</p>
						</li>
					</ul>
				</section>
				<section
					v-if="componentType !== '3d' && (processColors.length || spotColors.length)"
					class="FileDetailSpec"
					data-test-id="fileModalProcessInks"
				>
					<template v-if="processColors.length">
						<h3 class="FileDetailSpec__title">
							{{ $t('Process Inks') }}
						</h3>
						<ul class="FileDetailSpec__list">
							<li v-for="color in processColors" :key="color" class="FileDetailSpec__item">
								<p class="FileDetailSpec__colour">
									<span
										class="FileDetailSpec__swatch"
										:style="{ background: color }"
										v-text="color"
									/>
									{{ color }}
								</p>
							</li>
						</ul>
					</template>
					<template v-if="spotColors.length">
						<h3 class="FileDetailSpec__title mt-3">
							{{ $t('Spot Inks') }}
						</h3>
						<ul class="FileDetailSpec__list">
							<li v-for="color in spotColors" :key="color" class="FileDetailSpec__item">
								<p class="FileDetailSpec__colour">
									<span
										class="FileDetailSpec__swatch"
										:style="{ background: color }"
										v-text="color"
									/>
									{{ color }}
								</p>
							</li>
						</ul>
					</template>
				</section>
				<section
					v-if="
						currentVersion &&
							currentVersion.pageConfigs &&
							currentVersion.pageConfigs.length &&
							componentType !== '3d'
					"
					class="FileDetailSpec FileDetailSpec--full"
				>
					<h2 class="FileDetailSpec__heading">
						{{ $t('PDF Boxes') }}
					</h2>
					<div class="FileDetailSpec__boxes">
						<section
							v-for="(config, index) in currentVersion.pageConfigs"
							:key="`config-${index}`"
							class="FileDetailSpec__box"
						>
							<h3
								v-if="hasMultiplePageBoxes"
								class="FileDetailSpec__title FileDetailSpec__title--no-margin"
								v-text="`Type ${index + 1}`"
							/>
							<ul v-if="hasMultiplePageBoxes" class="FileDetailSpec__list">
								<li class="FileDetailSpec__item">
									<p class="FileDetailSpec__value">
										<span class="FileDetailSpec__key FileDetailSpec__key--fluid">
											{{ $t('Pages:') }}
										</span>
										{{ getPageRangeStringForConfig(index) }}
									</p>
								</li>
							</ul>
							<measurements
								class="FileDetailSpec__measurements"
								:bleed="getBoxSize(config.boxes.bleed)"
								:trim="getBoxSize(config.boxes.trim)"
								:media="getBoxSize(config.boxes.media)"
							/>
						</section>
					</div>
				</section>
			</div>
		</section>
	</div>
</template>

<script>
import _find from 'lodash/find';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import moment from 'moment';
import { OfsBadge } from '@workflow-solutions/ofs-vue-layout';
import { mapActions, mapGetters } from 'vuex';
import { fileSize } from '../../lib/filters';
import Measurements from './Measurements';
import Loader from '../../components/Loader';
import imageMissing from '../../../public/img/no-image.svg';
import { PageSelector } from '../../lib/PageSelector';

const processColors = ['cyan', 'magenta', 'yellow', 'black'];

export default {
	components: {
		Measurements,
		Loader,
		OfsBadge
	},
	filters: {
		fileSize,
		date(time) {
			return moment(time).accountFormat(' LT');
		}
	},
	props: {
		file: {
			type: Object,
			required: true
		},
		selectedVersionId: {
			type: String,
			default: undefined
		},
		hideFileActions: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			thumbnails: [],
			thumbnailIndex: 0,
			thumbnailRange: []
		};
	},
	computed: {
		...mapGetters({
			downloadUrl: 'file/downloadUrl',
			infotechUrl: 'file/infotechUrl'
		}),
		currentVersion() {
			if (this.selectedVersionId) return _find(this.file.versions, v => v._id === this.selectedVersionId) || {};
			return _find(this.file.versions, v => v._id === this.file.currentVersionId) || {};
		},
		hasMultiplePageBoxes() {
			return this.currentVersion && this.currentVersion.pageConfigs && this.currentVersion.pageConfigs.length > 1;
		},
		processColors() {
			if (this.currentVersion && this.currentVersion.pageConfigs) {
				// Using sets ensures unique values
				const colors = new Set();

				this.currentVersion.pageConfigs.forEach(config => {
					config.colors.forEach(color => {
						const colorKey = color.toLowerCase();

						if (processColors.includes(colorKey)) {
							colors.add(color.charAt(0).toUpperCase() + color.slice(1));
						}
					});
				});

				return Array.from(colors);
			}

			return [];
		},
		spotColors() {
			if (this.currentVersion && this.currentVersion.pageConfigs) {
				// Using sets ensures unique values
				const colors = new Set();

				this.currentVersion.pageConfigs.forEach(config => {
					config.colors.forEach(color => {
						const colorKey = color.toLowerCase();

						if (!processColors.includes(colorKey)) {
							colors.add(color.charAt(0).toUpperCase() + color.slice(1));
						}
					});
				});

				return Array.from(colors);
			}

			return [];
		},
		job() {
			if (this.order) {
				return _find(this.order.jobs, { fileId: this.component.fileId });
			}

			return null;
		},
		processingFile() {
			return this.file.status === 'notreceived' && !this.componentFileThumbnail;
		},
		componentFileThumbnail() {
			return _get(this.file, 'thumbnailUrl');
		},
		componentType() {
			return _get(this.file, 'componentType');
		}
	},
	watch: {
		async file() {
			await this.handleFileChange();
		}
	},
	async mounted() {
		this.handleFileChange();
		if (this.componentFileThumbnail && _isEmpty(this.thumbnails)) {
			this.thumbnails = [this.componentFileThumbnail];
		}
	},
	methods: {
		...mapActions({
			downloadFile: 'order/downloadFile',
			getDownload: 'order/getDownload',
			getThumbnails: 'order/getThumbnails',
			updateFile: 'file/updateFile',
			resetFile: 'file/reset',
			setDownloadUrl: 'file/setDownloadUrl',
			setInfotechUrl: 'file/setInfotechUrl'
		}),
		...mapActions('previewer', { openPreviewer: 'show', closePreviewer: 'hide' }),
		async handleOpenPreviewerBtnClick() {
			const downloadUrl = await this.downloadFile(this.file._id);

			return this.openPreviewer({
				url: downloadUrl,
				hasClose: true,
				onClose: this.closePreviewer
			});
		},
		showPlaceholder(index) {
			this.$set(this.thumbnails, index, imageMissing);
		},
		parseThumbnailRange() {
			this.currentVersion.thumbnailRange = null;
			const pageCount = this.currentVersion.pageCount || 1;
			const defaultRange =
				this.componentType === '3d'
					? PageSelector.getPages(pageCount, '1')
					: PageSelector.getPages(pageCount, '1-5,R2,R1');
			try {
				const range = PageSelector.getPages(pageCount, this.currentVersion.thumbnailRange);
				this.thumbnailRange = range && range.length ? range : defaultRange;
			} catch {
				this.thumbnailRange = defaultRange;
			}
		},
		shouldShowThumbnail(index) {
			return this.thumbnailRange.includes(index);
		},
		nextThumbnail() {
			if (this.thumbnailIndex === this.thumbnails.length - 1) {
				this.thumbnailIndex = 0;
			} else {
				this.thumbnailIndex++;
			}
		},
		previousThumbnail() {
			if (this.thumbnailIndex === 0) {
				this.thumbnailIndex = this.thumbnails.length - 1;
			} else {
				this.thumbnailIndex--;
			}
		},
		getBoxSize(box) {
			if (box) {
				return {
					width: Math.round(box.right - box.left),
					height: Math.round(box.top - box.bottom)
				};
			}

			return null;
		},
		getPagesForConfig(index) {
			if (!this.currentVersion || _isEmpty(this.currentVersion)) return [];
			const pageConfig = this.currentVersion ? this.currentVersion.pageConfigs[index] : null;
			const pages = [];

			if (!pageConfig) {
				return '-';
			}

			// Iterate over pages and collate all matching config
			_forEach(this.currentVersion.pages, (config, page) => {
				if (config === index) pages.push(page);
			});

			// Ensure page numbers are in order
			pages.sort((a, b) => a - b);

			return pages;
		},
		// Generates a formatted string of pages e.g. 1-49, 51-100
		getPageRangeStringForConfig(index) {
			const pages = this.getPagesForConfig(index);
			const pageGroups = [];
			let lastPage = pages[0];
			let lastPageGroup = [];

			// Group adjoining blocks of pages
			pages.forEach((page, i) => {
				const diff = Math.abs(page - lastPage);

				if (diff > 1) {
					pageGroups.push(lastPageGroup);
					lastPageGroup = [page];
				} else if (i === pages.length - 1) {
					lastPageGroup.push(page);
					pageGroups.push(lastPageGroup);
				} else {
					lastPageGroup.push(page);
				}

				lastPage = page;
			});

			// Format the groups of pages into a string
			const pageGroupStrings = [];

			pageGroups.forEach(group => {
				if (group.length > 1) {
					pageGroupStrings.push(`${group[0] + 1}-${group[group.length - 1] + 1}`);
				} else {
					pageGroupStrings.push(`${group[0] + 1}`);
				}
			});

			return pageGroupStrings.join(', ');
		},
		async handleFileChange() {
			this.parseThumbnailRange();
			if (this.file.status === 'ready' && !this.file.localFile) {
				const filename = _get(this.file, 'path', 'file').split('?')[0];
				const downloadUrl = await this.getDownload({
					fileId: this.file._id,
					params: {
						filename,
						preview: true
					}
				});
				this.setDownloadUrl({ url: downloadUrl });
			} else {
				this.setDownloadUrl({ url: null });
			}

			if (this.file.infotechFileId) {
				const infotechUrl = await this.getDownload({
					fileId: this.file.infotechFileId,
					params: {
						filename: 'infotech',
						preview: true
					}
				});

				this.setInfotechUrl({ url: infotechUrl });
			} else {
				this.setInfotechUrl({ url: null });
			}
			const thumbnails = await this.getThumbnails(this.file._id);
			if (thumbnails && thumbnails.length) this.thumbnails = thumbnails;
		}
	}
};
</script>

<style lang="scss">
@import '~@workflow-solutions/ofs-vue-layout/dist/style/variables';
@import '~@workflow-solutions/ofs-vue-layout/dist/style/mixins';

.FileDetail {
	&__inner {
		padding: 0 20px;
	}

	.hidden {
		opacity: 0;
		pointer-events: none;
		position: absolute;
	}

	.modal-dialog {
		max-width: 910px;
	}

	&__info {
		flex: 1;
		padding: 0;
		display: flex;
		flex-direction: column;

		@media all and (min-width: 600px) {
			flex-direction: row;
		}
	}

	&__button {
		display: flex;
		align-items: center;
		justify-content: center;

		&-icon {
			display: inline-flex;
			width: 16px;
			height: 16px;
			margin: 0 0 1px 5px;
		}
	}

	&__specs {
		padding: 0 20px 20px;
		display: flex;
		flex-wrap: wrap;
		flex: 1;
		overflow: hidden;

		@media all and (min-width: 600px) {
			padding: 20px 0 0;
			flex-direction: row;
		}

		.FileDetailSpec {
			margin-bottom: 20px;
			max-width: 100%;

			@media all and (min-width: 600px) {
				max-width: 50%;
			}

			&--full {
				max-width: 100%;
				width: 100%;
				padding: 0;
			}
		}
	}

	&__aside {
		padding: 20px 20px 20px 0;
		display: flex;
		flex-direction: column;
		align-items: center;

		@media all and (min-width: 600px) {
			margin-bottom: 0;
		}

		@media all and (min-width: 1000px) {
			margin-right: 20px;
		}
	}

	&__actions {
		display: flex;
		flex-direction: column;
		max-width: 160px;
	}
}

.FilePreview {
	display: flex;
	position: relative;
	background: rgba(#29c6ff, 0.1);
	padding: 20px;
	border: 1px solid #ededf5;
	border-radius: 3px;
	margin-bottom: 20px;
	flex-direction: column;

	&.error {
		background-color: #f8eded;
		border: solid 1px #e3b0b3;
	}

	&__actions {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		list-style: none;
		margin: 0;
		padding: 20px 0 0;
	}

	&__action {
		border-radius: 50%;
		width: 26px;
		height: 26px;
		padding: 0;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	&__status {
		font-size: $of-font-size-small;
		padding: 0 20px;
	}

	&__thumbnails {
		flex: 1;
		position: relative;
		margin: 0;
		padding: 0;
		list-style: none;
		min-width: 200px;
		min-height: 250px;

		@media all and (min-width: 800px) {
			min-width: 260px;
			min-height: 300px;
		}
	}

	&__thumbnail {
		background-position: 50%;
		background-size: contain;
		background-repeat: no-repeat;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
		transition: opacity 0.2s ease-in-out;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		font-size: 10px;
		font-weight: 600;
		color: desaturate(darken($of-color-highlights, 20), 60);

		&-icon {
			margin-bottom: 5px;
			min-width: 20px;
			min-height: 20px;
		}

		&--active {
			opacity: 1;
		}

		&--noImage {
			background-size: auto;
		}

		&--unavailable {
			display: flex;
			flex-direction: column;
			font-size: $of-font-size-small;

			&:before {
				content: attr(data-unavailable-title);
				text-transform: uppercase;
				letter-spacing: 1px;
				opacity: 0.8;
			}

			&:after {
				content: attr(data-unavailable-text);
				font-weight: normal;
				opacity: 0.6;
			}
		}

		span {
			font-size: 10px;
			font-weight: 600;
			text-transform: uppercase;
		}
	}

	&__loader {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

.FileDetailSpec {
	padding-right: 20px;

	&__heading {
		@include ofTextStyleSubheading();
		margin: 0;
		padding: 0;
	}

	&__title {
		@include ofTextStyleDescriptions();
		margin: 0 0 10px;
		padding: 0;

		&--no-margin {
			margin: 0;
		}
	}

	&__note {
		font-size: $of-font-size-smaller;
		color: $of-color-grey-3;
	}

	&__key {
		font-size: $of-font-size-smaller;
		color: $of-color-grey-2;
		min-width: 100px;
		display: inline-block;

		&--fluid {
			min-width: auto;
		}
	}

	&__value {
		font-size: $of-font-size-smaller;
		color: $of-color-dark;
		margin: 0;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		width: 100%;
	}

	&__boxes {
		display: flex;
		flex-wrap: wrap;
	}

	&__box {
		margin-top: 20px;
		padding-right: 20px;
		flex-basis: 100%;

		@media all and (min-width: 500px) {
			flex-basis: 50%;
		}
	}

	&__measurements {
		min-height: 200px;
		max-width: 180px;
	}

	&__list {
		list-style: none;
		margin: 0;
		padding: 0;
	}

	&__item {
		margin: 0;
		padding: 0;
		line-height: 1.8;
	}

	&__colour {
		display: flex;
		align-items: center;
		margin: 0;
		line-height: 1.8;
		font-size: $of-font-size-smaller;
		color: $of-color-dark;
	}

	&__swatch {
		width: 15px;
		height: 15px;
		text-indent: -999px;
		overflow: hidden;
		border-radius: 50%;
		display: inline-block;
		margin-right: 20px;
		background: rgba(#000000, 0.05);
	}
}

.FileActions {
	background: $of-color-grey-4;
	border-top: 2px solid $of-color-blue;
	width: 100%;
	display: flex;
	flex-direction: row;
	list-style: none;
	margin: 0;
	padding: 0;

	&_item {
		list-style: none;
		margin: 0;
		padding: 20px;
		display: flex;
		flex-direction: column;
	}

	&_heading {
		@include ofTextStyleDescriptions();
	}
}
</style>
