<template>
	<b-modal
		v-model="shouldShowModal"
		modal-class="ShipmentModal"
		:title="$t('Edit Shipment')"
		size="lg"
		@shown="onShow"
	>
		<b-form v-if="shipment" novalidate>
			<b-row v-if="orderIsPending">
				<b-col>
					<of-toggle
						name="canShipEarly"
						data-test-id="shipmentModalCanShipEarly"
						:right-side-label="$t('Can Ship Early')"
					/>
				</b-col>
			</b-row>
			<b-row>
				<b-col v-if="orderIsPending">
					<datepicker-input
						v-model="shipByDate"
						class="mb-0"
						data-test-id="shipmentModalShipByDate"
						:label="$t('Ship By Date')"
						:placeholder="$t('Date')"
					/>
				</b-col>
				<b-col cols="6">
					<of-multi-select
						v-if="!orderIsPending"
						class="mb-0"
						name="carrier.serviceId"
						data-test-id="shipmentModalShippingMethods"
						:label="$t('Shipping Alias')"
						:options="shippingSelectOptions"
						:placeholder="$t('Select option')"
					/>
					<template v-else>
						<of-form-input
							v-if="isEnteringNewShippingMethod"
							class="mb-0"
							name="carrier.alias"
							:label="$t('Shipping Alias')"
							required
							:description="$t('The alias for the shipping method you want to use')"
						/>
						<template v-else>
							<of-multi-select
								class="mb-2"
								name="carrier.alias"
								data-test-id="shipmentModalShippingMethods"
								:label="$t('Shipping Alias')"
								:allow-clear="false"
								:options="shippingAliases"
								:placeholder="$t('Select option')"
							/>
							<p class="mb-0">
								{{ $t('Shipping alias not displaying?') }}
								<a href="#" @click.prevent="newShippingMethod = true">{{ $t('Enter a new one') }}</a>
								{{ $t('or') }}
								<a href="#" @click.prevent="onClearShippingMethods">{{ $t('clear shipping aliases') }}</a>
							</p>
						</template>
					</template>
				</b-col>
			</b-row>
			<hr class="form-divider" />
			<h3 v-t="'Shipping Address'" class="mb-3"></h3>
			<b-row>
				<b-col>
					<of-form-input
						name="shipTo.name"
						data-test-id="shipmentModalShipToName"
						type="text"
						required
						:label="$t('Name')"
					/>
					<of-form-input
						name="shipTo.companyName"
						data-test-id="shipmentModalShipToCompany"
						type="text"
						:label="$t('Company')"
					/>
					<of-form-input
						name="shipTo.email"
						data-test-id="shipmentModalShipToEmail"
						type="text"
						:label="$t('Email')"
					/>
					<of-form-input
						required
						name="shipTo.address1"
						data-test-id="shipmentModalShipToAddress1"
						type="text"
						:label="$t('Address Line 1')"
					/>
					<of-form-input
						name="shipTo.address2"
						data-test-id="shipmentModalShipToAddress2"
						type="text"
						:label="$t('Address Line 2')"
					/>
					<of-form-input
						name="shipTo.address3"
						data-test-id="shipmentModalShipToAddress3"
						type="text"
						:label="$t('Address Line 3')"
					/>
				</b-col>
				<b-col>
					<of-form-input
						required
						name="shipTo.town"
						data-test-id="shipmentModalShipToTown"
						type="text"
						:label="$t('Town')"
					/>
					<of-form-input
						name="shipTo.state"
						data-test-id="shipmentModalShipToState"
						type="text"
						:label="$t('State')"
					/>
					<of-form-input
						name="shipTo.postcode"
						data-test-id="shipmentModalShipToPostcode"
						type="text"
						:label="$t('Postcode / ZIP')"
					/>
					<of-form-input
						name="shipTo.phone"
						data-test-id="shipmentModalShipToPhone"
						type="text"
						:label="$t('Phone')"
					/>

					<of-form-select
						required
						name="shipTo.isoCountry"
						data-test-id="shipmentModalShipToCountry"
						:options="countryOptions"
						:label="$t('Country')"
					/>
					<of-form-checkbox
						v-if="orderIsPending || shipment.returnAddress"
						v-model="displayReturnAddress"
						data-test-id="shipmentModalReturnAddressCheckbox"
						class="ShipmentModal-checkbox"
						:right-side-label="
							shipment.returnAddress ? $t('Show Return Address') : $t('Add Return Address')
						"
					></of-form-checkbox>
				</b-col>
			</b-row>
			<section v-if="displayReturnAddress">
				<hr class="form-divider" />
				<h3 v-t="'Return Address'" class="mb-3"></h3>
				<b-row class="mt-3">
					<b-col>
						<of-form-input
							name="returnAddress.name"
							data-test-id="shipmentModalReturnAddressName"
							type="text"
							:label="$t('Name')"
						/>
						<of-form-input
							name="returnAddress.companyName"
							data-test-id="shipmentModalReturnAddressCompany"
							type="text"
							:label="$t('Company')"
						/>
						<of-form-input
							required
							name="returnAddress.address1"
							data-test-id="shipmentModalReturnAddressAddress1"
							type="text"
							:label="$t('Address Line 1')"
						/>
						<of-form-input
							name="returnAddress.address2"
							data-test-id="shipmentModalReturnAddressAddress2"
							type="text"
							:label="$t('Address Line 2')"
						/>
						<of-form-input
							name="returnAddress.address3"
							data-test-id="shipmentModalReturnAddressAddress3"
							type="text"
							:label="$t('Address Line 3')"
						/>
					</b-col>
					<b-col>
						<of-form-input
							required
							name="returnAddress.town"
							data-test-id="shipmentModalReturnAddressTown"
							type="text"
							:label="$t('Town')"
						/>
						<of-form-input
							name="returnAddress.state"
							data-test-id="shipmentModalReturnAddressState"
							type="text"
							:label="$t('State')"
						/>
						<of-form-input
							name="returnAddress.postcode"
							data-test-id="shipmentModalReturnAddressPostcode"
							type="text"
							:label="$t('Postcode / ZIP')"
						/>
						<of-form-input
							name="returnAddress.phone"
							data-test-id="shipmentModalReturnAddressPhone"
							type="text"
							:label="$t('Phone')"
						/>
						<of-form-select
							required
							name="returnAddress.isoCountry"
							data-test-id="shipmentModalReturnAddressCountry"
							:options="countryOptions"
							:label="$t('Country')"
							class="mb-0"
						/>
					</b-col>
				</b-row>
			</section>
		</b-form>
		<template slot="modal-footer">
			<b-button @click="onClose">
				{{ $t('Cancel') }}
			</b-button>
			<b-button variant="primary" :disabled="!canSubmit" @click.prevent="update">
				{{ orderIsPending ? $t('Done') : $t('Save') }}
			</b-button>
		</template>
	</b-modal>
</template>

<script>
import _get from 'lodash/get';
import _set from 'lodash/set';
import _pick from 'lodash/pick';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import _map from 'lodash/map';
import _sortBy from 'lodash/sortBy';
import { required, email } from 'vuelidate/lib/validators';
import { mapActions, mapGetters } from 'vuex';
import { OfFormInput, OfFormSelect, OfMultiSelect, OfToggle, withForm, OfFormCheckbox } from '@workflow-solutions/ofs-vue-layout';
import DatepickerInput from '../DatepickerInput';
import { $t } from '../../../vuex';
import { displayError } from '../../../lib/helpers';
import { clearShippingMethods } from '../../../vuex/orders/actions';

export default {
	components: {
		OfFormInput,
		OfToggle,
		OfFormSelect,
		OfMultiSelect,
		DatepickerInput,
		OfFormCheckbox
	},
	mixins: [withForm('editShipmentForm')],
	props: {
		show: {
			type: Boolean,
			default: false
		},
		onClose: {
			type: Function,
			default: () => {}
		},
		shipment: {
			type: Object,
			required: true
		},
		orderStatus: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			currentShipment: null,
			countries: [],
			displayReturnAddress: false,
			newShippingMethod: false
		};
	},
	computed: {
		...mapGetters({
			getFormData: 'form/getFormData',
			orderConfigOptions: 'order/configOptions',
			order: 'order/order',
			currentAccount: 'account/currentAccount',
			shippingAliases: 'order/shippingMethods',
			vars: 'account/vars'
		}),
		shipByDate: {
			get() {
				return _get(this.formData, 'shipByDate');
			},
			set(value) {
				this.updateFormField({ formName: this.formName, fieldPath: 'shipByDate', value });
			}
		},
		isEnteringNewShippingMethod() {
			return this.shippingAliases.length === 0 || this.newShippingMethod;
		},
		orderFormData() {
			return this.getFormData({ formName: 'orderCreateForm' });
		},
		shipments() {
			return _get(this.orderFormData, 'orderData.shipments', []);
		},
		defaultReturnAddress() {
			const settings = _pick(this.vars.oneflowAccountSettings, [
				'companyName',
				'phoneNumber',
				'address1',
				'address2',
				'address3',
				'town',
				'state',
				'postcode',
				'isoCountry'
			]);
			delete Object.assign(settings, { ['phone']: settings['phoneNumber'] })['phoneNumber'];
			return settings;
		},
		orderId() {
			return _get(this.$route, 'params.id');
		},
		orderIsPending() {
			return !this.orderId;
		},
		shouldShowModal: {
			get() {
				return this.show;
			},
			set(show) {
				if (!show) {
					this.onClose();
				}
			}
		},
		shippingMethods() {
			return _get(this.orderConfigOptions, 'shippingMethods', []);
		},
		filteredShippingMethods() {
			// Hide inactive shipping methods
			let shippingMethods = _filter(this.shippingMethods, m => m.active);
			// Only show methods for source account
			let sourceId;
			if (this.orderIsPending) {
				sourceId = _get(this, 'currentAccount._id');
			} else {
				sourceId = _get(this, 'order.order.source.id');
			}
			if (sourceId) shippingMethods = _filter(shippingMethods, m => m.sourceAccountId === sourceId);
			return _sortBy(shippingMethods, 'code');
		},
		shippingSelectOptions() {
			return _map(this.filteredShippingMethods, method => {
				let text = `${method.code} / ${method.service}`;
				if (Array.isArray(method.aliases) && method.aliases.length) {
					text += ` (${method.aliases.join(', ')})`;
				}
				return {
					text,
					value: method._id
				};
			});
		},
		selectedShippingMethod() {
			const serviceId = _get(this.formData, 'carrier.serviceId');
			return _find(this.shippingMethods, { _id: serviceId });
		},
		countryOptions() {
			return _map(this.countries, country => ({
				text: country.name,
				value: country['alpha-2']
			}));
		},
		canSubmit() {
			return this.formState.dirty && !this.formState.invalid && this.formState.status !== 'pending';
		},
		validationRules() {
			let rules = {
				formData: {
					shipTo: {
						name: {
							required
						},
						address1: {
							required
						},
						town: {
							required
						},
						email: {
							email
						},
						isoCountry: {
							required
						}
					},
					carrier: {}
				}
			};

			if (this.orderIsPending) {
				_set(rules, 'carrier', {
					alias: {
						required
					}
				});
			} else {
				_set(rules, 'carrier', {
					serviceId: {
						required
					}
				});
			}

			return rules;
		}
	},
	watch: {
		shipment: {
			handler() {
				if (this.shipment) {
					this.setFormData(this.shipment);
				}
			},
			immediate: true
		},
		displayReturnAddress(val) {
			if (this.orderIsPending) {
				this.updateFormField({
					formName: 'editShipmentForm',
					fieldPath: 'returnAddress',
					value: val ? this.defaultReturnAddress : null
				});
				this.updateFormSummary({
					formName: this.formName,
					summary: { dirty: true }
				});
			}
		}
	},
	mounted() {
		this.countries = require('../../../../public/data/iso.countries.json');
	},
	methods: {
		...mapActions({
			updateFormField: 'form/updateFormField',
			updateFormSummary: 'form/updateFormSummary',
			getOrderConfigOptions: 'order/getConfigOptions',
			getOrderDetails: 'order/getOrderDetails',
			getOrderShipments: 'order/getShipmentsByOrderId',
			updateShipment: 'shipment/updateShipment',
			setShippingMethod: 'order/setShippingMethod',
			addShippingMethod: 'order/addShippingMethod',
			clearShippingMethods: 'order/clearShippingMethods'
		}),
		onShow() {
			this.newShippingMethod = false;

			if (!this.orderConfigOptions) {
				this.getOrderConfigOptions();
			}

			// Reset any changes
			if (this.shipment) {
				this.setFormData(this.shipment);
			}
		},
		onClearShippingMethods() {
			this.$bvModal
				.msgBoxConfirm(this.$t('Are you sure you wish to clear your saved shipping methods?'), {
					title: `Clear Shipping Methods`,
					size: 'sm'
				})
				.then(okay => {
					if (okay) {
						this.clearShippingMethods();
						this.updateFormField({ formName: this.formName, fieldPath: 'carrier.alias', value: '' });
					}
				});
		},
		async update() {
			const shipmentUpdate = {
				carrier: {},
				shipTo: this.formData.shipTo,
				returnAddress: this.formData.returnAddress
			};

			if (this.orderIsPending) {
				_set(shipmentUpdate, 'carrier', {
					alias: this.formData.carrier.alias
				});
			} else {
				_set(shipmentUpdate, 'carrier', {
					code: this.selectedShippingMethod.code,
					service: this.selectedShippingMethod.service,
					serviceId: this.selectedShippingMethod._id
				});

				if (Array.isArray(this.selectedShippingMethod.aliases) && this.selectedShippingMethod.aliases.length) {
					shipmentUpdate.carrier.alias = this.selectedShippingMethod.aliases[0];
				}
			}

			this.addShippingMethod(this.formData.carrier.alias);
			this.setShippingMethod(this.formData.carrier.alias);

			if (this.orderIsPending) {
				this.updateFormField({
					formName: 'orderCreateForm',
					fieldPath: `orderData.shipments.${_findIndex(this.shipments, this.shipment)}`,
					value: { ...this.formData, carrier: shipmentUpdate.carrier }
				});

				return this.onClose();
			}

			try {
				// Update Shipment
				await this.updateShipment({ id: this.shipment._id, data: shipmentUpdate });

				// Reload order and shipments
				await this.getOrderDetails(this.shipment.orderId);
				await this.getOrderShipments(this.shipment.orderId);

				this.$notify({
					type: 'success',
					title: $t('Shipment Updated'),
					text: $t('Your shipment information has been updated successfully')
				});

				// Close modal
				this.onClose();
			} catch (err) {
				const message = displayError(err);
				this.$notify({ type: 'error ', text: message });
			}
		}
	}
};
</script>

<style lang="scss">
.ShipmentModal {
	&-checkbox {
		margin-top: 40px;
	}
	.form-divider {
		margin: 30px 0;
	}
}
</style>
