<template>
	<section v-if="orderData" class="OrderDetail">
		<h2 class="OrderDetail_title" v-text="$t('Order Details')" />
		<b-row class="mb-3">
			<b-col class="OrderDetail_cell">
				<span class="OrderDetail_label">{{ $t('Order Id') }}</span>
				<span class="OrderDetail_value" data-test-id="orderDataOrderId">{{ sourceOrderId }}</span>
			</b-col>
			<b-col class="OrderDetail_cell">
				<span class="OrderDetail_label">{{ $t('Customer Name') }}</span>
				<span class="OrderDetail_value" data-test-id="orderDataCustomer">{{ customerName || '-' }}</span>
			</b-col>
		</b-row>
		<b-row class="mb-3">
			<b-col class="OrderDetail_cell">
				<span class="OrderDetail_label">{{ $t('PSP') }}</span>
				<span class="OrderDetail_value" data-test-id="orderPsp">{{ destinationName }}</span>
			</b-col>
			<b-col class="OrderDetail_cell">
				<span class="OrderDetail_label">{{ $t('Status') }}</span>
				<span class="d-flex">
					<ofs-badge :status="status" :text="getBadgeStatusText(status)" data-test-id="orderStatus" />
				</span>
			</b-col>
		</b-row>
		<b-row v-if="tags.length" class="mb-3">
			<b-col class="OrderDetail_cell" data-test-id="orderDataTag">
				<span class="OrderDetail_label">{{ $t('Tags') }}</span>
				<ul class="tag-list">
					<li
						v-for="(tag, index) in tags"
						:key="`tag-${index}`"
						class="tag-list_item"
						:title="tag"
						v-text="tag"
					/>
				</ul>
			</b-col>
		</b-row>
		<b-row class="mb-3">
			<b-col class="OrderDetail_cell">
				<span class="OrderDetail_label">{{ $t('Submitted') }}</span>
				<span class="OrderDetail_value" data-test-id="order-submitted" :title="submitted | formatDate">{{
					submitted | duration
				}}</span>
			</b-col>
			<b-col class="OrderDetail_cell" data-test-id="orderDataOrderSource">
				<span class="OrderDetail_label">{{ $t('Order Source') }}</span>
				<a href="#" @click.prevent="viewSource">{{ $t('View Order Source') }}</a>
			</b-col>
		</b-row>
		<b-row>
			<b-col>
				<b-tabs fill>
					<b-tab :title="$t('Postbacks')">
						<Postbacks :order="order.order" />
					</b-tab>
					<b-tab :title="$t('Order Logs')">
						<Logs :order="order.order" />
					</b-tab>
				</b-tabs>
			</b-col>
		</b-row>
		<code-viewer
			:code="sourceCode"
			:title="$t('Order Source')"
			:show="isSourceModalVisible"
			:on-close="closeSourceModal"
		/>
	</section>
</template>

<script>
import { withForm, OfsBadge } from '@workflow-solutions/ofs-vue-layout';
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import moment from 'moment';
import '../../../../styles/order.scss';
import { duration, getBadgeStatusText } from '../../../../lib/helpers';
import Postbacks from '../../../../components/Postbacks';
import Logs from './Logs';
import CodeViewer from '../../../../components/siteflow/Modal/CodeViewer';
import { $t } from '../../../../vuex';

export default {
	name: 'OrderDetails',
	filters: {
		duration,
		formatDate(date) {
			return moment(date).format('ddd, Do MMM, YYYY');
		}
	},
	components: {
		OfsBadge,
		Postbacks,
		Logs,
		CodeViewer
	},
	mixins: [withForm('orderForm')],
	props: {
		order: {
			required: true,
			type: Object
		},
		editable: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			isSourceModalVisible: false
		};
	},
	computed: {
		...mapGetters({
			postbacks: 'order/postbacks',
			orderFromStore: 'order/order'
		}),
		sourceCode() {
			return JSON.stringify(_.get(this, 'orderFromStore', {}), null, 2);
		},
		sourceOrderId() {
			return _.get(this.orderData, 'sourceOrderId');
		},
		orderId() {
			return _.get(this.order, 'order._id');
		},
		orderData() {
			return _.get(this.order, 'order.orderData');
		},
		tags() {
			return _.get(this.orderData, 'tags', []);
		},
		orderPriority() {
			return _.get(this.orderData, 'colour', 'black');
		},
		customerName() {
			return _.get(this.orderData, 'customerName');
		},
		destinationName() {
			return _.get(this.order, 'order.destination.name');
		},
		status() {
			return _.get(this.orderData, 'status');
		},
		shipments() {
			return _.get(this.order, 'shipments', []);
		},
		submitted() {
			return _.get(this.orderData, 'date');
		}
	},
	methods: {
		...mapActions({
			updateOrder: 'order/update',
			getOrderDetails: 'order/getOrderDetails',
			updateOrderDetails: 'order/updateOrderDetails'
		}),
		getBadgeStatusText,
		viewSource() {
			this.isSourceModalVisible = true;
		},
		closeSourceModal() {
			this.isSourceModalVisible = false;
		}
	}
};
</script>
